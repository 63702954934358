import {
  TOGGLE_EDIT_A_POST_MODAL,
  TOGGLE_SHARE_A_POST_MODAL,
  HIDE_LIKE_MODAL,
  TOGGLE_PROFILE_COMPLETION_MODAL,
  TOGGLE_PROFILE_MENU_MODAL,
  TOGGLE_SHOW_INTEREST_MODAL,
  TOGGLE_PROFILE_EDIT_MODAL,
  SHOW_PROFILE_PASSBOOK_MODAL,
  HIDE_PROFILE_MODAL,
  TOGGLE_COMPANY_EDIT_MODAL,
  TOGGLE_REMOVE_COMPANY_ADMIN_MODAL,
  TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL,
  RESET_PHOTO_MODAL,
  SHOW_PHOTO_MODAL,
  SHOW_TTFB_BUSINESS_MODAL,
  HIDE_TTFB_BUSINESS_MODAL,
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_ERROR,
  SHOW_DONATION_MODAL,
  HIDE_DONATION_MODAL,
  SAVE_DONATION_DATA_SUCCESS,
  TOGGLE_OTP_MODAL,
  TOGGLE_SUCCESS_OTP_MODAL,
  HIDE_PROFILE_PROMPT_MODAL,
  SHOW_PROFILE_PROMPT_MODAL,
  SHOW_INVITTAION_MODAL,
  HIDE_INVITATION_MODAL
} from './constants';
import { onRequest, getResponseData, onSuccess, onError } from '../../utils/helper';
import { getBaseUrl, tastingUrls, ProfileUrls, surveyLinks } from '../../utils/links';
import Axios from 'axios';
const CancelToken = Axios.CancelToken;

export const toggleEditPostModal = (post = null) => {
  return {
    type: TOGGLE_EDIT_A_POST_MODAL,
    post,
  };
};

export const toggleShareAPostModal = (post = null) => {
  return {
    type: TOGGLE_SHARE_A_POST_MODAL,
    payload: {
      type: 'shareAPost',
      post,
    },
  };
};

// Hiding like modal
export const hideLikeModal = () => {
  return {
    type: HIDE_LIKE_MODAL,
  };
};
//get the mandatory fields
export let getMandatoryFeildsToken = undefined;
export const getMandatoryFeilds = (token) => {
  if (getMandatoryFeildsToken) getMandatoryFeildsToken();
  let url = `${getBaseUrl()}${tastingUrls.collaboration.getMandatoryFeilds}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getMandatoryFeildsToken = c)),
    };
  return Axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

//get the mandatory fields
export let addOrUpdateDemographyToken = undefined;
export const addOrUpdateDemography = (token, data, modelId, isSurvey) => {
  if (addOrUpdateDemographyToken) addOrUpdateDemographyToken();
  let url = isSurvey ? `${getBaseUrl()}${surveyLinks.updateDemography(modelId)}` : `${getBaseUrl()}${tastingUrls.collaboration.updateDemography(modelId)}`,
    options = {
      url,
      method: 'PATCH',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (addOrUpdateDemographyToken = c)),
    };
  return Axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

//show interest modal
export const toggleShowInterestModal = (collaboration) => {
  return {
    type: TOGGLE_SHOW_INTEREST_MODAL,
    payload: {
      collaboration,
    },
  };
};

// Toggling profile completion modal
export const toggleProfileCompletionModal = (type) => {
  return {
    type: TOGGLE_PROFILE_COMPLETION_MODAL,
    payload: type,
  };
};

export const toggleProfileMenuModal = (type) => {
  return {
    type: TOGGLE_PROFILE_MENU_MODAL,
    payload: type,
  };
};

export const toggleOtpModal = (type) => {
  return {
    type: TOGGLE_OTP_MODAL,
    payload: type,
  };
};

export const toggleSuccessOtpModal = (type) => {
  return {
    type: TOGGLE_SUCCESS_OTP_MODAL,
    payload: type,
  };
};

// Toggling profile edit modal
export const toggleProfileEditModal = (type) => {
  return {
    type: TOGGLE_PROFILE_EDIT_MODAL,
    payload: type,
  };
};

// show passbook modal
export const showProfilePassbookModal = (type, data = null) => {
  return onSuccess(SHOW_PROFILE_PASSBOOK_MODAL, {
    data,
    type,
  });
};

// hide Profile modal
export const hideProfileModal = () => onSuccess(HIDE_PROFILE_MODAL);

// toggling company edit modal
export const toggleCompanyEditModal = (type = null) => {
  return {
    type: TOGGLE_COMPANY_EDIT_MODAL,
    payload: type,
  };
};

// toggling company admin remove modal
export const toggleCompanyAdminRemoveModal = (profile = null) => {
  return {
    type: TOGGLE_REMOVE_COMPANY_ADMIN_MODAL,
    payload: profile,
  };
};

// toggling company admin remove modal
export const toggleCompanyMakeSuperAdminModal = (profile = null) => {
  return {
    type: TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL,
    payload: profile,
  };
};

// toggling photo modal
export const showPhotoModal = (data) => onSuccess(SHOW_PHOTO_MODAL, data);
export const resetPhotoModal = () => onSuccess(RESET_PHOTO_MODAL);

// toggling ttfb modal
export const showTTFBModal = (type, data = null) =>
  onSuccess(SHOW_TTFB_BUSINESS_MODAL, {
    data,
    type,
  });

export const hideTTFBModal = () => onSuccess(HIDE_TTFB_BUSINESS_MODAL);

export const updloadDocuments = (data, token) => {
  return (dispatch) => {
    dispatch(onRequest(UPLOAD_DOCUMENTS_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.uploadDocuments}`,
      options = {
        url,
        method: 'POST',
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          let images = data.images.map((image) => JSON.parse(image));
          let dataToSend = { images: images };
          dispatch(onSuccess(UPLOAD_DOCUMENTS_SUCCESS, dataToSend));
          return true;
        } else {
          dispatch(onError(UPLOAD_DOCUMENTS_ERROR, response.data.errors));
          return false;
        }
      })
      .catch((error) => {
        dispatch(onError(UPLOAD_DOCUMENTS_ERROR, error));
        return false;
      });
  };
};

//Show Donation Modal
export const setShowDonationModal = (type) => {
  return {
    type: SHOW_DONATION_MODAL,
    payload: {
      type
    },
  };
};

//Hide Donation Modal
export const setHideDonationModal = (type) => {
  return {
    type: HIDE_DONATION_MODAL,
    payload: {
      type
    },
  };
};

export const saveDonationModalData = (data) => {
  return {
    type: SAVE_DONATION_DATA_SUCCESS,
    payload: {
      data
    }
  }
}

export const showProfilePromptModal = (payload) => {
  return {
    type: SHOW_PROFILE_PROMPT_MODAL,
    payload,
  };
};



export const hideProfilePromptModal = () => {
  return {
    type: HIDE_PROFILE_PROMPT_MODAL,
  };
};


export const showInvitationDetailsModal = (data) => {
  return {
    type: SHOW_INVITTAION_MODAL,
    payload: {
      data
    }
  };
};

export const hideInvitationDetailsModal = () => {
  return {
    type: HIDE_INVITATION_MODAL,
  };
};