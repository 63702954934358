import React from "react";

const SeeAllListLoaderItem = ({ className }) => {
    return (
        <article className={`skeleton-list__see-all feed-list__item loading-animation ${className || ""}`}>
            <p className="text"></p>
            <p className="text"></p>
            <footer>
                <div className="avatar">
                    <span className="image"></span>
                    <div className="text-wrapper">
                        <p className="text"></p>
                        <p className="text"></p>
                    </div>
                </div>
                <span className="text action"></span>
            </footer>
        </article>
    )
}

const SeeAllListLoader = (props) => {
    const { count = 3 } = props;
    return (
        <>
            {Array.from({ length: count }, (_, index) => index + 1).map((_, i) => {
                return <SeeAllListLoaderItem key={i} {...props} />
            })}
        </>

    )
};

export default SeeAllListLoader;