// This file is for events
import ReactGA from "react-ga4";
import { PROFILE_COMPLETION_GA } from "../components/modals/profilePrompts/constants";
import { INVITATIONS_GA } from "../components/collaborations/prManage/schedule/constants";

const _sendGAEvent = (category, action, label = "") =>
  ReactGA.event({
    category,
    action,
    label,
  });

const _sendGA4Event = (event, params) => {
  if (window.gtag) {
    window.gtag('event', event, params);
  }
};

export const gaEvents = {
  collaborations: {
    questionLevel: (label = "") =>
      _sendGAEvent("Question level (i)", "Click", label),
    headerLearnMore: (label = "") => _sendGAEvent("Learn more", "Click", label),
  },
  hashtags: {
    suggestionsClick: (label) =>
      _sendGAEvent("Hashtag Suggestions", "Click", label),
    click: (label) => _sendGAEvent("Hashtag", "Click", label),
    search: (label) => _sendGAEvent("Hashtag", "Search", label),
    trendingClick: (label) => _sendGAEvent("Hashtag Trending", "Click", label),
    trendingSeeMore: (label) =>
      _sendGAEvent("Hashtag Trending", "See more click", label),
  },
  polls: {
    imageExpand: (label) => _sendGAEvent("Poll Image Expand", "Click", label),
  },
  landingpage: {
    quickLink: (label) => _sendGAEvent("Quick Links", "Click", label),
    bigbanner: (label) => _sendGAEvent("Big Banner", "Click", label),
    productAvailable: () =>
      _sendGAEvent("Products Available", "Click", "Landing Page"),
    passbook: (label) => _sendGAEvent("Passbook", "Click", label),
    suggestionCard: (label) => _sendGAEvent("Suggestion", "Click", label),
    pollResultSuggestionCard: () =>
      _sendGAEvent("Poll Result", "Click", "Suggested"),
    carousel: (action, label) => _sendGAEvent("Carousel", action, label),
    imageCard: (label) => _sendGAEvent("Image Card", "Click", label),
    trendingHashtag: (label) => _sendGAEvent("Trending", "Click", label),
    tagtasteInsights: (label) =>
      _sendGAEvent("TagTaste Insights", "Click", label),
    fromYourFeed: (label) => _sendGAEvent("Feed", "Click", label),
  },
  quiz: {
    createBar: (label) => _sendGAEvent("Create Bar", "Click", label),
    play: (label) => _sendGAEvent("Quiz Play", "Click", label),
    scoreScreen: (action, label) =>
      _sendGAEvent("Quiz Play Score", action, label),
    viewSubmission: (action, label) => _sendGAEvent("Quiz", action, label),
  },
  prompts: {
    profile: {
      view: (content, sessionCount, screenName, model, type) => _sendGA4Event(PROFILE_COMPLETION_GA.VIEW, {
        content,
        session_count: sessionCount,
        screen_name: screenName,
        model,
        type
      }),
      skip: (content, sessionCount, screenName, model, type) => _sendGA4Event(PROFILE_COMPLETION_GA.SKIP, {
        content,
        session_count: sessionCount,
        screen_name: screenName,
        model,
        type
      }),
      interact: (content, sessionCount, screenName, model, type) => _sendGA4Event(PROFILE_COMPLETION_GA.INTERACT, {
        content,
        session_count: sessionCount,
        screen_name: screenName,
        model,
        type
      }),

    }
  },
  schedule: {
    invite: (action, label, type) => _sendGA4Event(type, {
      action,
      label
    }),
  },
  workshop: {
    register: (label) => _sendGAEvent("Workshop Register", "Click", label),
    join: (label) => _sendGAEvent("Workshop Join", "Click", label),
  },
  certification: {
    fill: (label) => _sendGAEvent("Certification Fill", "Click", label),
    scoreScreen: (action, label) =>
      _sendGAEvent("Certification Fill Score", action, label),
    viewSubmission: (action, label) => _sendGAEvent("Certification", action, label),
  },
};