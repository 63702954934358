import React from 'react';
import PropTypes from 'prop-types';
import CommentIcon from '../../assets/images/feed/comment.svg';
import ShareIcon from '../../assets/images/feed/feed-share.svg';
import LikeIcon from '../../assets/images/feed/feed-like.svg';

function LoggedoutMetaBar({ meta, publicClickAction, actionsToShowList }) {
    if (actionsToShowList) {
        return (
            <>
                {actionsToShowList.map((actionItem) => {
                    //curently handling for share component only
                    if (actionItem.type === "share") {
                        return <div className="post-action-bar__action">
                            <div className="post-action-bar__action__popup-container">
                                <button onClick={publicClickAction}
                                    className="post-action-bar__action__btn cursor-pointer">
                                    <img className="post-action-bar__action__btn__image" src={ShareIcon} alt="share a post" />
                                    {!!actionItem.showText && <span className="post-action-bar__action__btn__text">Share</span>}
                                </button>
                            </div>
                        </div>
                    }
                })}
            </>
        )
    }
    return (<div className="post-action-bar">
        <div className="post-action-bar__meta">
            {
                !!meta.commentCount
                && <button onClick={publicClickAction}
                    className="cursor-pointer post-action-bar__meta__btn post-action-bar__meta__btn--default">
                    <span className="post-action-bar__meta__btn__text">{meta.commentCount} {meta.commentCount === 1 ? 'comment' : 'comments'}</span>
                </button>

            }
            {
                !!meta.likeCount
                && !!meta.commentCount
                && <span className="post-action-bar__meta__seprator">&bull;</span>
            }
            {
                !!meta.likeCount
                && (<button onClick={publicClickAction}
                    className="cursor-pointer post-action-bar__meta__btn post-action-bar__meta__btn--pink">
                    <span className="post-action-bar__meta__btn__text">{meta.likeCount} {meta.likeCount === 1 ? 'like' : 'likes'}</span>
                </button>)
            }
        </div>
        <div className="post-action-bar__action">
            <div className="post-action-bar__action__popup-container">
                <button onClick={publicClickAction}
                    className="post-action-bar__action__btn cursor-pointer">
                    <img className="post-action-bar__action__btn__image" src={ShareIcon} alt="share a post" />
                    <span className="post-action-bar__action__btn__text">Share</span>
                </button>
            </div>
            <button onClick={publicClickAction}
                className="post-action-bar__action__btn cursor-pointer">
                <img className="post-action-bar__action__btn__image" src={CommentIcon} alt="comment on a post" />
                <span className="post-action-bar__action__btn__text">Comment</span>
            </button>
            <button onClick={publicClickAction}
                className="post-action-bar__action__btn post-action-bar__action__btn--pink cursor-pointer">
                <img className="post-action-bar__action__btn__image" src={LikeIcon} alt="like a post" />
                <span className="post-action-bar__action__btn__text">Like</span>
            </button>
        </div>
    </div>)
};

LoggedoutMetaBar.propTypes = {
    meta: PropTypes.object.isRequired,
    publicClickAction: PropTypes.func.isRequired
}

export default LoggedoutMetaBar;