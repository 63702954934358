import get from 'lodash/get';
import {
  GET_LANDING_PAGE_DATA,
  GET_LANDING_PAGE_DATA_SUCCESS,
  GET_LANDING_PAGE_DATA_FAILURE,
  RESET_LANDING_PAGE_DATA,
} from '../../actions/landingPage/constants';
import {
  LIKE_A_POST_REQUEST,
  LIKE_A_POST_SUCCESS,
  LIKE_A_POST_FAILURE,
} from '../../actions/constants';
import { CREATE_A_COMMENT_SUCCESS } from '../../actions/common/constants';
import { GET_A_POST_COMMENT_SUCCESS, DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { PLAY_A_POLL_SUCCESS } from '../../actions/poll/constants';

const INITIAL_STATE = {
  fetching: false,
  data: [],
  error: false,
  hasFetched: false,
};

const isCurrentItem = (state, actionPollId, uiType) => {
  if (!state.data) return false;

  let currentPostIndex = state.data.findIndex((obj) => {
    const objUiType = get(obj, 'ui_type', 'suggestion');
    const objType = get(obj, `${objUiType}.type`, '');
    const objId = get(obj, `${objUiType}[${objType}].id`);

    // If uiType is provided, it must match the obj's ui_type
    if (uiType && objUiType !== uiType) {
      return false;
    }

    // Check if the actionPollId matches the obj's id
    return objId === actionPollId;
  });

  return currentPostIndex !== -1;
};


export default function landingPageData(state = INITIAL_STATE, action) {
  const newState = JSON.parse(JSON.stringify(state));
  let currentPostId = get(action, 'payload.postId');
  let payloadUiType = get(action, 'payload.uiType');

  let currentPostIndex = newState.data.findIndex((obj) => {
    const objUiType = get(obj, 'ui_type', 'suggestion'); //defalt suggestions as previosuly only suggestions have MetaBar
    const objType = get(obj, `${objUiType}.type`, '');
    const objId = get(obj, `${objUiType}[${objType}].id`);

    // uiType values can be post , suggestions etc 
    if (payloadUiType && objUiType !== payloadUiType) {
      return false;
    }

    return objId === currentPostId;
  });

  let currentItemUiType = currentPostIndex !== -1 ? get(newState, `data[${currentPostIndex}].ui_type`, 'suggestion') : null;


  let updatedPost;

  switch (action.type) {
    case GET_LANDING_PAGE_DATA:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_LANDING_PAGE_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: get(action, 'payload.data', []),
        hasFetched: true,
      };
    case GET_LANDING_PAGE_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.error,
      };

    case LIKE_A_POST_REQUEST:
    case LIKE_A_POST_FAILURE:
      if (isCurrentItem(state, get(action, 'payload.postId'))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          [currentItemUiType]: {
            ...newState.data[currentPostIndex][currentItemUiType],
            meta: {
              ...newState.data[currentPostIndex][currentItemUiType].meta,
              hasLiked:
                action.type === LIKE_A_POST_REQUEST
                  ? !action.payload.hasLiked
                  : action.payload.hasLiked,
              likeCount:
                action.type === LIKE_A_POST_REQUEST
                  ? action.payload.hasLiked
                    ? newState.data[currentPostIndex][currentItemUiType].meta.likeCount === 0
                      ? 0
                      : newState.data[currentPostIndex][currentItemUiType].meta.likeCount - 1
                    : newState.data[currentPostIndex][currentItemUiType].meta.likeCount + 1
                  : action.type === LIKE_A_POST_FAILURE
                    ? action.payload.hasLiked
                      ? newState.data[currentPostIndex][currentItemUiType].meta.likeCount + 1
                      : newState.data[currentPostIndex][currentItemUiType].meta.likeCount === 0
                        ? 0
                        : newState.data[currentPostIndex][currentItemUiType].meta.likeCount - 1
                    : newState.data[currentPostIndex][currentItemUiType].meta.likeCount,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }
    case LIKE_A_POST_SUCCESS:// like status & count based on API response
      if (isCurrentItem(state, get(action, 'payload.postId'))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          [currentItemUiType]: {
            ...newState.data[currentPostIndex][currentItemUiType],
            meta: {
              ...newState.data[currentPostIndex][currentItemUiType].meta,
              hasLiked: action.payload.hasLiked,
              likeCount: action.payload.likeCount
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }
    case CREATE_A_COMMENT_SUCCESS:
    case GET_A_POST_COMMENT_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.postId'), get(action, 'payload.uiType', false))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          [currentItemUiType]: {
            ...newState.data[currentPostIndex][currentItemUiType],
            meta: {
              ...newState.data[currentPostIndex][currentItemUiType].meta,
              commentCount:
                action.type === CREATE_A_COMMENT_SUCCESS
                  ? newState.data[currentPostIndex][currentItemUiType].meta.commentCount + 1
                  : action.payload.count,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }

    case DELETE_A_COMMENT_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.postId'), get(action, 'payload.uiType', false))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          [currentItemUiType]: {
            ...newState.data[currentPostIndex][currentItemUiType],
            meta: {
              ...newState.data[currentPostIndex][currentItemUiType].meta,
              commentCount:
                newState.data[currentPostIndex][currentItemUiType].meta.commentCount - 1 <= 0
                  ? 0
                  : newState.data[currentPostIndex][currentItemUiType].meta.commentCount - 1,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;

        return newState;
      } else {
        return state;
      }

    case PLAY_A_POLL_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.polling.id'))) {
        currentPostId = get(action, 'payload.polling.id');
        currentPostIndex = newState.data.findIndex(
          (obj) => get(obj, `${get(obj, 'ui_type', 'suggestion')}[${get(obj, `${get(obj, 'ui_type', 'suggestion')}.type`, '')}].id`) === currentPostId,
        );
        currentItemUiType = currentPostIndex !== -1 ? get(newState.data[currentPostIndex], 'ui_type', 'suggestion') : null;

        updatedPost = {
          ...newState.data[currentPostIndex],
          [currentItemUiType]: {
            ...newState.data[currentPostIndex][currentItemUiType],
            meta: {
              ...action.payload.meta,
            },
            polling: {
              ...action.payload.polling,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }

    case RESET_LANDING_PAGE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
}
