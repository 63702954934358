import React from 'react'
import { AddToCalendarButton, atcb_action } from 'add-to-calendar-button-react';
import { getOS } from '../../utils/helper';
import CommonPortal from '../modals/common/CommonPortal';
import { add, format, parse } from 'date-fns';


function AddToCalendar(props) {
    const { data, handleUpdate, isDetails } = props;
    const {
        title,
        date,
        reporting_time,
        location,
        description,
        buttonStyle,
        duration
    } = data;
    let optionList = "'Google','iCal','Outlook.com','Microsoft 365'";
    const os = getOS();
    if (os === "Mac" || os === "iOS") {
        let optionArray = optionList.split(',');
        optionArray.push("'Apple'");
        optionList = optionArray.join(',');
    }
    const dateTimeString = `${date}T${reporting_time}`;
    const startDateTime = parse(dateTimeString, "yyyy-MM-dd'T'HH:mm:ss", new Date());
    const endDateTime = add(startDateTime, duration);
    const formattedEndDate = format(endDateTime, "yyyy-MM-dd");
    const formattedEndTime = format(endDateTime, "HH:mm");


    return (

        <div className={`schedule__add-to-calendar-wrapper ${!!isDetails ? "details-view" : ""}`}
            onClick={(e) => {
                if (handleUpdate) {
                    handleUpdate(e, "", "addToCalendar")
                }
            }}
        >

            <AddToCalendarButton
                name={title}
                startDate={date}
                startTime={reporting_time}
                styleLight={buttonStyle}
                endTime={formattedEndTime}
                endDate={formattedEndDate}
                listStyle={`${!!isDetails ? "dropdown-static" : "dropup-static"}`}
                buttonStyle="text"
                timeZone="Asia/Kolkata"
                location={`${location ? `Title : ${location.title} , Link : ${location.link}` : ""} `}
                options={optionList}
                hideIconButton
                hideCheckmark
                hideBackground
                hideBranding
                description={description ? `TagTaste Event: \n ${description}` : ""}
                lightMode="bodyScheme"
            >
            </AddToCalendarButton>

        </div>

    )
}

export default AddToCalendar