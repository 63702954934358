
import { sum, isEmpty, get } from "lodash";

export const LONG_ANSWER_QUESTION_TYPE = 1;
export const SHORT_ANSWER_QUESTION_TYPE = 2;
export const MULTI_SELECT_QUESTION_TYPE = 3;
export const SINGLE_SELECT_QUESTION_TYPE = 4;
export const MEDIA_QUESTION_TYPE = 5;
export const RANGE_QUESTION_TYPE = 6;
export const RANK_QUESTION_TYPE = 7;
export const MULTI_SELECT_RADIO_QUESTION_TYPE = 8;
export const MULTI_SELECT_CHECK_QUESTION_TYPE = 9;
export const PRICE_CAPTURE_QUESTION_TYPE = 11;
export const SECTION = 10;
export const surveyType = "surveys";
export const SURVEY_QUESTION_OPTIONS_LIMIT = 20;
export const SURVEY_QUESTION_LIMIT = 200;
export const SURVEY_OTHER_OPTION_TEXT = "Others";
export const SURVEY_GA_EVENTS_DETAILS_PAGE = "Survey - Full View";

export const SURVEY_TEMPLATE_QUESTION_TYPES = [PRICE_CAPTURE_QUESTION_TYPE];

export const TEMPLATE_SURVEY_TEXTS = {
  toast: {
    duplicateSection: "All questions have been successfully copied, except for the pricing capture questions.",
    deleteSection: "This section contains template questions and cannot be deleted. Please reposition the questions or reposition the section as needed."
  }
}

export const PRICING_SURVEY_LIMITS = {
  startingPrice: {
    max: 5000,
  },
  step: {
    max: 500
  }
}


export const SURVEY_OPTION_TYPES = {
  NORMAL: 0,
  ANY_OTHER: 1,
  ABSENT: 2,
  SHORT_ANSWER: 3,
  LONG_ANSWER: 4,
  MEDIA: 5,
};

export const SURVEY_REPORTS_QUESTION_TEXT = {
  [LONG_ANSWER_QUESTION_TYPE]: "Long Answer",
  [SHORT_ANSWER_QUESTION_TYPE]: "Short Answer",
  [MULTI_SELECT_QUESTION_TYPE]: "Multiple Select",
  [SINGLE_SELECT_QUESTION_TYPE]: "Single Select",
  [PRICE_CAPTURE_QUESTION_TYPE]: "Price Capture",
  [MEDIA_QUESTION_TYPE]: "Files",
  [RANGE_QUESTION_TYPE]: "Range",
  [RANK_QUESTION_TYPE]: "Rank",
  [MULTI_SELECT_RADIO_QUESTION_TYPE]: "Multiple Grid Single Select",
  [MULTI_SELECT_CHECK_QUESTION_TYPE]: "Checkbox Grid",
  [SECTION]: "Section",
};

export const SURVEY_STATES = {
  DRAFTS: 1,
  PUBLISHED: 2,
  CLOSED: 3,
  EXPIRED: 4,
  PARTICIPATED: 5,
};

export const SURVEY_STATES_ARR = [
  {
    title: "Active",
    state: SURVEY_STATES.PUBLISHED,
  },
  {
    title: "Inactive",
    state: SURVEY_STATES.CLOSED,
  },
  {
    title: "Drafts",
    state: SURVEY_STATES.DRAFTS,
  },
  {
    title: "Participated",
    state: SURVEY_STATES.PARTICIPATED,
  },

];

export const SURVEY_FILE_TYPES = {
  PHOTO: "image",
  DOCUMENT: "document",
  VIDEO_URL: "media_url",
  VIDEO: "video",
};

export const getSurveyBlankOption = (optionType) => {
  return {
    id: "",
    title: Number(optionType) === SURVEY_OPTION_TYPES.ANY_OTHER ? SURVEY_OTHER_OPTION_TEXT : "",
    option_type: optionType,
    image_meta: [],
    video_meta: [],
    idKey: Math.random(),
  };
};


export const getSurveyFilesObj = (fileObj, fileType) => {
  switch (fileType) {
    case SURVEY_FILE_TYPES.PHOTO:
      const fileSplit = get(fileObj, "data.original_photo")
        ? fileObj.data.original_photo.split("/")
        : [];
      const fileName = fileSplit[fileSplit.length - 1];
      return {
        meta: fileObj.meta,
        link: fileObj.data.original_photo,
        fileName,
        type: fileType,
      };
    case SURVEY_FILE_TYPES.DOCUMENT:
      return {
        meta: fileObj.meta,
        link: fileObj.data.document_url,
        type: fileType,
        fileName: fileObj.data.orignal_name,
      };
    case SURVEY_FILE_TYPES.VIDEO_URL:
      return {
        meta: fileObj.meta,
        link: fileObj.data.url,
        type: SURVEY_FILE_TYPES.VIDEO_URL,
        fileName: fileObj.data.url,
      };
    default:
      return null;
  }
};

export const getSurveyFormOptionsWithoutIdKey = (options) =>
  options.map((option) => {
    let newObj = { ...option };
    delete newObj.idKey;
    return newObj;
  });

export const downloadSurveyExcelReport = (fileLink, name) => {
  let link = document.createElement("a");
  link.href = fileLink;
  link.setAttribute("download", name);
  link.click();
};

export const SURVEY_FILTER_TYPES = {
  DATE: 'date',
  QUESTION_FILTER: 'question_filter'
}

export const removeFalseFilters = (data) => {
  let questionFilter = [];
  if (!data) return [];
  const isSection = data && !!data.length && data[0].element_type == "section";
  if (isSection) {
    // For isSection case
    questionFilter = data.reduce((acc, section) => {
      const sectionQuestions = section.questions.map(question => {
        const filteredOptions = question.options.filter(option => option.is_selected);
        return { ...question, options: filteredOptions };
      });

      const filteredQuestions = sectionQuestions.filter(question => question.is_selected);
      if (filteredQuestions.length > 0) {
        const sectionData = { ...section, questions: filteredQuestions };
        return [...acc, sectionData];
      }
      return acc;
    }, []);
  } else {
    // For !isSection case
    questionFilter = data
      .map(question => {
        const filteredOptions = question.options.filter(option => option.is_selected);
        return { ...question, options: filteredOptions };
      })
      .filter(question => question.is_selected);
  }

  return [...questionFilter]
}

export const isPreviewURL = (path, search) => {
  return path.includes(search);
};

export const checkIsValidSurveyForUser = (survey, profile) => {
  if (!survey || !profile) return false;
  const { surveys, meta } = survey;

  // user states
  const isAdmin = get(meta, 'isAdmin', false);
  const isCreator = surveys.profile.id === profile.id;

  //survey states
  const isPublished = Number(get(surveys, 'state', '')) === SURVEY_STATES.PUBLISHED;
  const isClosed = Number(get(surveys, 'state', '')) === SURVEY_STATES.CLOSED;
  const isExpired = Number(get(surveys, 'state', '')) === SURVEY_STATES.EXPIRED;
  const isReviewed = get(meta, 'isReviewed', false);
  const isMultiSubmission = get(surveys, 'multi_submission', false);

  if (isClosed || isExpired) return false; // survey invalid no access to fill 

  if (isPublished) { //  published state 
    if (isCreator) return false; // Creator can't fill own survey 
  } else { //non published state 
    if (!isCreator && !isAdmin) return false; // Non creator / Non admin cannot access fill
  }

  if (isReviewed && !isMultiSubmission) return false // single submission cannot access fill

  return true;
}

// helper function to get final submit demography payload 
// ensures in each generation other is set to 0 if other_active is false while submitting
export const getSurveyDemographyPayload = (product) => {
  return Object.entries(product.demography).reduce((acc, [generationKey, generationValue]) => {
    if (generationKey === "other_active") {
      acc[generationKey] = Number(generationValue);
      return acc;
    }
    acc[generationKey] = {
      ...generationValue,
      other: product.demography.other_active ? generationValue.other : 0, // Update `other` based on `other_active`
    };

    return acc;
  }, {});
};


// getting count of gender count
const getCountDemography = (data, type) => {
  const genderCountArray = Object.keys(data[0].demography).map((val) => {
    return val === "other_active" ? 0 : Number(data[0].demography[val][type])
  });
  return sum(genderCountArray);
}

// function for demography count array

export const getDemographyCountArray = (surveyDemographyData) => {
  // getting count of generation count
  const generationCountArray = !isEmpty(surveyDemographyData) && Object.keys(surveyDemographyData[0].demography).map((val) => {
    const value = sum(Object.values(surveyDemographyData[0].demography[val]));
    return { title: val, value: value }
  })

  // combine array of gender count and generation count
  const totalCountArray = !isEmpty(surveyDemographyData) && [{ title: "male", value: getCountDemography(surveyDemographyData, "male") },
  { title: "female", value: getCountDemography(surveyDemographyData, "female") }
    , { title: "other", value: getCountDemography(surveyDemographyData, "other") }, ...generationCountArray
  ]

  return totalCountArray;
};

export const getInitialPayloadForCopy = (isPricingSurvey = false, survey, profile) => {
  if (!survey) return;
  let data = {}
  if (isPricingSurvey) {
    (survey.company && survey.company.is_premium)
      ? data = {
        company_id: survey.company_id,
        profile_id: survey.profile_id,
      }
      : data = (profile.is_premium) ? { profile_id: survey.profile_id } : null;
  } else {
    survey.company
      ? data = {
        company_id: survey.company_id,
        profile_id: survey.profile_id,
      }
      : data = { profile_id: survey.profile_id };
  };
  return data;
};

