import { combineReducers } from 'redux';
import player from './player';
import currencies from './currencies';


const commonReducer = combineReducers({
    player,
    currencies
});

export default commonReducer;
