import React, { useRef, useState } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { genericErrorText } from "../../utils/commonVariables";
import { verifyCaptcha } from "../../actions/common/captcha";

const Captcha = ({ handler, key }) => {
    const [isError, setIsError] = useState(false);
    const captchaRef = useRef(null);
    const handleToken = async (token) => {
        setIsError(false);
        if (!token) {
            handler(false);
            return;
        }
        const data = {
            response: token,
            secret: process.env.REACT_APP_HCAPTCHA_SECRET,
        };
        verifyCaptcha(data).then((response) => {
            if (response && !!response.success) {
                handler(!!response.success);
            } else {
                captchaRef.current.resetCaptcha();
                setIsError(true);
            };
        }).catch((err) => {
            console.log("captcha failed ===>", err);
            handler(false);
            captchaRef.current.resetCaptcha();
            setIsError(true);
        })
    };
    return (
        <form style={{ overflow: "hidden"}}>
            <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_KEY}
                onVerify={handleToken}
                ref={captchaRef}
                sentry={false}
                onError={handleToken}
                onExpire={handleToken}
                key={key}
            />
            {isError ? <span style={{ color: 'red', fontSize: '14px' }}>{genericErrorText.general}</span> : null}
        </form>
    )
};

export default Captcha;