import React, { useState } from "react";
import ScrollableModal from "../common/ScrollableModal";
import Avatar from "../../user/Avatar";
import UserName from "../../profile/UserName";
import { connect } from "react-redux";
import RadioSelected from "../../../assets/images/survey/create/select-circular.svg";
import RadioUnSelected from "../../../assets/images/survey/create/unselect-circular.svg";
import Loader from "../../../components/misc/Loader";
import { getInitialPayloadForCopy } from "../../../utils/surveyHelper";

const SurveyCopyModal = (props) => {
  const {
    profile,
    survey,
    companies,
    handleClose,
    showCompanySquare,
    user,
    isModalOpen,
    handleSurveyCopy,
    copying,
    isSubmitting,
    isTemplateCreation = false,
  } = props;

  const isPricingSurvey = survey && survey.pricing_survey;

  const profileReorderingObject =
    survey && companies &&
    companies.length &&
    companies.filter((obj) => {
      return obj.id === survey.company_id;
    });

  const remainingCompanies =
    survey && companies &&
    companies.length &&
    companies.filter((obj) => {
      return obj.id !== survey.company_id;
    });

  const profileToAdd = (isPricingSurvey || isTemplateCreation) ? profile.is_premium ? profile : null : profile

  const allProfiles =
    companies && companies.length > 0
      ? isTemplateCreation ? profileToAdd ? [profileToAdd, ...companies] : companies : [...profileReorderingObject, profileToAdd, ...remainingCompanies]
      : profileToAdd ? [profileToAdd] : null;


  const [selectedUserOrCompanyData, setSelectedUserOrCompanyData] = useState(
    isTemplateCreation ?
      profile.is_premium ? { profile_id: profile.id } : companies.length ? { company_id: companies[0].id } : {}
      : getInitialPayloadForCopy(isPricingSurvey, survey, profile, isTemplateCreation)
  );

  const handleCompanySelect = (isUserCompany = false, companyId) => {
    setSelectedUserOrCompanyData(
      isUserCompany
        ? {
          company_id: companyId,
          profile_id: user.user.profile.id,
        }
        : {
          profile_id: user.user.profile.id,
        }
    );
  };


  const idToCheck =
    selectedUserOrCompanyData && selectedUserOrCompanyData.company_id
      ? selectedUserOrCompanyData.company_id
      : selectedUserOrCompanyData.profile_id;



  return (
    <ScrollableModal
      showHeader={true}
      title={isTemplateCreation ? "Select Profile" : "Copy Survey For"}
      handleClose={() => {
        handleClose(false);
      }}
      isModalOpen={isModalOpen}
      primaryButtonText={isTemplateCreation ? "Create" : "Copy"}
      containerClassName="survey__form__copySurveyModal--containerClassName"
      primaryButtonClassName={isTemplateCreation ? "see-all-container__action-btn--green" : ""}
      handleSubmit={() => {
        handleSurveyCopy(selectedUserOrCompanyData)
      }}
      paddingLessContent={true}
      showHeaderBorderBottom={true}
      showFooterBorderTop={true}
      isSubmitting={isSubmitting}
      primaryButtonDisabled={isSubmitting}
      submittingText={isTemplateCreation ? "Creating..." : "Copying..."}
    >
      {!!allProfiles && allProfiles.length && allProfiles.map((profile, index) => {
        if (!profile) return;
        const isUserCompany = profile.self ? false : true;

        const actionImage =
          idToCheck === profile.id ? RadioSelected : RadioUnSelected;

        return (
          <div
            className="survey__form__copySurveyModal"
            key={Math.random() * 100}
            onClick={() => handleCompanySelect(isUserCompany, profile.id)}
          >
            <img
              src={actionImage}
              alt="radio selected"
              className="survey__form__copySurveyModal--selectImg"
            />
            <Avatar
              alt={profile.name || "user"}
              isCompany={isUserCompany}
              src={
                isUserCompany
                  ? profile.logo_meta
                    ? JSON.parse(profile.logo_meta).original_photo ||
                    profile.logo
                    : ""
                  : profile.image_meta
                    ? JSON.parse(profile.image_meta).original_photo ||
                    profile.imageUrl
                    : ""
              }
              className={`user-create-block__avatar survey__form__copySurveyModal--profileImg ${showCompanySquare && isUserCompany
                ? "user-create-block__avatar--square"
                : ""
                }`}
              profile={profile}
            />
            <p
              className={`user-create-block__name ${!!profile.verified && "user-create-block__name--verified"
                }`}
            >
              <UserName
                isVerified={!!profile.verified || false}
                name={profile.name}
              />
            </p>
          </div>
        );
      })}
    </ScrollableModal>
  );
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

export default connect(mapStateToProps)(SurveyCopyModal);
