import {
  GET_TASTING_HEADERS_REQUEST,
  GET_TASTING_HEADERS_SUCCESS,
  GET_TASTING_HEADERS_FAILURE,
  GET_TASTING_DATA_BY_HEADER_REQUEST,
  GET_TASTING_DATA_BY_HEADER_SUCCESS,
  GET_TASTING_DATA_BY_HEADER_FAILURE,
  UPDATE_ANSWER_REQUEST,
  SHOW_INTENSITY_OPTION,
  RESET_TASTING_HEADERS,
  TOGGLE_TEXTUAL_INTENSITY_OPTION,
  SUBMIT_TASTING_DATA_BY_HEADER_FAILURE,
  SUBMIT_TASTING_DATA_BY_HEADER_SUCCESS,
  SUBMIT_TASTING_DATA_BY_HEADER_REQUEST,
  START_REVIEW_SUCCESS
} from "../../actions/tasting/constants";
import isEmpty from "lodash/isEmpty";

const INITIAL_STATE = {
  headers: [],
  fetching: false,
  error: false,
  currentHeader: null,
  currentHeaderData: {
    data: null,
    fetching: false,
    error: false,
  },
  currentAnswers: null,
  currentSelectedOption: null,
  currentSelectedTextualIntensityOption: null,
  submitFailure: false,
  submitError: null,
  data: null,
  isStartAlready: false
};

const trasformDataForMinSelectionForNestedQuestion = (data) => {
  const newData = {
    ...data,
    question: data.question.map((_) => {
      if (
        !!_.is_nested_option &&
        _.nested_option_type &&
        _.nested_option_type === 2
      ) {
        const sequenceIdForDefaultOptionType = _.option
            .filter((option) => option.option_type === 0 && option.sequence_id)
            .map((option) => option.sequence_id),
          availableMinAromaOnlySequenceId =
            _.min_aroma_selection && _.min_aroma_selection.length
              ? _.min_aroma_selection.map((minAroma) => minAroma.sequence_id)
              : [],
          minAromaSelectionWithoutAlreadyAvailale =
            sequenceIdForDefaultOptionType
              .filter((_) => !availableMinAromaOnlySequenceId.includes(_))
              .map((_) => {
                return {
                  sequence_id: _,
                  min_selection: 1,
                };
              });
        return {
          ..._,
          min_aroma_selection:
            _.min_aroma_selection && _.min_aroma_selection.length
              ? [
                  ..._.min_aroma_selection,
                  ...minAromaSelectionWithoutAlreadyAvailale,
                ]
              : [...minAromaSelectionWithoutAlreadyAvailale],
        };
      }
      return _;
    }),
  };
  return newData;
};

export default function tasting(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TASTING_HEADERS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_TASTING_HEADERS_SUCCESS:
      return {
        ...state,
        headers: [...action.payload],
      };
    case GET_TASTING_HEADERS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case START_REVIEW_SUCCESS: 
      return {
        ...state,
        data: action.payload,
        isStartAlready: true
      };  
    case GET_TASTING_DATA_BY_HEADER_REQUEST:
      return {
        ...state,
        currentHeaderData: {
          ...state.currentHeaderData,
          fetching: true,
        },
      };
    case GET_TASTING_DATA_BY_HEADER_SUCCESS:
      let answerObj = {};
      let answers = action.payload.answers || action.payload.answer;
      answers.forEach((_) => {
        if (_.comment) {
          answerObj[`${_.question_id}`] = {
            comment: _.comment,
            ...(_.select_type && { select_type: _.select_type }),
          };
        } else if (_.option && _.option.length) {
          answerObj[`${_.question_id}`] = {
            option: _.option,
            ...(_.select_type && { select_type: _.select_type }),
          };
        } else if (typeof _.meta === "object" && _.meta !== null) {
          answerObj[`${_.question_id}`] = {
            meta: _.meta,
            ...(_.select_type && { select_type: _.select_type }),
            ...(_.track_consistency && {
              track_consistency: _.track_consistency,
            }),
          };
        } else if (_.files && !isEmpty(_.files)){
          answerObj[`${_.question_id}`] = {
            files: _.files,
            ...(_.select_type && { select_type: _.select_type }),
          };
        }
      });
      const questionTransformedData =
        action.payload &&
        action.payload.question &&
        action.payload.question.length
          ? trasformDataForMinSelectionForNestedQuestion(action.payload)
          : action.payload;
      return {
        ...state,
        currentHeaderData: {
          fetching: false,
          data: questionTransformedData,
        },
        currentHeader: state.headers.filter((header) => {
          return header.id === action.payload.currentheaderId;
        })[0],
        currentAnswers:
          (action.payload.answers && action.payload.answers.length) ||
          (action.payload.answer && action.payload.answer.length)
            ? answerObj
            : null,
      };
    case GET_TASTING_DATA_BY_HEADER_FAILURE:
      return {
        ...state,
        currentHeaderData: {
          error: action.error,
        },
      };
    case UPDATE_ANSWER_REQUEST:
      return {
        ...state,
        currentAnswers: {
          ...state.currentAnswers,
          [`${action.payload.questionId}`]: {
            [`${action.payload.key}`]: action.payload.newAnswer,
            ...(action.payload.selectType &&
              action.payload.isPublicCollab && {
                select_type: action.payload.selectType,
              }),
            ...(action.payload.trackConsistency && {
              track_consistency: action.payload.trackConsistency,
            }),
          },
        },
      };
    case TOGGLE_TEXTUAL_INTENSITY_OPTION:
      return {
        ...state,
        currentSelectedTextualIntensityOption: action.payload,
      };
    case SUBMIT_TASTING_DATA_BY_HEADER_FAILURE:
      return {
        ...state,
        submitFailure: true,
        submitError: action.error,
      };
    case SUBMIT_TASTING_DATA_BY_HEADER_SUCCESS:
    case SUBMIT_TASTING_DATA_BY_HEADER_REQUEST:
      return {
        ...state,
        submitFailure: false,
        submitError: null,
      };
    case RESET_TASTING_HEADERS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
