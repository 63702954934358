import {
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_REQUEST,
    LOGOUT_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_REQUEST,
    REGISTER_USER_FAILURE,
    GUEST_USER_LOGIN_SUCCESS,
} from '../actions/login/constants';
import { handleAuthState } from '../utils/helper';

const authToken = handleAuthState.get() || null;

const INITIAL_STATE = {
    loggedIn: authToken ? !!authToken : false,
    isLoggingIn: false,
    error: null,
    token: authToken,
    logoutMessage: false,
}

export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
        // case LOGIN_USER_REQUEST:
        // case REGISTER_USER_REQUEST:
        //     return INITIAL_STATE;
        case LOGIN_USER_SUCCESS:
        case REGISTER_USER_SUCCESS:
        case GUEST_USER_LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                isLoggingIn: false,
                token: action.payload.token,
            }
        case LOGIN_USER_FAILURE:
        case REGISTER_USER_FAILURE:
            return {
                ...state,
                token: null,
                isLoggingIn: false,
                error: action.error,
                loggedIn: false
            }
        case LOGOUT_REQUEST:
            return {
                loggedIn: false,
                isLoggingIn: false,
                error: null,
                token: null,
                logoutMessage: action.payload.logoutMessage || false,
            }
        default:
            return state
    }
}