/* eslint-disable no-case-declarations */
import get from 'lodash/get';
import {
  GET_FROM_FEED_REQUEST,
  GET_FROM_FEED_SUCCESS,
  GET_FROM_FEED_FAILURE,
  RESET_FROM_FEED_DETAILS,
} from '../../actions/landingPage/constants';
import {
  LIKE_A_POST_REQUEST,
  LIKE_A_POST_SUCCESS,
  LIKE_A_POST_FAILURE,
} from '../../actions/constants';
import {
  CREATE_A_COMMENT_SUCCESS,
  UPDATE_A_POLL_SUCCESS,
  UPDATE_A_POST_SUCCESS,
  DELETE_A_POST_SUCCESS,
} from '../../actions/common/constants';
import { GET_A_POST_COMMENT_SUCCESS, DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { RENEW_A_POLL_SUCCESS, PLAY_A_POLL_SUCCESS } from '../../actions/poll/constants';

const INITIAL_STATE = {
  fetching: false,
  data: [],
};

const isCurrentItem = (state, actionPollId) => {
  if (!state.data) return false;

  let currentPostIndex =
    state.data &&
    state.data.findIndex((obj) => get(obj, `[${get(obj, 'type')}].id`) === actionPollId);
  return currentPostIndex === -1 ? false : true;
};

export default function fromFeed(state = INITIAL_STATE, action) {
  const newState = JSON.parse(JSON.stringify(state));
  let currentPostId = get(action, 'payload.postId');
  let currentPostIndex =
    newState.data &&
    newState.data.findIndex((obj) => get(obj, `[${get(obj, 'type')}].id`) === currentPostId);
  let updatedPost;

  if (action.type) {
    switch (action.type) {
      case GET_FROM_FEED_REQUEST:
        return {
          ...state,
          fetching: true,
        };
      case GET_FROM_FEED_SUCCESS:
        return {
          ...state,
          fetching: false,
          data: get(action, 'payload.data', []),
        };
      case GET_FROM_FEED_FAILURE:
        return {
          ...state,
          fetching: false,
          error: true,
          errorMsg: action.error,
        };

      case LIKE_A_POST_REQUEST:
      case LIKE_A_POST_FAILURE:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...newState.data[currentPostIndex].meta,
              hasLiked:
                action.type === LIKE_A_POST_REQUEST
                  ? !action.payload.hasLiked
                  : action.payload.hasLiked,
              likeCount:
                action.type === LIKE_A_POST_REQUEST
                  ? action.payload.hasLiked
                    ? newState.data[currentPostIndex].meta.likeCount === 0
                      ? 0
                      : newState.data[currentPostIndex].meta.likeCount - 1
                    : newState.data[currentPostIndex].meta.likeCount + 1
                  : action.type === LIKE_A_POST_FAILURE
                  ? action.payload.hasLiked
                    ? newState.data[currentPostIndex].meta.likeCount + 1
                    : newState.data[currentPostIndex].meta.likeCount === 0
                    ? 0
                    : newState.data[currentPostIndex].meta.likeCount - 1
                  : newState.data[currentPostIndex].meta.likeCount,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }
      case LIKE_A_POST_SUCCESS:// like status & count based on API response
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...newState.data[currentPostIndex].meta,
              hasLiked: action.payload.hasLiked,
              likeCount: action.payload.likeCount
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }
      case CREATE_A_COMMENT_SUCCESS:
      case GET_A_POST_COMMENT_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...newState.data[currentPostIndex].meta,
              commentCount:
                action.type === CREATE_A_COMMENT_SUCCESS
                  ? newState.data[currentPostIndex].meta.commentCount + 1
                  : action.payload.count,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case DELETE_A_COMMENT_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...newState.data[currentPostIndex].meta,
              commentCount:
                newState.data[currentPostIndex].meta.commentCount - 1 <= 0
                  ? 0
                  : newState.data[currentPostIndex].meta.commentCount - 1,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case UPDATE_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.data.polling.id'))) {
          currentPostId = get(action, 'payload.data.polling.id');
          currentPostIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPostId);

          updatedPost = {
            ...newState.data[currentPostIndex],
            polling: {
              ...action.payload.data.polling,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case RENEW_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.pollId'))) {
          currentPostId = get(action, 'payload.pollId');
          currentPostIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPostId);

          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...newState.data[currentPostIndex].meta,
              is_expired: 0,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case PLAY_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.polling.id'))) {
          currentPostId = get(action, 'payload.polling.id');
          currentPostIndex =
            newState.data &&
            newState.data.findIndex(
              (obj) => get(obj, `[${get(obj, 'type')}].id`) === currentPostId,
            );

          updatedPost = {
            ...newState.data[currentPostIndex],
            meta: {
              ...action.payload.meta,
            },
            polling: {
              ...action.payload.polling,
            },
          };
          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case UPDATE_A_POST_SUCCESS:
        if (isCurrentItem(state, get(action, `payload.data[${[action.payload.postType]}].id`))) {
          currentPostId = get(action, `payload.data[${[action.payload.postType]}].id`);
          currentPostIndex =
            newState.data &&
            newState.data.findIndex(
              (obj) => get(obj, `[${get(obj, 'type')}].id`) === currentPostId,
            );

          updatedPost = {
            ...newState.data[currentPostIndex],
            ...action.payload.data,
          };

          newState.data[currentPostIndex] = updatedPost;
          return newState;
        } else {
          return state;
        }

      case DELETE_A_POST_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          currentPostId = get(action, 'payload.postId');
          currentPostIndex =
            newState.data &&
            newState.data.findIndex(
              (obj) => get(obj, `[${get(obj, 'type')}].id`) === currentPostId,
            );

          return {
            ...state,
            data: state.data.filter((obj) => {
              return get(obj, `[${get(obj, 'type')}].id`) !== get(action, 'payload.postId', '');
            }),
          };
        } else {
          return state;
        }

      case RESET_FROM_FEED_DETAILS:
        return INITIAL_STATE;
      default:
        return state;
    }
  }
}
