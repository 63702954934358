import React, { useEffect, useState } from "react";
import ScrollableModal from "../common/ScrollableModal";
import { createSurveyTemplate, getTemplatesandData } from "../../../actions/survey/common";
import CollaborationCopyModal from "../collaborations/CollaborationCopyModal";
import { surveyLinks } from "../../../utils/internalLinks";
import Loader from "../../misc/Loader";
import PlusIcon from "../../../assets/images/reviews/plus-icon.svg";
import PreviewIcon from "../../../assets/images/survey/create/preview-btn.svg";
import SeeAllListLoader from "../../misc/Loaders/Skeletons/SeeAllListLoader";
import SurveyCopyModal from "./SurveyCopyModal";


const TemplateListItem = (props) => {
    const { data, key, token, toggleCopyModal, history } = props;
    const previewLink = surveyLinks.templatePreview();
    const [isLoding, setIsLoading] = useState(false);
    const getSurevyTemplateData = () => {
        if (isLoding) return false;
        setIsLoading(prev => !prev);
        getTemplatesandData(token, data.id).then((res) => {
            history.push({
                pathname: previewLink,
                state: {
                    templateData: res,
                    templatePreview: true
                },
            })
        }).finally(() => {
            setIsLoading(prev => !prev);
        })
    }
    return (
        <div className="list-item" key={key}>
            <img src={data.image_url} className="image" />
            <div className="list-item__details">
                <p className="title">{data.title || ""}</p>
                <p className="subtitle">{data.description || ""}</p>
                <div className="button-container">
                    <button className="btn" onClick={getSurevyTemplateData} disabled={isLoding}>
                        {isLoding ? 'Loading...' : <>Preview <img className="icon" src={PreviewIcon} /></>}
                    </button>
                    <button className="btn fill text" onClick={() => toggleCopyModal(data.id)}>Use this template</button>
                </div>
            </div>
        </div>
    )
}

const SurveyPricingModal = (props) => {
    const { handleToggle, token, handleClick, profile, premiumCompanies, history } = props,
        [templatesList, setTemplateslist] = useState(null),
        [copyModal, setCopyModal] = useState(false),
        [loading, setLoading] = useState(false),
        [templateId, setTemplateId] = useState();
    const getSurevyTemplates = () => {
        setLoading(true);
        getTemplatesandData(token, null).then((res) => {
            setLoading(false);
            if (res) {
                setTemplateslist(res);
            }
        })
    }

    const toggleCopyModal = (id) => {
        // no action if profile or comapny not premium
        if (!profile.is_premium && !premiumCompanies.length) return false;
        if (!id) {
            setCopyModal(false);
            return;
        }
        setTemplateId(id);
        let premiumCount = 0;
        if (profile.is_premium) {
            premiumCount = premiumCount + 1;
        };
        premiumCount = premiumCount + premiumCompanies.length;
        // if only profile is premium or only single company is premium then dont show 
        // modal and let user through to next screen 
        if (premiumCount === 1) {
            const company = premiumCompanies.length === 1 && premiumCompanies[0];
            const data = {
                ...(company
                    && {
                    company_id: company.id,
                }),
                profile_id: profile.id,
                template_id: id,
            }
            handleCreateSurvey(data);
        } else {
            setCopyModal(!copyModal);
        }

    }

    const handleCreateSurvey = (data = null) => {
        if (!data) return;
        const isCompany = data.hasOwnProperty('company_id');
        const dataToSend = {
            "template_id": templateId ? templateId : data.template_id,
            ...(isCompany && { "company_id": data["company_id"] }),
        }
        setLoading(true);
        createSurveyTemplate(token, dataToSend).then((res) => {
            setLoading(false);
            if (res) {
                const editLink = surveyLinks.edit(res.id);
                history.push(editLink);
            }
        })
    }
    useEffect(() => {
        getSurevyTemplates();
    }, [])
    return (
        <>
            <ScrollableModal
                title="Create Survey"
                showHeader
                showFooter={false}
                handleClose={handleToggle}
                showNoteText="Start with a ready-made template or build your survey from scratch."
                className="template-survey-modal"
                isModalOpen
            >
                <button className="survey-create__container" onClick={handleClick}>
                    <p className="title"><img className="icon" src={PlusIcon} />Create from scratch</p>
                    <p className="subtitle">Click to design your own survey from the ground up, tailoring every detail to your specific needs.</p>
                </button>
                <div className="list-container">
                    <p className="temp-title">Template</p>
                    {
                        (!templatesList && loading) ? <div style={{ padding: "20px 0" }}>
                            <Loader isCentered />
                        </div> : (templatesList && !!templatesList.length) ? templatesList.map((temp, index) => {
                            return (
                                <ul className="all-list">
                                    <TemplateListItem
                                        data={temp}
                                        key={temp.id}
                                        token={token}
                                        toggleCopyModal={toggleCopyModal}
                                        history={history}
                                    />
                                </ul>
                            )
                        }) : <p className="no-template">No Templates Found</p>
                    }
                </div>
            </ScrollableModal>
            {
                copyModal && <SurveyCopyModal
                    survey={null}
                    handleClose={toggleCopyModal}
                    profile={profile}
                    companies={premiumCompanies}
                    showCompanySquare={false}
                    handleSurveyCopy={handleCreateSurvey}
                    copying={loading}
                    // selectedCompanyData={selectedData}
                    copyModalHeader="Select Profile"
                    // setSelectedCompanyData={setSelectedData}
                    isSubmitting={loading}
                    isTemplateCreation
                />
            }
        </>
    )
}


export default SurveyPricingModal