import Axios from "axios";
import {
  getResponseData,
  getResponseDataWithStatusOld,
  onError,
  onRequest,
  onSuccess,
} from "../../utils/helper";
import { getBaseUrl, surveyLinks, profileLinks } from "../../utils/links";
import {
  GET_SURVEY_DETAILS_FAILURE,
  GET_SURVEY_DETAILS_REQUEST,
  GET_SURVEY_DETAILS_SUCCESS,
  RESET_SURVEY_DETAILS,
} from "./constants";
const CancelToken = Axios.CancelToken;

export let getSurveyQuestionTypesCancelToken = undefined;
export const getSurveyQuestionTypes = (token) => {
  if (getSurveyQuestionTypesCancelToken) getSurveyQuestionTypesCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.questionTypes}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken(
        (c) => (getSurveyQuestionTypesCancelToken = c)
      ),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};

export let getSurveyAnswersCancelToken = undefined;
export const getSurveyAnswers = (token, id) => {
  if (getSurveyAnswersCancelToken) getSurveyAnswersCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.answers(id)}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getSurveyAnswersCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};

export let getSurveyDetailsCancelToken = undefined;
export const getSurveyDetails = (token, id, isEdit = false) => {
  return (dispatch) => {
    if (getSurveyDetailsCancelToken) getSurveyDetailsCancelToken();
    dispatch(onRequest(GET_SURVEY_DETAILS_REQUEST));
    let url = `${getBaseUrl()}${surveyLinks.single(id)}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getSurveyDetailsCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_SURVEY_DETAILS_SUCCESS, {
              ...successData,
              isEdit,
            })
          );
          return successData;
        } else {
          dispatch(onError(GET_SURVEY_DETAILS_FAILURE, response.data.errors));
          return false;
        }
      })
      .catch((error) => {
        dispatch(onError(GET_SURVEY_DETAILS_FAILURE, error));
        return false;
      });
  };
};

export const resetSurveyDetails = () => onSuccess(RESET_SURVEY_DETAILS);

export const submitSurveyAnswer = (token, data) => {
  let url = `${getBaseUrl()}${surveyLinks.save}`,
    options = {
      url,
      data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseDataWithStatusOld(response);
      return successData;
    })
    .catch(() => getResponseDataWithStatusOld(null, true));
};

// getting close reasons
export let surveyCloseReasonsCancelToken = undefined;
export const surveyCloseReasons = (token) => {
  if (surveyCloseReasonsCancelToken) surveyCloseReasonsCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.closeReaons}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (surveyCloseReasonsCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};

// closing a survey
export const closeASurvey = (token, id, data) => {
  let url = `${getBaseUrl()}${surveyLinks.close(id)}`,
    options = {
      url,
      data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};

// get survey mandatory fields
export let getSurveyMandatoryFieldsCancelToken = undefined;
export const getSurveyMandatoryFields = (token, params, id = "") => {
  if (getSurveyMandatoryFieldsCancelToken)
    getSurveyMandatoryFieldsCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.getMandatoryFields(id)}`,
    options = {
      url,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken(
        (c) => (getSurveyMandatoryFieldsCancelToken = c)
      ),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};

// saving applicants state whenever mandatory fields are submitted
export let saveAsSurveyApplicantFieldsCancelToken = undefined;
export const saveAsSurveyApplicantFields = (token, id = "", data) => {
  if (saveAsSurveyApplicantFieldsCancelToken)
    saveAsSurveyApplicantFieldsCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.surveyApplicantsStateSave(id)}`,
    options = {
      url,
      data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken(
        (c) => (saveAsSurveyApplicantFieldsCancelToken = c)
      ),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};


// post survey start time
export let getStartSurveyTimeCancelToken = undefined;
export const getStartSurveyTime = (token,id ="", data) => {
  if (getStartSurveyTimeCancelToken)
    getStartSurveyTimeCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.getSurveyTiming(id)}`,
    options = {
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
      cancelToken: new CancelToken(
        (c) => (getStartSurveyTimeCancelToken = c)
      ),
    };
  return Axios(options)
    .then((response) => {
      // let successData = getResponseData(response);
      return response.data;
    })
    .catch(() => false);
};

export let getPremiumCompaniesCancelToken = undefined;
export const getPremiumCompanies = (token) => {
  if (getPremiumCompaniesCancelToken) getPremiumCompaniesCancelToken();
  let url = `${getBaseUrl()}${profileLinks.premiumCompanies}`,
    options = {
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getPremiumCompaniesCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};


export let getTemplatesandDataCancelToken = undefined;
export const getTemplatesandData = (token, id) => {
  if (getTemplatesandDataCancelToken) getTemplatesandDataCancelToken();
  let url = id ? `${getBaseUrl()}${surveyLinks.surveyTemplateData(id)}` :`${getBaseUrl()}${surveyLinks.surveyTemplate}`,
    options = {
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getTemplatesandDataCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};



export let createSurveyTemplateCancelToken = undefined;
export const createSurveyTemplate = (token, data) => {
  if (createSurveyTemplateCancelToken) createSurveyTemplateCancelToken();
  let url = `${getBaseUrl()}${surveyLinks.create}`,
    options = {
      url,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (createSurveyTemplateCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => false);
};






