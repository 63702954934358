import React from "react";
import Share from "../../../../../../assets/images/feed/feed-share.svg";
import SharePopUp from "../../../../../../components/SharePopUp";


const ShareComponent = (props) => {
    const {
        toggleShareOptions,
        state,
        toggleShareAPost,
        postId,
        hideInternalSharing,
        sharedId,
        postType,
        showText = true,
        showIcon = true
    } = props
    return (
        <>
            <div className="post-action-bar__action__popup-container">
                <button
                    onClick={toggleShareOptions}
                    className={`post-action-bar__action__btn cursor-pointer
                                ${state.showShareOptions
                            ? "post-action-bar__action__btn--active"
                            : ""
                        }`}
                >
                    {showIcon ? <img
                        className="post-action-bar__action__btn__image"
                        src={Share}
                        alt="share a post"
                        style={{ marginRight: showText ? '8px' : 0 }}
                    /> : null}
                    {showText ? <span className="post-action-bar__action__btn__text">Share</span> : null}
                </button>
                {state.showShareOptions && (
                    <SharePopUp
                        shareOnFeed={toggleShareAPost}
                        postId={postId}
                        hideInternalSharing={hideInternalSharing || false}
                        sharedId={sharedId}
                        postType={postType}
                    />
                )}
            </div>
        </>
    )
};


export default ShareComponent;
