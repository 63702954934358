import React, { useEffect, useState } from 'react'
import { convertSecondsToTime, getCombinedDate } from '../../utils/helper';
import { differenceInSeconds } from 'date-fns';
import _ from "lodash";



function InvitationTimer(props) {
    const { isDetails, date, reportingTime, containerClassName, title, handleUpdate } = props;
    const totalSeconds = differenceInSeconds(
        getCombinedDate(date, reportingTime),
        new Date()
    ); // Example;  
    const [remainiTime, setRemainTimne] = useState(totalSeconds);
    const result = convertSecondsToTime(totalSeconds);
    const totalTimes = [
        {
            key: "day",
            label: "DAYS"
        },
        {
            key: "hour",
            label: "HRS"
        },
        {
            key: "minute",
            label: "MIN"
        },
        {
            key: "second",
            label: "SEC"
        }
    ];
    
    useEffect(() => {     
        const interval = setInterval(() => {
            if(totalSeconds === 0){
                if (handleUpdate) {
                    handleUpdate();
                }
                clearInterval(interval);
                return false;
            } else {
                setRemainTimne((remain) => remain - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [totalSeconds]);
    const getTwoDigitofTime = (num) => {
        return _.padStart(num.toString(), 2, "0")
    }
    if (totalSeconds < 0) return null;
    return (
        <div className={`invitation-timer-container ${!!isDetails ? "details-view" : ""} ${containerClassName ? containerClassName : ""}`}>
            <p className={`title ${!!isDetails ? "details-view" : ""}`}>{title ? title : "Event Starts In"}</p>
            <div className="countdown">
                {
                    totalTimes.map((val, index) => {
                        if((result.day === 0 && val.key === "day") || (result.day >= 1 && val.key === "second")) return null;
                        return (
                            <div className="timer-conatiner" key={index}>
                                <div>
                                    <div className="min_div" >
                                        {
                                            getTwoDigitofTime(result[val.key]).split("").map((val, index) => {
                                                return <p className="countdown-label" key={index}>{val}</p>
                                            })
                                        }
                                    </div>
                                    <p className={`time-label`}>{val.label}</p>
                                </div>
                                <p className={`dot-text ${(result.day >= 1 && val.key === "minute") ? "hide" : ""} ${!!isDetails ? "details-view" : ""}`}>:</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default InvitationTimer