import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CreatePost from "../../components/create/CreatePost";
import CreatePoll from "../../components/create/CreatePoll";
import { hideEditAPostModal } from "../../actions/feed/common";
import { Prompt } from "react-router-dom";
import DiscardPostDraft from "./common/DiscardPost";
import ScrollableModal from "./common/ScrollableModal";

class EditAPostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsHalfFilledOut: true,
      discardPost: false,
    };
  }
  _renderPost = (postType, post) => {
    switch (postType) {
      case "photo":
      case "shoutout":
        return (
          <CreatePost
            isEdit={true}
            editablePostType={postType}
            editablePost={post}
          />
        );
      case "polling":
        return <CreatePoll isEdit={true} editablePost={post} />;
      default:
        return null;
    }
  };

  handleClose = () => {
    const { hideEditAPostModal } = this.props;
    hideEditAPostModal();
    this.setState({ formIsHalfFilledOut: false });
  };

  componentWillUnmount() {
    if (this.props.editAPost.isVisible) {
      this.handleClose();
    }
    window.onbeforeunload = null;
  }

  render() {
    const { post, postType } = this.props;
    return (
      <div className="default-modal edit-a-post-modal">
        <ScrollableModal
          showHeader
          title={
            <p className="default-modal__header__title">
              Edit {postType === "polling" ? "poll" : "post"}
            </p>
          }
          handleClose={() => {
            this.setState({ discardPost: true });
          }}
          showFooter={false}
          containerClassName="edit-a-post-modal"
          isModalOpen
          paddingLessContent={false}
        >
          <div className="default-modal__content">
            {postType && post && this._renderPost(postType, post)}
          </div>
        </ScrollableModal>
        {this.state.discardPost && (
          <DiscardPostDraft
            modalTitle={"Discard draft?"}
            handleClose={() => this.setState({ discardPost: false })}
            handleAction={() => {
              window.onbeforeunload = null;
              this.handleClose();
            }}
            actionText="Discard"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    token: state.login.token,
    editAPost: state.modalReducer.postModals.editAPost,
  };
}

const mapDispatchToProps = {
  hideEditAPostModal,
};

EditAPostModal.propTypes = {
  post: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAPostModal);