import { GET_CURRENCIES_FAILURE, GET_CURRENCIES_REQUEST, GET_CURRENCIES_SUCCESS } from '../../actions/common/constants';

const INITIAL_STATE = {
    fetching: false,
    list: [],
    error: false,
    errorMsg: false,
};

export default function currencies(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_CURRENCIES_REQUEST:
            return {
                ...INITIAL_STATE,
                fetching: true,
            }
        case GET_CURRENCIES_SUCCESS:
            return {
                ...INITIAL_STATE,
                list: action.payload
            }
        case GET_CURRENCIES_FAILURE:
            return {
                ...INITIAL_STATE,
                error: true,
                errorMsg: action.error,
            }
        default:
            return state;
    }
}
