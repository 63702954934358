import {
    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_UPDATE_FAILURE,
    ADD_ADDRESS_REQUEST,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAILURE,
    INVITE_CONTACT_REQUEST,
    INVITE_CONTACT_SUCCESS,
    INVITE_CONTACT_FAILURE,
    UPDATE_ALLERGENS_REQUEST,
    UPDATE_ALLERGENS_SUCCESS,
    UPDATE_ALLERGENS_FAILURE,
    DELETE_SHIPPING_ADDRESS_REQUEST,
    DELETE_SHIPPING_ADDRESS_SUCCESS,
    DELETE_SHIPPING_ADDRESS_FAILURE,
    UPDATE_SHIPPING_ADDRESS_REQUEST,
    UPDATE_SHIPPING_ADDRESS_SUCCESS,
    UPDATE_SHIPPING_ADDRESS_FAILURE
} from './constants';
import {
    onRequest,
    onSuccess,
    onError,
    getResponseData
} from '../../utils/helper';
import { getBaseUrl, profileLinks } from '../../utils/links';
import Axios from 'axios';
import { genericErrorText } from '../../utils/commonVariables';
const CancelToken = Axios.CancelToken;

export let updateUserCancelToken = undefined;
export const updateUser = (profileId,
    data,
    token,
    isMultiPart = false) => {
    return dispatch => {
        if (updateUserCancelToken) updateUserCancelToken();
        dispatch(onRequest(PROFILE_UPDATE_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.update(profileId)}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => updateUserCancelToken = c)
            };
        if (isMultiPart) options.headers['Content-Type'] = 'multipart/form-data';
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(PROFILE_UPDATE_SUCCESS, successData));
                } else {
                    dispatch(onError(PROFILE_UPDATE_FAILURE, response.data.errors));
                    return response.data;
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(PROFILE_UPDATE_FAILURE, genericErrorText.general));
                return false;
            })
    }
}

export const addShippingAddress = (profileId,
    data,
    token) => {
    return dispatch => {
        dispatch(onRequest(ADD_ADDRESS_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.addAddress(profileId)}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(ADD_ADDRESS_SUCCESS, successData))
                } else {
                    dispatch(onError(ADD_ADDRESS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(ADD_ADDRESS_FAILURE, error));
                return false;
            })
    }
}

// method to shipping address
export let updateShippingAddressCancelToken = undefined;
export const updateShippingAddress = (token,
    profileId,
    addressId,
    data) => {
    return dispatch => {
        if (updateShippingAddressCancelToken) updateShippingAddressCancelToken();
        dispatch(onRequest(UPDATE_SHIPPING_ADDRESS_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.singleAddress(profileId, addressId)}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'PATCH',
                data,
                cancelToken: new CancelToken((c) => updateShippingAddressCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(UPDATE_SHIPPING_ADDRESS_SUCCESS, successData[0]));
                } else {
                    dispatch(onError(UPDATE_SHIPPING_ADDRESS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(_ => {
                dispatch(onError(UPDATE_SHIPPING_ADDRESS_FAILURE, _));
                return false;
            })
    }
}

// delete shipping address
export let deleteShippingAddressCancelToken = undefined;
export const deleteShippingAddress = (token,
    profileId,
    addressId) => {
    return dispatch => {
        if (deleteShippingAddressCancelToken) deleteShippingAddressCancelToken();
        dispatch(onRequest(DELETE_SHIPPING_ADDRESS_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.singleAddress(profileId, addressId)}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'DELETE',
                cancelToken: new CancelToken((c) => deleteShippingAddressCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(DELETE_SHIPPING_ADDRESS_SUCCESS, addressId));
                } else {
                    dispatch(onError(DELETE_SHIPPING_ADDRESS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(_ => {
                dispatch(onError(DELETE_SHIPPING_ADDRESS_FAILURE, _));
                return false
            })
    }
}

// action to invite contact
export let inviteContactsCancelToken = undefined;
export const inviteContacts = (data,
    token) => {
    return dispatch => {
        if (inviteContactsCancelToken) inviteContactsCancelToken();
        dispatch(onRequest(INVITE_CONTACT_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.invite}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => inviteContactsCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(INVITE_CONTACT_SUCCESS, successData));
                } else {
                    dispatch(onError(INVITE_CONTACT_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(INVITE_CONTACT_FAILURE, error));
                return false
            })
    }
}

export let updateAllergensCancelToken = undefined;
export const updateAllergens = (data, token) => {
    return dispatch => {
        if (updateAllergensCancelToken) updateAllergensCancelToken();
        dispatch(onRequest(UPDATE_ALLERGENS_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.addAllergens}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => updateAllergensCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(UPDATE_ALLERGENS_SUCCESS, successData));
                } else {
                    dispatch(onError(UPDATE_ALLERGENS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(onError(UPDATE_ALLERGENS_FAILURE, error)));
                return false;
            })
    }
}