import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  // Link, NavLink,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { getPremiumCompanies } from "../../actions/premium";
import PostMobileIcon from "../../assets/images/feed/create/post-mobile.svg";
import CreateBarDesktop from "./CreateBarDesktop";
import CreateBarMobile from "./CreateBarMobile";
import CreatePost from "./CreatePost";
import CreatePoll from "./CreatePoll";
import "react-toastify/dist/ReactToastify.css";
import { certificateLinks, collaborationInternalLinks, quizLinks, surveyLinks } from "../../utils/internalLinks";
import { typesOfCollaborations } from "../../utils/collaborationHelper";
import { gaEvents } from "../../utils/events";
import { QUIZ_GA_EVENTS_TITLE } from "../../utils/quizHelper";
import SurveyPricingModal from "../modals/survey/SurveyPricingModal";
import MandatoryInformationModal from "../modals/MandatoryInformationModal";

const CreateBar = (props) => {
  const { isMobile, token, handleOpen, user, history } = props;
  const {
    premiumCompanies,
    user: { profile },
  } = user
  const [showMobileCreateMenu, setShowMobileCreateMenu] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const premiumCompaniesList = premiumCompanies && premiumCompanies.companies;
  const isUserorCompanyPremium = profile && profile.is_premium || (premiumCompaniesList && premiumCompaniesList.length);
  const [mandatoryFieldModalVisible, setMandatoryFieldModalVisible] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [creationType, setCreationType] = useState("");


  const toggleCreateModalMobile = () => {
    if (showMobileCreateMenu) {
      document.querySelector("body").classList.remove("overflow-hidden");
      document.getElementsByClassName("top-bar")[0].style.display = "block";
    } else {
      document.querySelector("body").classList.add("overflow-hidden");
      document.getElementsByClassName("top-bar")[0].style.display = "none";
    }
    setShowMobileCreateMenu(!showMobileCreateMenu);
  };

  // mobileCreate
  const [type, setType] = useState(null);

  const handleUpdate = (type = null) => {
    if (type) {
      setType(type);
      handleOpen(true);
      return;
    }
    setType(null);
    handleOpen(false);
  };

  useEffect(() => {
    if (type === "poll") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [type]);
  const handleSurveyOrPollClose = () => {
    handleUpdate();
  };


  const handleLinkButtonClick = (type) => {
    switch (type) {
      case "collaboration":
        history.push(collaborationInternalLinks.create());
        break;
      case "poll":
        handleUpdate("poll");
        break;
      case "survey":
        if (isUserorCompanyPremium) {
          handleToggle()
        } else {
          history.push(surveyLinks.create);
        }
        break;
      case "quiz":
        gaEvents.quiz.createBar(QUIZ_GA_EVENTS_TITLE);
        history.push(quizLinks.create);
        break;
      case "certification":
        history.push(certificateLinks.create);
        break;
      case "review":
        history.push(collaborationInternalLinks.create(typesOfCollaborations.productReview));
        break;
      case "consistency":
        history.push(collaborationInternalLinks.create(`${typesOfCollaborations.productReview}?track_consistency=true`));
        break;
      default:
        break;
    }
    if (isMobile) {
      document.querySelector("body").classList.remove("overflow-hidden");
      document.getElementsByClassName("top-bar")[0].style.display = "block";
    }
  };

  const handleButtonClick = (type) => {
    setCreationType(type);
    if (mandatoryFields.length >= 1) {
      setMandatoryFieldModalVisible(true);
    } else {
      handleLinkButtonClick(type);
    }
  }

  const handleCreateSurvey = () => {
    history.push(surveyLinks.create);
  }

  const closeMandatoryModal = () => {
    setMandatoryFieldModalVisible(false);
  }

  const handleToggle = () => {
    setShowPricingModal(!showPricingModal);
  }

  const handleCheckFields = () => {
    setMandatoryFields(prevState => {
      let val = [...prevState];
      if (typeof verified_at === "object")
        val.push("verified_email") // val = [...val, "verified_email"]
      if (!profile.verified_phone)
        val.push("verified_phone") // val = [...val, "verified_phone"]
      return val;
    })
  }

  useEffect(() => {
    if (profile) {
      handleCheckFields();
    }
  }, [])

  return (
    <>
      {isMobile ? (
        <div className="createbar__mobile" onClick={toggleCreateModalMobile}>
          <img alt="post" src={PostMobileIcon} />
          <span className="createbar__mobile__text">Post Something</span>
        </div>
      ) : (
        <CreateBarDesktop token={token} handleOpen={handleOpen} user={user}
          handleButtonClick={handleButtonClick}
        />
      )}

      {showMobileCreateMenu && (
        <CreateBarMobile
          toggleCreateModalMobile={toggleCreateModalMobile}
          handleOpen={handleOpen}
          history={history}
          handleUpdate={handleUpdate}
          setShowMobileCreateMenu={setShowMobileCreateMenu}
          handleLinkButtonClick={handleLinkButtonClick}
          handleButtonClick={handleButtonClick}
        />
      )}

      {type && (
        <div className="post-bar__create-modal">
          {type === "post" && (
            <CreatePost handleClose={() => handleUpdate()} hideArrow />
          )}
          {type === "poll" && (
            <CreatePoll handleClose={handleSurveyOrPollClose} hideArrow />
          )}
        </div>
      )}
      {type && <div className="create-bg"></div>}
      {
        showPricingModal && <SurveyPricingModal
          handleToggle={handleToggle}
          token={token}
          handleClick={handleCreateSurvey}
          profile={profile}
          history={history}
          premiumCompanies={premiumCompaniesList}
        />
      }
      {mandatoryFieldModalVisible &&
        <MandatoryInformationModal
          useFor="creation"
          mandatoryFields={mandatoryFields}
          closeModal={closeMandatoryModal}
          proceedAction={() => {
            setMandatoryFieldModalVisible(false);
            handleLinkButtonClick(creationType)
          }}
          profile={profile}
        />}
    </>
  );
};

CreateBar.propTypes = {
  isMobile: PropTypes.bool,
  token: PropTypes.string,
  user: PropTypes.object,
  handleOpen: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(connect(null, { getPremiumCompanies })(CreateBar));
