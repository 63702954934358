// Create a shoutout
export const CREATE_A_POST_REQUEST = "CREATE_A_POST_REQUEST";
export const CREATE_A_POST_SUCCESS = "CREATE_A_POST_SUCCESS";
export const CREATE_A_POST_FAILURE = "CREATE_A_POST_FAILURE";

// Creating a photo post
export const CREATE_A_PHOTO_POST_REQUEST = "CREATE_A_PHOTO_POST_REQUEST";
export const CREATE_A_PHOTO_POST_SUCCESS = "CREATE_A_PHOTO_POST_SUCCESS";
export const CREATE_A_PHOTO_POST_FAILURE = "CREATE_A_PHOTO_POST_FAILURE";

// Uploading a photo globally
export const UPLOAD_PHOTO_REQUEST = "UPLOAD_PHOTO_REQUEST";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE";

// Uploading a video globally
export const UPLOAD_VIDEO_REQUEST = "UPLOAD_VIDEO_REQUEST";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAILURE = "UPLOAD_VIDEO_FAILURE";

// Creating a poll
export const CREATE_A_POLL_REQUEST = "CREATE_A_POLL_REQUEST";
export const CREATE_A_POLL_SUCCESS = "CREATE_A_POLL_SUCCESS";
export const CREATE_A_POLL_FAILURE = "CREATE_A_POLL_FAILURE";

// creating a comments
export const CREATE_A_COMMENT_REQUEST = "CREATE_A_COMMENT_REQUEST";
export const CREATE_A_COMMENT_SUCCESS = "CREATE_A_COMMENT_SUCCESS";
export const CREATE_A_COMMENT_FAILURE = "CREATE_A_COMMENT_FAILURE";

// Delete a post
export const DELETE_A_POST_REQUEST = "DELETE_A_POST_REQUEST";
export const DELETE_A_POST_SUCCESS = "DELETE_A_POST_SUCCESS";
export const DELETE_A_POST_FAILURE = "DELETE_A_POST_FAILURE";

// Open a share post modal
export const TOGGLE_SHARE_A_POST_MODAL = "TOGGLE_SHARE_A_POST_MODAL";
export const TOGGLE_PROFILE_MENU_MODAL = "TOGGLE_PROFILE_MENU_MODAL";
export const TOGGLE_OTP_MODAL = "TOGGLE_OTP_MODAL";
export const TOGGLE_SUCCESS_OTP_MODAL = "TOGGLE_SUCCESS_OTP_MODAL";

// Open a edit post modal
export const TOGGLE_EDIT_A_POST_MODAL = "TOGGLE_EDIT_A_POST_MODAL";

// Like post modal
export const SHOW_LIKE_MODAL = "SHOW_LIKE_MODAL";
export const HIDE_LIKE_MODAL = "HIDE_LIKE_MODAL";

// Share a post on feed
export const SHARE_A_POST_ON_FEED_REQUEST = "SHARE_A_POST_ON_FEED_REQUEST";
export const SHARE_A_POST_ON_FEED_SUCCESS = "SHARE_A_POST_ON_FEED_SUCCESS";
export const UPDATE_A_SHARED_POST = "UPDATE_A_SHARED_POST";
export const SHARE_A_POST_ON_FEED_FAILURE = "SHARE_A_POST_ON_FEED_FAILURE";

// Get like for a given post
export const GET_LIKES_FOR_A_POST_REQUEST = "GET_LIKES_FOR_A_POST_REQUEST";
export const GET_LIKES_FOR_A_POST_SUCCESS = "GET_LIKES_FOR_A_POST_SUCCESS";
export const GET_LIKES_FOR_A_POST_FAILURE = "GET_LIKES_FOR_A_POST_FAILURE";

// Showing edit a post modal
export const SHOW_EDIT_A_POST_MODAL = "SHOW_EDIT_A_POST_MODAL";
export const HIDE_EDIT_A_POST_MODAL = "HIDE_EDIT_A_POST_MODAL";

// Update a post
export const UPDATE_A_POST_REQUEST = "UPDATE_A_POST_REQUEST";
export const UPDATE_A_POST_SUCCESS = "UPDATE_A_POST_SUCCESS";
export const UPDATE_A_POST_FAILURE = "UPDATE_A_POST_FAILURE";

// Update a photo post
export const UPDATE_A_PHOTO_POST_REQUEST = "UPDATE_A_PHOTO_POST_REQUEST";
export const UPDATE_A_PHOTO_POST_SUCCESS = "UPDATE_A_PHOTO_POST_SUCCESS";
export const UPDATE_A_PHOTO_POST_FAILURE = "UPDATE_A_PHOTO_POST_FAILURE";

// Update a poll
export const UPDATE_A_POLL_REQUEST = "UPDATE_A_POLL_REQUEST";
export const UPDATE_A_POLL_SUCCESS = "UPDATE_A_POLL_SUCCESS";
export const UPDATE_A_POLL_FAILURE = "UPDATE_A_POLL_FAILURE";

// Deleting a poll option
export const DELETE_A_POST_OPTION = "DELETE_A_POST_OPTION";
export const SHOW_INTEREST_COLLABORATION_MODAL =
  "SHOW_INTEREST_COLLABORATION_MODAL";

// profile completion modal
export const TOGGLE_PROFILE_COMPLETION_MODAL =
  "TOGGLE_PROFILE_COMPLETION_MODAL";

// toggling show interest modal
export const TOGGLE_SHOW_INTEREST_MODAL = "TOGGLE_SHOW_INTEREST_MODAL";
export const HIDE_SHOW_INTEREST_MODAL = " HIDE_SHOW_INTEREST_MODAL";

// toggling profile edit modal
export const TOGGLE_PROFILE_EDIT_MODAL = "TOGGLE_PROFILE_EDIT_MODAL";

// show profile passbook modal
export const SHOW_PROFILE_PASSBOOK_MODAL = "SHOW_PROFILE_PASSBOOK_MODAL";

// toggling profile passbook modal
export const HIDE_PROFILE_MODAL = "HIDE_PROFILE_MODAL";

// toggling company edit modal
export const TOGGLE_COMPANY_EDIT_MODAL = "TOGGLE_COMPANY_EDIT_MODAL";

// toggling remove admin modal
export const TOGGLE_REMOVE_COMPANY_ADMIN_MODAL =
  "TOGGLE_REMOVE_COMPANY_ADMIN_MODAL";

// toggling make super admin modal
export const TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL =
  "TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL";

// toggling or reports
export const UPDATE_UPLOAD_PERCENTAGE = "UPDATE_UPLOAD_PERCENTAGE";
export const RESET_UPLOAD_PERCENTAGE = "RESET_UPLOAD_PERCENTAGE";

// photo modal
export const SHOW_PHOTO_MODAL = "SHOW_PHOTO_MODAL";
export const RESET_PHOTO_MODAL = "RESET_PHOTO_MODAL";

// business modals
export const SHOW_TTFB_BUSINESS_MODAL = "SHOW_TTFB_BUSINESS_MODAL";
export const HIDE_TTFB_BUSINESS_MODAL = "HIDE_TTFB_BUSINESS_MODAL";

//upload documents
export const UPLOAD_DOCUMENTS_REQUEST = "UPLOAD_DOCUMENTS_REQUEST";
export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_DOCUMENTS_SUCCESS";
export const UPLOAD_DOCUMENTS_ERROR = "UPLOAD_DOCUMENTS_ERROR";

// donation
export const SHOW_DONATION_MODAL = "SHOW_DONATION_MODAL";
export const HIDE_DONATION_MODAL = "HIDE_DONATION_MODAL";
export const SAVE_DONATION_DATA_SUCCESS = "SAVE_DONATION_DATA_SUCCESS";
export const DONATION_PREFERENCE_SUCCESS = "DONATION_PREFERENCE_SUCCESS";
export const DONATION_PREFERENCE_REQUEST = "DONATION_PREFERENCE_REQUEST";
export const DONATION_PREFERENCE_FALIURE = "DONATION_PREFERENCE_FALIURE";

//prompts
export const SHOW_PROFILE_PROMPT_MODAL = "SHOW_PROFILE_PROMPT_MODAL";
export const HIDE_PROFILE_PROMPT_MODAL = "HIDE_PROFILE_PROMPT_MODAL";

// invitations
export const SHOW_INVITTAION_MODAL = "SHOW_INVITTAION_MODAL";
export const HIDE_INVITATION_MODAL = "HIDE_INVITATION_MODAL";

//currency
export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";