import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ProfileUrls } from '../../utils/links';
import { hideLikeModal } from '../../actions/common/modals';
import { followUser } from '../../actions/profile/follow';
import { hasDivScrollEndReached, debounce } from '../../utils/helper';
import { getLikeActionForPost } from '../../actions/feed/common';
import Avatar from '../user/Avatar';
import PlusIcon from '../../assets/images/common/icons/add.svg';
import Check from '../../assets/images/common/suggestions/check.svg';
import UserName from '../profile/UserName';
import ScrollableModal from './common/ScrollableModal';

class LikeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setFollowProcess: false,
    };
    this.renderLikeItem = this.renderLikeItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseViaEscape = this.handleCloseViaEscape.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this));
  }

  componentDidMount() {
    // adding event listener to keypress
    document.addEventListener('keyup', this.handleCloseViaEscape);
    // adding scroll event
    let listItem = document.querySelector('.like-modal__list');
    this.setState({ listItem });
    listItem.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.likeModal &&
      prevProps.likeModal &&
      this.state.listItem &&
      this.props.likeModal.isVisible &&
      this.props.likeModal.hasEndReached &&
      this.props.likeModal.hasEndReached !== prevProps.likeModal.hasEndReached
    ) {
      this.state.listItem.removeEventListener('scroll', this.handleScroll, true);
    }
  }

  componentWillUnmount() {
    // removing keypress event listener
    document.removeEventListener('keyup', this.handleCloseViaEscape, true);
    //removing scroll event
    if (this.state.listItem) {
      this.state.listItem.removeEventListener('scroll', this.handleScroll, true);
    }
    if (this.props.likeModal && this.props.likeModal.isVisible) {
      this.handleClose();
    }
  }

  // function to handle scroll event for pagination
  handleScroll() {
    const {
      likeModal: { fetching, hasEndReached, page, postData },
      getLikeActionForPost,
      token,
    } = this.props,
      { postId, postType, isShared, sharedId } = postData;
    if (hasDivScrollEndReached(this.state.listItem) && !fetching && !hasEndReached) {
      getLikeActionForPost(postType, postId, token, page, isShared, sharedId);
    }
  }

  // Function to close modal
  handleClose() {
    const { hideLikeModal } = this.props;
    hideLikeModal();
  }

  // function to close modal via escape btn
  handleCloseViaEscape(e) {
    if (e.code === 'Escape' || e.key === 'Escape' || e.which === 27 || e.keyCode === 27) {
      this.handleClose();
    }
  }

  renderLikeItem(profile, key, isSelf) {
    const { token, followUser } = this.props;
    const imageMeta = profile.image_meta ? JSON.parse(profile.image_meta) : false,
      imageToShow = imageMeta ? imageMeta.original_photo : profile.imageUrl || '',
      name = profile.name,
      profileId = profile.id,
      profileHandle = profile.handle,
      linkToProfile = ProfileUrls.userProfileLink(profileHandle, profileId),
      isFollowing = profile.isFollowing || false,
      showFollowButton = !isSelf && !isFollowing;
    return (
      <li className="like-modal__list__item" key={key}>
        <a
          href={linkToProfile}
          target="_blank"
          rel="noopener noreferrer"
          className={`like-modal__list__item__details
                    ${showFollowButton ? '' : 'like-modal__list__item__details--full-width'} `}
        >
          <Avatar
            src={imageToShow}
            alt={name}
            className="like-modal__list__item__details__icon"
            profile={profile}
          />
          <span
            className={`like-modal__list__item__details__name ${!!profile.verified && 'like-modal__list__item__details__name--verified'
              }`}
          >
            <UserName isVerified={!!profile.verified} name={name} />
          </span>
        </a>
        <div>
          {showFollowButton && (
            <button
              type="button"
              onClick={() => {
                this.setState({
                  setFollowProcess: true,
                });
                followUser(profile.id, token).then((_) => {
                  this.setState({
                    setFollowProcess: false,
                  });
                });
              }}
              className="like-modal__list__item__action-btn default-follow-btn"
            >
              <img
                className="like-modal__list__item__action-btn__icon"
                src={PlusIcon}
                alt="follow user"
              />
              <span className="like-modal__list__item__action-btn__text default-follow-btn__text">
                FOLLOW
              </span>
            </button>
          )}
          {!isSelf && isFollowing && (
            <p className="like-modal__list__item__following-btn">
              <img
                className="like-modal__list__item__following-btn__icon"
                src={Check}
                alt="following"
              />
              <span>Following</span>
            </p>
          )}
        </div>
      </li>
    );
  }

  render() {
    const {
      likeModal,
      user: {
        user: { profile },
      },
    } = this.props;
    return (
      <ScrollableModal
        showHeader
        title="Likes"
        handleClose={this.handleClose}
        showFooter={false}
        containerClassName="like-modal"
        isModalOpen
        paddingLessContent={false}
      >
        <div className="default-modal__content">
          <ul className="like-modal__list">
            {likeModal &&
              likeModal.hasLiked &&
              this.renderLikeItem(profile, `self-like-${likeModal.count}`, true)}
            {likeModal &&
              likeModal.profiles &&
              likeModal.profiles.map((profile, profileIndex) => {
                return this.renderLikeItem(profile, `like-modal-profile-${profileIndex}`);
              })}
          </ul>
        </div>
      </ScrollableModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    likeModal: state.modalReducer.postModals.likeModal,
    user: state.user,
    token: state.login.token,
  };
}

const mapDispatchToProps = {
  hideLikeModal,
  getLikeActionForPost,
  followUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LikeModal));
