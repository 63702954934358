import React from "react";
import { connect } from "react-redux";
import RadioSelected from "../../../assets/images/survey/create/select-circular.svg";
import RadioUnSelected from "../../../assets/images/survey/create/unselect-circular.svg";
import UserName from "../../profile/UserName";
import Avatar from "../../user/Avatar";
import ScrollableModal from "../common/ScrollableModal";

const CollaborationCopyModal = (props) => {
  const {
    profile,
    collaboration,
    companies,
    handleClose,
    showCompanySquare,
    user,
    isModalOpen,
    selectedCompanyData,
    handleCollabCopy,
    copying,
    setSelectedCompanyData,
    copyModalHeader,
    copyHeaderNoteText = "",
    isSurvey = false, // using only for pricing survey now 
    isSubmitting = false
  } = props;
  const handleCompanySelect = (isUserCompany = false, companyId) => {
    setSelectedCompanyData(
      isUserCompany
        ? {
          company_id: companyId,
          profile_id: user.user.profile.id,
        }
        : {
          profile_id: user.user.profile.id,
        }
    );
  };
  const idToCheck = {
    isCompany: selectedCompanyData && !!selectedCompanyData.company_id,
    id: selectedCompanyData ? (selectedCompanyData.company_id ? selectedCompanyData.company_id : selectedCompanyData.profile_id ? selectedCompanyData.profile_id : null) : null,
  }

  const profileReorderingObject =
    companies && collaboration &&
    companies.length &&
    companies.filter((obj) => {
      return obj.id === collaboration.company_id;
    });
  const remainingCompanies =
    companies && collaboration &&
    companies.length &&
    companies.filter((obj) => {
      return obj.id !== collaboration.company_id;
    });

  const allProfiles =
    companies && companies.length > 0
      ? isSurvey ? profile.is_premium ? [profile, ...companies] : companies : [...profileReorderingObject, profile, ...remainingCompanies]
      : [profile];
  return (
    <ScrollableModal
      showHeader={true}
      title={copyModalHeader}
      handleClose={handleClose}
      isModalOpen={isModalOpen}
      primaryButtonText={isSurvey ? "Create" : "Copy"}
      containerClassName="survey__form__copySurveyModal--containerClassName"
      primaryButtonClassName={isSurvey ? "see-all-container__action-btn--green" : ""}
      handleSubmit={handleCollabCopy}
      paddingLessContent={true}
      showHeaderBorderBottom={true}
      showNoteText={copyHeaderNoteText || ""}
      showFooterBorderTop={true}
      isSubmitting={isSubmitting}
      submittingText={isSurvey ? "Creating..." : "Copying..."}
      primaryButtonDisabled={isSubmitting || !selectedCompanyData}
    >
      {/* {copying ? (
       
          <div className="collaboration-form survey__form collaboration-form__loader">
            <Loader isCentered={true} />
          </div>
      ) : null} */}
      {allProfiles.map((profile, index) => {
        const isUserCompany = profile.self ? false : true;

        const actionImage =
          (idToCheck.isCompany === isUserCompany && idToCheck.id === profile.id) ? RadioSelected : RadioUnSelected;
        const imageToShow = isUserCompany ? profile.logo_meta
          ? JSON.parse(profile.logo_meta).original_photo ||
          profile.logo
          : ""
          : profile.image_meta
            ? JSON.parse(profile.image_meta).original_photo ||
            profile.imageUrl
            : ""
        return (
          <div
            className="survey__form__copySurveyModal"
            key={index}
            onClick={() => {
              handleCompanySelect(isUserCompany, profile.id);
            }}
          >
            <img
              src={actionImage}
              alt="radio selected"
              className="survey__form__copySurveyModal--selectImg"
            />
            <Avatar
              alt={profile.name || "user"}
              isCompany={isUserCompany}
              src={imageToShow}
              className={`user-create-block__avatar survey__form__copySurveyModal--profileImg ${showCompanySquare && isUserCompany
                ? "user-create-block__avatar--square"
                : ""
                }`}
              profile={profile}
            />
            <p
              className={`user-create-block__name ${!!profile.verified && "user-create-block__name--copy-verified"
                }`}
            >
              <UserName
                isVerified={!!profile.verified || false}
                name={profile.name}
              />
            </p>
          </div>
        );
      })}

    </ScrollableModal>
  )
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  }
}

export default connect(mapStateToProps)(CollaborationCopyModal);