import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleShareAPostModal } from "../../actions/common/modals";
import { shareAPostOnFeed } from "../../actions/feed/common";
import CloseIcon from "../../assets/images/common/modals/close.svg";
import {
  transformTaggedContent,
  makeTaggedContentEditable,
  decodeString,
} from "../../utils/helper";
import TaggableTextarea from "../TaggableTextarea";
import CommonCard from "../../containers/loggedIn/Feed/cards/Common";
import ShareIcon from "../../assets/images/common/sharing/post-update-2.svg";
import { postMaxLength } from "../../utils/commonVariables";
import UserSelect from "../create/UserSelect";
import { Prompt } from "react-router-dom";
import DiscardPostDraft from "./common/DiscardPost";

const texts = {
  errorSharing: "You have already shared this post.",
  successSharing: "Post shared successfully",
  updatedSharing: "Shared post updated successfully",
};

class ShareAPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      transformedContent: "",
      errorSharing: false,
      successSharing: false,
      discardPost: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseViaEscape = this.handleCloseViaEscape.bind(this);
    this.handleCaptionChange = this.handleCaptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //adding overflow hidden from body
    document.body.style.overflow = 'hidden';
    // adding event listener to keypress
    // window.addEventListener('keyup', this.handleCloseViaEscape);
    // checking if shared
    const {
      shareAPost,
      user: {
        user: { profile },
      },
    } = this.props,
      { post } = shareAPost;
    if (post && post.shared) {
      const editableTextContent =
        post.shared.content && post.shared.content.profiles
          ? makeTaggedContentEditable(
            decodeString(post.shared.content.text),
            post.shared.content.profiles
          )
          : decodeString(post.shared.content),
        isEdit =
          post.shared.profile_id && post.shared.profile_id === profile.id;
      this.setState({
        isEdit,
        content: isEdit && editableTextContent ? editableTextContent : "",
        transformedContent: isEdit
          ? post.shared.content && post.shared.content.profiles
            ? post.shared.content.text
            : post.shared.content
          : "",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.isEdit && this.state.content.length > 0) ||
      (this.state.isEdit && this.state.content === "") ||
      this.state.content.length > 0
    ) {
      window.onbeforeunload = function (e) {
        return "";
      };
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    //removing overflow hidden from body
    document.body.style.overflow = 'auto';
    // removing keypress event listener
    // window.removeEventListener('keyup', this.handleCloseViaEscape, true);
    if (this.props.shareAPost.isVisible) {
      this.handleClose();
    }
    this.setState({ editShare: false });
    window.onbeforeunload = null;
  }

  // function to close modal via escape btn
  handleCloseViaEscape(e) {
    if (
      e.code === "Escape" ||
      e.key === "Escape" ||
      e.which === 27 ||
      e.keyCode === 27
    ) {
      this.handleClose();
    }
  }

  // Function to close modal
  handleClose() {
    const { toggleShareAPostModal } = this.props;
    if (!toggleShareAPostModal) return;
    // removing keypress event listener
    // window.removeEventListener('keyup', this.handleCloseViaEscape, true);
    toggleShareAPostModal();
  }

  //function to update caption
  handleCaptionChange(content) {
    this.setState({
      content,
      transformedContent: transformTaggedContent(content),
    });
  }

  // share button click
  handleSubmit() {
    const { shareAPost, token, shareAPostOnFeed, toggleShareAPostModal } =
      this.props,
      { isEdit } = this.state;
    if (!shareAPost || (shareAPost && !shareAPost.post)) return;
    const postType = shareAPost.post.type,
      postId = shareAPost.post[postType].id,
      contentToShow = !!this.state.transformedContent
        ? this.state.transformedContent.trim()
        : "";
    shareAPostOnFeed(postType, postId, token, contentToShow, isEdit).then(
      (val) => {
        if (val) {
          this.setState({
            successSharing: true,
          });
          setTimeout(toggleShareAPostModal, 2000);
        } else {
          this.setState({
            errorSharing: true,
          });
          setTimeout(toggleShareAPostModal, 2000);
        }
      }
    );
  }

  discardDraft = (content) => {
    if (
      !(this.state.successSharing || this.state.errorSharing) && (this.state.content.length > 0 && !this.state.discardPost) ||
      (content.length === 0 && this.state.isEdit)
    ) {
      this.setState({ discardPost: true });
    } else {
      this.handleClose();
    }
  };

  render() {
    const { shareAPost, user } = this.props,
      { post } = shareAPost,
      { isEdit, errorSharing, successSharing } = this.state;
    if (!post) return null;
    return (
      <div className={`default-modal share-post-modal`}>
        <Prompt
          when={
            (isEdit && this.state.content.length > 1) ||
            (isEdit && this.state.content === "") ||
            this.state.content.length > 1
          }
          message={"Changes you made may not be saved."}
        />
        <div className="default-modal__backdrop">
          <div className="default-modal__container">
            <div className="default-modal__header">
              <p className="default-modal__header__title">
                {isEdit ? "Update share" : "Share post"}
              </p>
              <button
                className="default-modal__header__close"
                type="button"
                onClick={() => {
                  this.discardDraft(this.state.content);
                }}
              >
                <img src={CloseIcon} alt="close modal" />
              </button>
            </div>
            {errorSharing || successSharing ? (
              <p className="share-post-modal__content share-post-modal__content__helper">
                {errorSharing
                  ? texts.errorSharing
                  : isEdit
                    ? texts.updatedSharing
                    : texts.successSharing}
              </p>
            ) : (
              <>
                <div className="default-modal__content share-post-modal__content">
                  <UserSelect
                    isEdit={true}
                    isUserCompany={false}
                    selectedUser={user.user.profile}
                    profile={user.user.profile}
                  />
                  <TaggableTextarea
                    maxLength={postMaxLength}
                    placeholder="Write something along..."
                    className="post-bar__post__textarea"
                    value={this.state.content}
                    autoFocus={true}
                    onChange={this.handleCaptionChange}
                    isEveryone
                  />
                  <div className="share-post-modal__content__shared-post">
                    <div className="share-post-modal__content__shared-post__overlay"></div>
                    <CommonCard
                      type={post.type}
                      cardData={post}
                      showOptionsIcon={false}
                      hideMetaBar={true}
                    />
                  </div>
                </div>
                <div className="default-modal__footer">
                  <button
                    className="default-modal__footer__action-btn"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    <img
                      className="default-modal__footer__action-btn__icon"
                      src={ShareIcon}
                      alt="share post"
                    />
                    <span className="default-modal__footer__action-btn__text">
                      {isEdit ? "Update Share" : "Share"}
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.discardPost && (
          <DiscardPostDraft
            modalTitle={"Discard draft?"}
            text={
              "This will remove all the changes you have made to this post."
            }
            handleClose={() => this.setState({ discardPost: false })}
            handleAction={() => {
              window.onbeforeunload = null;
              this.setState({ discardPost: false });
              this.handleClose();
            }}
            actionText="Discard"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    shareAPost: state.modalReducer.postModals.shareAPost,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  toggleShareAPostModal,
  shareAPostOnFeed,
})(ShareAPost);
