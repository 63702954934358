export const GET_SINGLE_SURVEY_REQUEST = 'GET_SINGLE_SURVEY_REQUEST';
export const GET_SINGLE_SURVEY_SUCCESS = 'GET_SINGLE_SURVEY_SUCCESS';
export const GET_SINGLE_SURVEY_FAILURE = 'GET_SINGLE_SURVEY_FAILURE';

export const GET_SURVEY_DETAILS_REQUEST = 'GET_SURVEY_DETAILS_REQUEST';
export const GET_SURVEY_DETAILS_SUCCESS = 'GET_SURVEY_DETAILS_SUCCESS';
export const GET_SURVEY_DETAILS_FAILURE = 'GET_SURVEY_DETAILS_FAILURE';
export const RESET_SURVEY_DETAILS = 'RESET_SURVEY_DETAILS';

export const GET_SURVEY_FILTERS_REQUEST = 'GET_SURVEY_FILTERS_REQUEST';
export const GET_SURVEY_FILTERS_SUCCESS = 'GET_SURVEY_FILTERS_SUCCESS';
export const GET_SURVEY_FILTERS_FAILURE = 'GET_SURVEY_FILTERS_FAILURE';
export const RESET_SURVEY_FILTERS = 'RESET_SURVEY_FILTERS';


export const SURVEY_MANAGE_DEMOGRAPHY_SUCCESS = 'SURVEY_MANAGE_DEMOGRAPHY_SUCCESS';
export const SURVEY_MANAGE_DEMOGRAPHY_FAILURE = 'SURVEY_MANAGE_DEMOGRAPHY_FAILURE';
export const SURVEY_MANAGE_DEMOGRAPHY_REQUEST = 'SURVEY_MANAGE_DEMOGRAPHY_REQUEST'