export const GET_SINGLE_CERTIFICATE_REQUEST = "GET_SINGLE_CERTIFICATE_REQUEST";
export const GET_SINGLE_CERTIFICATE_SUCCESS = "GET_SINGLE_CERTIFICATE_SUCCESS";
export const GET_SINGLE_CERTIFICATE_FAILURE = "GET_SINGLE_CERTIFICATE_FAILURE";

export const GET_CERTIFICATE_DETAILS_REQUEST = "GET_CERTIFICATE_DETAILS_REQUEST";
export const GET_CERTIFICATE_DETAILS_SUCCESS = "GET_CERTIFICATE_DETAILS_SUCCESS";
export const GET_CERTIFICATE_DETAILS_FAILURE = "GET_CERTIFICATE_DETAILS_FAILURE";
export const RESET_CERTIFICATE_DETAILS = "RESET_CERTIFICATE_DETAILS";

export const SHOW_STATUS_STRIP = "SHOW_STATUS_STRIP";

