import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import CommonCard from "../../containers/loggedIn/Feed/cards/Common";

const Post = (props) => {
    const { data, comments } = props;
    const uiType = get(data, "ui_type", null)
    const postData = get(data, "post", {});
    const profile = get(props, "user.user.profile", {});

    const title = get(data, "title", "");

    const getComponent = (feedData) => {
        if (!feedData) return null;
        const { type } = feedData;
        const profileId = profile ? profile.id : null;
        // Shared card
        const commentsData =
            comments.byPostId[`${type}-${feedData[type].id}-${uiType}`] || null,
            showOptionsIcon = feedData.company
                ? feedData.meta
                    ? feedData.meta.isAdmin
                    : false
                : feedData.profile
                    ? feedData.profile.id === profileId
                    : false;
        return (
            <CommonCard
                type={type}
                showFlagOptions={true}
                showOptionsIcon={showOptionsIcon}
                commentsData={commentsData}
                cardData={feedData}
                uiType={uiType}
            />
        );
    };

    return (
        <div className="home__card">
            <div class="home__card__header">
                <p class="home__card__header__text__title">{title}</p>
            </div>
            <div className="home__card__content post">
                <div className="feed-list__item ">
                    {getComponent(postData)}
                </div>
            </div>
        </div>
    );
};

Post.propTypes = {
    data: PropTypes.object,
    comments: PropTypes.object,
};
export default Post;
