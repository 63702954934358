import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPremiumCompanies } from '../../actions/premium';
import ReviewCreate from '../modals/premium/ReviewCreate';

function ReviewCreateButton(props) {
  const {
    isCreateMobile,
    handleButtonClick
  } = props;
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [classNameToAppend] = useState(props.classNameToAppend || '');

  const toggleCollabCreateModal = () => {
    setShowCollabModal(!showCollabModal);

  };

  return (
    <>
      {!isCreateMobile ? (
        <button onClick={toggleCollabCreateModal} className={classNameToAppend}>
          {props.children}
        </button>
      ) : null}
      {showCollabModal && <ReviewCreate handleClose={toggleCollabCreateModal} handleButtonClick={handleButtonClick} />}
    </>
  );
}

ReviewCreateButton.propTypes = {
  classNameToAppend: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  getPremiumCompanies: PropTypes.func,
  token: PropTypes.string,
  children: PropTypes.any,
  isCreateMobile: PropTypes.bool,
  handleShowCollabModal: PropTypes.func,
  isReviewShow: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}
export default withRouter(connect(mapStateToProps, { getPremiumCompanies })(ReviewCreateButton));
