import _ from "lodash";

export const QUIZ_QUESTION_OPTIONS_LIMIT = 4;
export const QUIZ_ACCENT_COLOR = "#f47816";
export const QUIZ_QUESTION_MINIMUM_OPTIONS = 2;

export const QUIZ_GA_EVENTS_TITLE = "Quiz";
export const QUIZ_GA_EVENTS_MODEL_NAME = "quiz";
export const QUIZ_GA_EVENTS_PREVIOUS_BUTTON_TITLE = "Previous";
export const QUIZ_GA_EVENTS_NEXT_BUTTON_TITLE = "Next";
export const QUIZ_GA_EVENTS_SUBMIT_BUTTON_TITLE = "Submit";
export const QUIZ_GA_EVENTS_REPLAY_BUTTON_TITLE = "Replay";
export const QUIZ_GA_EVENTS_SEE_MORE_BUTTON_TITLE = "See More";
export const QUIZ_GA_EVENTS_TAKE_NEW_QUIZ_BUTTON_TITLE = "Take New Quiz";
export const QUIZ_GA_EVENTS_SUBMISSION_PAGE = "Submission Page";
export const QUIZ_GA_EVENTS_PLAY_SCORE_VIEW = "Quiz Play Score View";
export const QUIZ_GA_EVENTS_DETAILS_PAGE = "Quiz - Full View";

//Quiz option types
export const SINGLE_CHOICE_QUESTION_TYPE = 1;
export const MULTIPLE_CHOICE_QUESTION_TYPE = 2;

export const QUIZ_STATES = {
  DRAFTS: 1,
  PUBLISHED: 2,
  CLOSED: 3,
  EXPIRED: 4,
  PARTICIPATED: 5,
};

export const QUIZ_APPLICANT_STATES = {
  NOTSTARTED: null,
  INPROGRESS: 1, //show resume quiz button
  COMPLETED: 2,
};

export const QUIZ_REPORTS_QUESTION_TEXT = {
  [MULTIPLE_CHOICE_QUESTION_TYPE]: "Multiple Select",
  [SINGLE_CHOICE_QUESTION_TYPE]: "Single Select",
};

export const QUIZ_APPLICATION_STATUS = {
  NOTPLAYED: null,
  NOTSUBMITTED: 1,
  SUBMITTED: 2,
};

export const QUIZ_STATES_ARR = [
  {
    title: "Active",
    state: QUIZ_STATES.PUBLISHED,
  },
  {
    title: "Inactive",
    state: QUIZ_STATES.CLOSED,
  },
  {
    title: "Drafts",
    state: QUIZ_STATES.DRAFTS,
  },
  {
    title: "Participated",
    state: QUIZ_STATES.PARTICIPATED,
  },
];


export const getBlankOptionForQuizQuestionsWithoutIdKey = (options) =>
  options.map((option) => {
    let newObj = { ...option };
    delete newObj.idKey;
    return newObj;
  });

export const getBlankOptionForQuizQuestions = () => {
  return {
    id: "",
    title: "",
    is_correct: false,
    image_meta: [],
    idKey: Math.random(),
  };
};

export const downloadQuizExcelReport = (fileLink, name) => {
  let link = document.createElement("a");
  link.href = fileLink;
  link.setAttribute("download", name);
  link.click();
};

export const getDataInSpecificType = (data, outputType) => {
  let outputData;
  if (_.isString(data)) {
    switch (outputType) {
      case "number":
        outputData = Number(data);
        break;
      case "boolean":
        outputData = Boolean(data);
        break;
      case "object":
        outputData = JSON.parse(data);
        break;
      default:
        outputData = data;
        break;
    }
  } else if (_.isArray(data)) {
    outputData = data;
  } else if (_.isObject(data)) {
    if (outputType === "array") {
      // outputData = [data];
      //convert an object to an array
      outputData = Object.keys(data).map((key) => {
        return data[key];
      });
    } else if (outputType === "string") {
      outputData = JSON.stringify(data);
    }
  }
  return outputData;
};
