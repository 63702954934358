import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Loader from "../../../misc/Loader";

const ScrollableModalFooter = (props) => {
  const {
    showFooter = true,
    paddingLessContent,
    showFooterBorderTop = true,
    isFooterComponent = false,
    footerComponent = "",
    handleSecondaryButtonCta,
    handleClose,
    primaryButtonText = "Save",
    primaryButtonClassName,
    secondaryButtonText = "Cancel",
    secondaryButtonClassName,
    handleSubmit,
    primaryButtonDisabled = false,
    tooltipText,
    hideSecondaryButton,
    isSubmitting,
    submittingText
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    if (primaryButtonDisabled) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const setFooter = () => {
    let output = (
      <>
        {hideSecondaryButton ? <div></div> : <button
          type="button"
          className={`scrollable-modal__footer__action-btn scrollable-modal__footer__action-btn--secondary ${secondaryButtonClassName ? secondaryButtonClassName : ""
            } `}
          onClick={
            handleSecondaryButtonCta ? handleSecondaryButtonCta : handleClose
          }
        >
          {secondaryButtonText}
        </button>}
        <div
          onMouseOver={toggle}
          onMouseOut={toggle}
          data-tip
          data-for={`${primaryButtonDisabled ? "tooltipSave" : ""}`}
        >
          <button
            className={`scrollable-modal__footer__action-btn  ${primaryButtonClassName ? primaryButtonClassName : ""
              } ${primaryButtonDisabled
                ? "scrollable-modal__footer__action-btn--disabled"
                : ""
              }
			  `}
            type="submit"
            onClick={handleSubmit}
            disabled={primaryButtonDisabled}
          >
            {isSubmitting ? submittingText ? submittingText : <Loader isCentered isWhite withMargin /> : primaryButtonText}
          </button>
        </div>
        {tooltipText ? <ReactTooltip
          id="tooltipSave"
          place="left"
          type="dark"
          effect="solid"
          arrowRadius="3"
          //dont show tooltip if isValid is true
          className={`${!primaryButtonDisabled ? "quiz__form__tooltipStyle" : ""
            }`}
        >
          {tooltipText}
        </ReactTooltip> : null}
      </>
    );

    if (isFooterComponent) {
      output = footerComponent;
    }

    return output;
  };

  if (!showFooter) return null;
  return (
    <div
      className={`scrollable-modal__footer ${paddingLessContent && showFooterBorderTop
        ? "scrollable-modal__footer--paddingLessContentTrue"
        : showFooterBorderTop
          ? "scrollable-modal__footer--paddingLessContentFalse"
          : ""
        }`}
      style={
        paddingLessContent
          ? {
            padding: "0px",
            margin: "0px 24px 0px 16px",
            width: "calc(100% + -40px)",
          }
          : {}
      }
    >
      {setFooter()}
    </div>
  );
};

export default ScrollableModalFooter;
