import React from "react";
import CloseIcon from "../../../../assets/images/common/modals/close.svg";

const ScrollableModalHeader = (props) => {
  const {
    showHeader = true,
    showHeaderBorderBottom = false,
    paddingLessContent,
    handleClose,
    disabledClose,
    title = "Select",
    isHeaderHTML,
    isHeaderComponent = false,
    headerComponent = "",
    showNoteText = ""
  } = props;
  const setHeader = () => {
    let output = <p className="default-modal__header__title">{title || ""}</p>;
    if (isHeaderHTML) {
      output = (
        <p
          className="default-modal__header__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    } else if (isHeaderComponent) {
      output = headerComponent;
    }
    return output;
  };

  if (!showHeader) return null;
  return (
    <div className={`${paddingLessContent && showHeaderBorderBottom
      ? showNoteText ? "scrollable-modal__header--paddingLessContentWithNoteText" : "scrollable-modal__header--paddingLessContentTrue"
      : showHeaderBorderBottom
        ? showNoteText ? "scrollable-modal__header--paddingLessContentFalseNoteText" : "scrollable-modal__header--paddingLessContentFalse"
        : ""
      }`}
      style={
        paddingLessContent
          ? { padding: "24px 16px", paddingBottom: showNoteText ? "16px" : "24px" }
          : { paddingBottom: "24px" }

      }>
      <div
        className="scrollable-modal__header"
      >
        {setHeader()}
        <button
          type="button"
          className="scrollable-modal__header__close"
          onClick={disabledClose ? () => { } : handleClose}
        >
          <img src={CloseIcon} alt="close modal" />
        </button>
      </div>
      {
        showNoteText && (showNoteText === "object" ? showNoteText :
          <p className="scrollable-modal__header__note-text">{showNoteText}</p>)
      }
    </div>
  );
};

export default ScrollableModalHeader;
