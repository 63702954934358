import axios from "axios";
import { commonLinks, getBaseUrl } from "../../utils/links";
import { getResponseData, onError, onRequest, onSuccess } from "../../utils/helper";
import { GET_CURRENCIES_FAILURE, GET_CURRENCIES_REQUEST, GET_CURRENCIES_SUCCESS } from "./constants";
const CancelToken = axios.CancelToken;

export let getCurrencyCancelToken = undefined;
export const getCurrency = () => {
    return dispatch => {
        dispatch(onRequest(GET_CURRENCIES_REQUEST));
        let url = `${getBaseUrl()}${commonLinks.currency}`,
            options = {
                url,
                method: 'GET',
                cancelToken: new CancelToken((c) => (getCurrencyCancelToken = c)),
            }
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_CURRENCIES_SUCCESS, successData))
                } else {
                    dispatch(onError(GET_CURRENCIES_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch((err) => {
                dispatch(GET_CURRENCIES_FAILURE, err);
                return false;
            })
    }

}