import React, { useEffect } from "react";
import ScrollableModalHeader from "./ScrollableModal/ScrollableModalHeader";
import ScrollableModalFooter from "./ScrollableModal/ScrollableModalFooter";

const ScrollableModal = (props) => {
  const {
    className,
    containerClassName,
    contentClassName,
    title,
    isHeaderHTML,
    isHeaderComponent,
    headerComponent,
    showHeader,
    showHeaderBorderBottom,
    showFooter,
    isFooterComponent,
    footerComponent,
    showFooterBorderTop,
    disabledClose,
    handleClose,
    handleSecondaryButtonCta,
    isModalOpen = false,
    primaryButtonText = "Save",
    primaryButtonClassName,
    secondaryButtonText = "Cancel",
    secondaryButtonClassName,
    hideSecondaryButton,
    handleSubmit,
    paddingLessContent = true,
    primaryButtonDisabled = false,
    tooltipText,
    showNoteText = "",
    isSubmitting = false,
    submittingText = ""
  } = props;

  useEffect(() => {
    if (isModalOpen) {
      //disable body scroll when modal is open
      document.body.style.overflow = "hidden";
    }
    return () => {
      //enable body scroll when modal is closed
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  return (
    <div className={`scrollable-modal ${className || ""}`}>
      <div className="scrollable-modal__backdrop">
        <div
          className={`scrollable-modal__container ${containerClassName ? containerClassName : ""
            }`}
          style={paddingLessContent ? { padding: "0px" } : {}}
        >
          <ScrollableModalHeader
            showHeader={showHeader}
            paddingLessContent={paddingLessContent}
            showHeaderBorderBottom={showHeaderBorderBottom}
            disabledClose={disabledClose}
            handleClose={handleClose}
            title={title}
            isHeaderHTML={isHeaderHTML}
            isHeaderComponent={isHeaderComponent}
            headerComponent={headerComponent}
            showNoteText={showNoteText || ""}
          />
          <div
            className={`scrollable-modal__modal-overflow ${contentClassName || ""
              }`}
          >
            {props.children}
          </div>
          <ScrollableModalFooter
            showFooter={showFooter}
            paddingLessContent={paddingLessContent}
            showFooterBorderTop={showFooterBorderTop}
            isFooterComponent={isFooterComponent}
            footerComponent={footerComponent}
            handleSecondaryButtonCta={handleSecondaryButtonCta}
            handleClose={handleClose}
            primaryButtonText={primaryButtonText}
            primaryButtonClassName={primaryButtonClassName}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonClassName={secondaryButtonClassName}
            handleSubmit={handleSubmit}
            primaryButtonDisabled={primaryButtonDisabled}
            tooltipText={tooltipText}
            hideSecondaryButton={hideSecondaryButton}
            isSubmitting={isSubmitting}
            submittingText={submittingText}
          />
        </div>
      </div>
    </div>
  );
};

export default ScrollableModal;
