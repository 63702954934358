import {
    LIKE_A_POST_REQUEST,
    LIKE_A_POST_SUCCESS,
    LIKE_A_POST_FAILURE,
    PROFILE_REQUEST,
    BLOCK_USER_REQUEST,
    BLOCK_USER_SUCCESS,
} from '../constants';
import {
    onRequest,
    onError,
    onSuccess,
    getResponseData
} from '../../utils/helper';
import {
    getBaseUrl,
    likeUrls,
    postUrls,
    previewLink,
    postPreview,
    ProfileUrls
} from '../../utils/links';
import Axios from 'axios';
import {
    CREATE_A_COMMENT_REQUEST,
    CREATE_A_COMMENT_SUCCESS,
    CREATE_A_COMMENT_FAILURE,
    DELETE_A_POST_REQUEST,
    DELETE_A_POST_SUCCESS,
    DELETE_A_POST_FAILURE,
    SHARE_A_POST_ON_FEED_REQUEST,
    SHARE_A_POST_ON_FEED_SUCCESS,
    UPDATE_A_SHARED_POST,
    SHARE_A_POST_ON_FEED_FAILURE,
    GET_LIKES_FOR_A_POST_REQUEST,
    GET_LIKES_FOR_A_POST_SUCCESS,
    GET_LIKES_FOR_A_POST_FAILURE,
    SHOW_LIKE_MODAL,
    SHOW_EDIT_A_POST_MODAL,
    HIDE_EDIT_A_POST_MODAL
} from '../common/constants';
import {
    GET_PREVIEW_SUCCESS,
    EMPTY_PREVIEW,
    RESET_ALL_PREVIEW,
    GET_PREVIEW_REQUEST,
    GET_PREVIEW_FAILURE,
    SET_PREVIEW
} from './constants';
import get from 'lodash/get';
const CancelToken = Axios.CancelToken;

// action to like post
export const likeAPost = (postType,
    postId,
    token,
    isShared = false,
    userType = null,
    userId = null,
    sharedId = null,
    hasLiked,
    isAd = false) => {
    const commentData = {
        postType,
        postId,
        isShared,
        sharedId,
        hasLiked,
        isAd
    };
    return dispatch => {
        dispatch(onRequest(LIKE_A_POST_REQUEST, commentData));
        let url = postType === 'photo'
            ? `${getBaseUrl()}${likeUrls.photos(postType,
                postId,
                userType,
                userId,
                isShared,
                sharedId)}`
            : `${getBaseUrl()}${likeUrls.common(postType, postId, isShared, sharedId)}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(LIKE_A_POST_SUCCESS, {
                        ...commentData,
                        hasLiked: get(response, 'data.data.liked', false),
                        likeCount: get(response, 'data.data.likeCount', 1),
                    }));
                } else {
                    dispatch(onError(LIKE_A_POST_FAILURE, {}, commentData))
                }
            })
            .catch(_ => {
                dispatch(onError(LIKE_A_POST_FAILURE, {}, commentData));
            })
    }
}


// ACTION TO BLOCK PROFILE

export const blockProfile = (
    token,
    id,
    user_type,
    block_status
) => {
    return dispatch => {
        const blockData = {
            token,
            id,
            user_type,
            block_status
        }
        dispatch(onRequest(BLOCK_USER_REQUEST, blockData));
        let url = `${getBaseUrl()}${ProfileUrls.blockProfileLink(user_type, id)}`
        let blockUserType = user_type === "profile" ? "block_profile" : "block_company"
        let options = {
            url,
            method: 'POST',
            data: {
                [`${blockUserType}`]: block_status
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        return Axios(options)
            .then(response => {
                dispatch(onSuccess(BLOCK_USER_SUCCESS, { user_type, id }));
                return response.data;
            })
            .catch(error => {
                return false;
            })
    }
}
// action to create a comment
export const createAComment = (postType,
    postId,
    data,
    token,
    isShared = false,
    sharedId = null,
    isAd = false,
    uiType = null) => {
    const commentData = {
        postType,
        postId,
        isShared,
        sharedId,
        isAd,
        uiType
    }
    return dispatch => {
        dispatch(onRequest(CREATE_A_COMMENT_REQUEST, commentData));
        let url = (isShared
            ? `${getBaseUrl()}${postUrls.sharePostComments(postType, sharedId)}`
            : `${getBaseUrl()}${postUrls.comments(postType, postId)}`),
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData && successData.comment) {
                    dispatch(onSuccess(CREATE_A_COMMENT_SUCCESS, {
                        ...commentData,
                        comment: successData.comment,
                        meta: successData.meta || false,
                        isAd,
                    }));
                    return true;
                } else {
                    dispatch(onError(CREATE_A_COMMENT_FAILURE, {
                        error: response.data.error
                    }, {
                        ...commentData,
                        error: true
                    }));
                    return false;
                }
            })
            .catch(error => {
                dispatch(onError(CREATE_A_COMMENT_FAILURE, {
                    error
                }, {
                    ...commentData,
                    error
                }))
                return false;
            });
    }
}

// action to delete a post
export const deleteAPost = (postType,
    postId,
    token,
    isShared,
    sharedId,
    profileId,
    companyId,
) => {
    return dispatch => {
        const dataToSend = {
            postType,
            postId,
            token,
            isShared,
            sharedId,
            profileId,
            companyId,
        }
        dispatch(onRequest(DELETE_A_POST_REQUEST, dataToSend))
        let url = `${getBaseUrl()}${postUrls.deleteAPost(postType,
            postId,
            isShared,
            profileId,
            companyId)}`,
            options = {
                url,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(DELETE_A_POST_SUCCESS, dataToSend))
                } else {
                    let responseError = response.data.errors;
                    if (responseError
                        && responseError === "Model not found.") { // checking for already deleted
                        dispatch(onSuccess(DELETE_A_POST_SUCCESS, dataToSend))
                    } else {
                        dispatch(onError(DELETE_A_POST_FAILURE), responseError, dataToSend)
                    }
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(DELETE_A_POST_FAILURE, error, dataToSend));
                return false;
            })
    }
}

// Share a post on feed
export const shareAPostOnFeed = (postType,
    postId,
    token,
    content = '',
    isEdit = false) => {
    return dispatch => {
        dispatch(onRequest(SHARE_A_POST_ON_FEED_REQUEST));
        let url = `${getBaseUrl()}${postUrls.shareAPostOnFeed(postType, postId)}`,
            options = {
                url,
                method: isEdit ? 'PATCH' : 'POST',
                data: {
                    content,
                    privacy_id: 1 // Backend requires this
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (!!successData) {
                    dispatch(onSuccess(isEdit ? UPDATE_A_SHARED_POST : SHARE_A_POST_ON_FEED_SUCCESS, successData));
                } else {
                    dispatch(onError(SHARE_A_POST_ON_FEED_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(SHARE_A_POST_ON_FEED_FAILURE, error));
                return false;
            })
    }
}

// get a preview link
export let getLinkPreviewCancelToken = undefined;
export const getLinkPreview = (urlToSend,
    token,
    previewType) => {
    return dispatch => {
        if (getLinkPreviewCancelToken) getLinkPreviewCancelToken();
        dispatch(onRequest(GET_PREVIEW_REQUEST, { previewType }))
        let url = `${getBaseUrl()}${previewLink}`,
            options = {
                url,
                data: {
                    url: urlToSend,
                },
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getLinkPreviewCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (!!successData) {
                    dispatch(onSuccess(GET_PREVIEW_SUCCESS, {
                        previewType,
                        preview: successData
                    }))
                } else {
                    dispatch(onError(GET_PREVIEW_FAILURE, response.data.error, { previewType }));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PREVIEW_FAILURE, error, { previewType }));
            })
    }
}

// to empty a link preview
export const emptyAPreview = (previewType) => ({
    type: EMPTY_PREVIEW,
    payload: previewType
});

// get link preview only action
export let getLinkPreviewOnlyActionCancelToken = undefined;
export const getLinkPreviewOnlyAction = (token,
    data) => {
    if (getLinkPreviewOnlyActionCancelToken) getLinkPreviewOnlyActionCancelToken();
    let url = `${getBaseUrl()}${previewLink}`,
        options = {
            url,
            data,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            cancelToken: new CancelToken((c) => getLinkPreviewOnlyActionCancelToken = c)
        };
    return Axios(options)
        .then(response => {
            let successData = getResponseData(response);
            return successData;
        })
        .catch(_ => false);
}


// update preview
export const setPreview = (previewType, preview) => onSuccess(SET_PREVIEW, { previewType, preview });

// to reset previews
export const resetPreviews = () => onSuccess(RESET_ALL_PREVIEW);

// Function to get likes of a post
export let getLikeActionForPostCancelToken = undefined;
export const getLikeActionForPost = (postType,
    postId,
    token,
    page = 1,
    isShared,
    sharedId) => {
    const postData = {
        postType,
        postId,
        isShared,
        sharedId
    };
    return dispatch => {
        if (getLikeActionForPostCancelToken) getLikeActionForPostCancelToken();
        dispatch(onRequest(GET_LIKES_FOR_A_POST_REQUEST));
        let url = `${getBaseUrl()}${postUrls.getLikesForAPost(postType,
            postId,
            isShared,
            sharedId)}`,
            options = {
                url,
                method: 'GET',
                params: {
                    page
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getLikeActionForPostCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_LIKES_FOR_A_POST_SUCCESS, successData));
                    dispatch(onSuccess(SHOW_LIKE_MODAL, {
                        ...successData,
                        postData
                    }));
                } else {
                    dispatch(onError(GET_LIKES_FOR_A_POST_FAILURE), response.data.errors);
                }
            })
            .catch(error => {
                dispatch(onError(GET_LIKES_FOR_A_POST_FAILURE, error));
            })
    }
}

// Function to show edit a post modal
export const showEditAPostModal = (post, postType) => {
    return {
        type: SHOW_EDIT_A_POST_MODAL,
        payload: {
            post,
            postType
        }
    }
}

// Function to hide edit a post modal
export const hideEditAPostModal = () => {
    return {
        type: HIDE_EDIT_A_POST_MODAL
    }
}

// function to get post previews
export let getPostPreviewCancelToken = undefined;
export const getPostPreview = (token,
    postType,
    postId,
    shareId = false) => {
    return dispatch => {
        const previewType = `postPreview`;
        if (getPostPreviewCancelToken) getPostPreviewCancelToken();
        dispatch(onRequest(GET_PREVIEW_REQUEST, { previewType }));
        let url = `${getBaseUrl()}${postPreview(postType, postId, shareId)}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getPostPreviewCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PREVIEW_SUCCESS, {
                        previewType,
                        preview: successData
                    }));
                } else {
                    dispatch(onError(GET_PREVIEW_FAILURE, response.data.error, { previewType }));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PREVIEW_FAILURE, error, { previewType: 'postPreview' }));
                return false;
            })
    }
}