import {
    GET_CHAT_BY_ID_REQUEST,
    GET_CHAT_BY_ID_SUCCESS,
    GET_CHAT_BY_ID_FAILURE,
    NEW_MESSAGE_SENT_FROM_SOCKET,
    NEW_MESSAGE_READ_FROM_SOCKET,
    GET_CHAT_MEMEBERS_TO_ADD_SUCCESS,
    GET_CHAT_MEMEBERS_SUCCESS,
    TOGGLE_CHAT_INFO_MODAL,
    TOGGLE_ADD_PARTICIPANTS_MODAL,
    ADD_ADMIN_IN_CHAT_GROUP_SUCCESS,
    REMOVE_AS_ADMIN_FROM_CHAT_GROUP_SUCCESS,
    DELETE_MEMBER_FROM_CHAT_SUCCESS,
    CLEAR_CHAT_SUCCESS,
    GET_NEW_CHAT_OBJ_BY_PROFILE_ID_SUCCESS,
    NEW_CHAT_MESSAGE_SUCCESS,
    UPDATE_AND_SHOW_NEW_MESSAGE_COUNT,
    RESET_AND_HIDE_NEW_MESSAGE_COUNT,
    UPDATE_OR_APPEND_CHAT_INFO,
    RESET_CHAT,
    GET_CHAT_MEMEBERS_TO_ADD_REQUEST,
    RESET_CHAT_MEMEBERS_TO_ADD,
    GET_CHAT_MEMEBERS_TO_ADD_SEARCH_SUCCESS,
    GET_CHAT_MEMEBERS_TO_ADD_SEARCH_REQUEST,
    RESET_CHAT_MEMEBERS_TO_ADD_SEARCH,
    UPDATE_GROUP_INFO_SUCCESS
} from '../../actions/chat/constants';

const SEARCH_PARTICIPANTS_TO_ADD_INITIAL_STATE = {
    searchedParticipants: [],
    searchHasReachedEnd: false,
};

const INITIAL_STATE = {
    fetching: false,
    error: false,
    chatId: null,
    fetchingChatId: null,
    messages: [],
    errorMsg: null,
    isInvalidated: false,
    page: 1,
    hasReachedEnd: false,
    chatObj: null,
    participants: [],
    hasCheckedParticipants: false,
    showGroupInfoModal: false,
    showAddParticipantsModal: false,
    participantsToAdd: {
        fetching: false,
        page: 1,
        participants: [],
        hasReachedEnd: false,
        hasSearchedParticipants: false,// flag to differentiate list to render between addList & searchList 
        searchedParticipantsToAdd: { // used while searching participants to add
            searchedParticipants: [],
            searchHasReachedEnd: false,
        }
    },
    showNewChatEmptyContainer: false,
    newChatObj: null,
    newMessageShow: false,
    newMessageCount: 0,
    pendingCount: 0
};


const getInitialState = () => INITIAL_STATE;

const updatedMessagesList = (payload) => {
    let newChatMessage = {
        chat_id: payload.chatId,
        signature: payload.signature,
        pending: payload.pending ? payload.pending : false,
        created_at: payload.created_at,
        profile: {
            id: payload.profileId
        },
        message: payload.message,
        file: payload.file ? payload.file : null,
        file_meta: payload.file_meta ? payload.file_meta : null
    }
    return [newChatMessage];
}

// Reading message from socket and updating it to the list
const readMessageFromSocketAndUpdateList = (chatId, currentMessages, payload) => {
    if (parseInt(chatId) !== parseInt(payload.chat_id)) {
        return currentMessages
    }
    if (payload.signature) {
        let signatureIndex = currentMessages.findIndex(message => String(message.signature) === String(payload.signature));
        if (signatureIndex === -1) {
            return [...currentMessages, ...[payload]];
        }
        currentMessages[signatureIndex] = payload;
        return currentMessages;
    } else {
        return [...currentMessages, ...[payload]];
    }
}

const updateParticipantsAdminStatus = (participants, profileIdsToChange, isAdmin) => {
    return participants.map(participant => {
        if (profileIdsToChange.profileId.indexOf(participant.profile.id) > -1) {
            return {
                ...participant,
                is_admin: isAdmin
            }
        } else {
            return participant
        }
    })
}

const removeParticipantFromChat = (participants, payload) => {
    return participants.filter(p => p.profile.id !== payload.memberId)
}

export default function currentChat(state = getInitialState(), action) {
    switch (action.type) {
        case GET_CHAT_BY_ID_REQUEST:
            return {
                ...state,
                fetching: true,
                messages: state.chatId && (action.payload.chatId === state.chatId) ? state.messages : [],
                page: state.chatId && (action.payload.chatId === state.chatId) ? state.page : 1,
                chatObj: state.chatId && (action.payload.chatId === state.chatId) ? state.chatObj : null,
                newMessageShow: state.chatId && (action.payload.chatId === state.chatId) ? state.newMessageShow : false,
                newMessageCount: state.chatId && (action.payload.chatId === state.chatId) ? state.newMessageCount : 0,
                fetchingChatId: action.payload.chatId
                // chatId: state.chatId && (action.payload.chatId === state.chatId) ? state.chatId : action.payload.chatId
            }
        case GET_CHAT_BY_ID_SUCCESS:
            return {
                ...state,
                fetching: false,
                messages: [...action.payload.messages.reverse(), ...state.messages],
                hasReachedEnd: !action.payload.messages.length,
                chatId: action.payload.chatId,
                page: state.page + 1,
                chatObj: action.payload.chatObj ? action.payload.chatObj : state.chatObj,
                showNewChatEmptyContainer: false,
                fetchingChatId: null
            }
        case GET_CHAT_BY_ID_FAILURE:
            return {
                ...state,
                fetching: false,
                hasReachedEnd: false,
                error: true,
                errorMsg: action.error,
                fetchingChatId: null
            }
        case UPDATE_GROUP_INFO_SUCCESS:
            return {
                ...state,
                chatObj: { ...state.chatObj, image: action.payload.image },
            }
        case NEW_MESSAGE_SENT_FROM_SOCKET:
            return {
                ...state,
                messages: [...state.messages, ...updatedMessagesList(action.payload)],
                pendingCount: state.pendingCount + 1
            }
        case NEW_MESSAGE_READ_FROM_SOCKET:
            return {
                ...state,
                messages: ((parseInt(state.chatId) === parseInt(action.payload.chat_id)) && ((!state.fetching || (state.fetching && parseInt(state.fetchingChatId) === parseInt(action.payload.chat_id))))) ? readMessageFromSocketAndUpdateList(state.chatId, state.messages, action.payload) : state.messages,
                pendingCount: state.pendingCount - 1
            }
        case GET_CHAT_MEMEBERS_TO_ADD_REQUEST:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    fetching: true,
                }
            }
        case GET_CHAT_MEMEBERS_TO_ADD_SUCCESS:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    fetching: false,
                    page: state.participantsToAdd.page + 1,
                    hasReachedEnd: !action.payload.profile.length,
                    participants: [...state.participantsToAdd.participants, ...action.payload.profile],
                    existingParticipantsCount: action.payload.participants_count,
                }
            }
        case RESET_CHAT_MEMEBERS_TO_ADD:
            return {
                ...state,
                participantsToAdd: {
                    ...INITIAL_STATE.participantsToAdd
                }
            }
        case GET_CHAT_MEMEBERS_TO_ADD_SEARCH_REQUEST:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    fetching: true,
                    searchedParticipantsToAdd: {
                        ...state.participantsToAdd.searchedParticipantsToAdd,
                        searchHasReachedEnd: false,
                    }
                }
            }
        case GET_CHAT_MEMEBERS_TO_ADD_SEARCH_SUCCESS:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    fetching: false,
                    hasSearchedParticipants: true, // flag to differentiate list to render between addList & searchList 
                    searchedParticipantsToAdd: {
                        ...state.participantsToAdd.searchedParticipantsToAdd,
                        searchHasReachedEnd: !action.payload.data.length,
                        searchedParticipants:
                            (action.payload.params && action.payload.params.page === 1) ? action.payload.data :
                                [...state.participantsToAdd.searchedParticipantsToAdd.searchedParticipants, ...action.payload.data],
                    },

                }
            }
        case RESET_CHAT_MEMEBERS_TO_ADD_SEARCH:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    fetching: false,
                    hasSearchedParticipants: false, // flag to differentiate list to render between addList & searchList 
                    searchedParticipantsToAdd: {
                        ...SEARCH_PARTICIPANTS_TO_ADD_INITIAL_STATE
                    },

                }
            }
        case TOGGLE_ADD_PARTICIPANTS_MODAL:
            return {
                ...state,
                participantsToAdd: {
                    ...state.participantsToAdd,
                    participants: state.showAddParticipantsModal ? [] : state.participantsToAdd.participants,
                    participantsToAddPage: state.showAddParticipantsModal ? 1 : state.participantsToAdd.page,
                    hasSearchedParticipants: false,
                    searchedParticipantsToAdd: {
                        ...state.participantsToAdd.searchedParticipantsToAdd,
                        searchedParticipants: [],
                    }
                },
                showAddParticipantsModal: !state.showAddParticipantsModal,
            }
        case GET_CHAT_MEMEBERS_SUCCESS:
            return {
                ...state,
                participants: [...state.participants, ...action.payload]
            }
        case TOGGLE_CHAT_INFO_MODAL:
            return {
                ...state,
                participants: state.showGroupInfoModal ? [] : state.participants,
                showGroupInfoModal: !state.showGroupInfoModal
            }
        case ADD_ADMIN_IN_CHAT_GROUP_SUCCESS:
            return {
                ...state,
                participants: updateParticipantsAdminStatus(state.participants, action.payload, true)
            }
        case REMOVE_AS_ADMIN_FROM_CHAT_GROUP_SUCCESS:
            return {
                ...state,
                participants: updateParticipantsAdminStatus(state.participants, action.payload, false)
            }
        case DELETE_MEMBER_FROM_CHAT_SUCCESS:
            return {
                ...state,
                participants: removeParticipantFromChat(state.participants, action.payload)
            }
        case CLEAR_CHAT_SUCCESS:
            return {
                ...state,
                messages: parseInt(action.payload.chatId) === parseInt(state.chatId) ? [] : state.messages,
                newMessageCount: 0,
                newMessageShow: false
            }
        case GET_NEW_CHAT_OBJ_BY_PROFILE_ID_SUCCESS:
            return {
                ...state,
                showNewChatEmptyContainer: true,
                newChatObj: action.payload,
                messages: [],
                chatId: null
            }
        case NEW_CHAT_MESSAGE_SUCCESS:
            return {
                ...INITIAL_STATE,
                chatId: action.payload.id,
                chatObj: action.payload,
                messages: [],
                newChatObj: null,
            }
        case UPDATE_AND_SHOW_NEW_MESSAGE_COUNT:
            return {
                ...state,
                newMessageShow: true,
                newMessageCount: state.newMessageCount + 1
            }
        case RESET_AND_HIDE_NEW_MESSAGE_COUNT:
            return {
                ...state,
                newMessageCount: 0,
                newMessageShow: false
            }
        case UPDATE_OR_APPEND_CHAT_INFO:
            return {
                ...state,
                chatObj: state.chatId && parseInt(state.chatId) === parseInt(action.payload.id) ? action.payload : state.chatObj
            }
        case RESET_CHAT:
            return INITIAL_STATE
        default:
            return state;
    }
}

