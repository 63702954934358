import { ADD_COMPANY_ADMIN_SUCCESS } from "../company/constants";

// collaboration all collaborations
export const GET_COLLABORATIONS_REQUEST = 'GET_COLLABORATIONS_REQUEST';
export const GET_COLLABORATIONS_SUCCESS = 'GET_COLLABORATIONS_SUCCESS';
export const GET_COLLABORATIONS_FAILURE = 'GET_COLLABORATIONS_FAILURE';

// resetting all collaborations
export const RESET_COLLABORATIONS = 'RESET_COLLABORATIONS';

// Collaboration details
export const GET_COLLABORATION_DETAILS_REQUEST = 'GET_COLLABORATION_DETAILS_REQUEST';
export const GET_COLLABORATION_DETAILS_SUCCESS = 'GET_COLLABORATION_DETAILS_SUCCESS';
export const GET_COLLABORATION_DETAILS_FAILURE = 'GET_COLLABORATION_DETAILS_FAILURE';

// Collaboration create
export const CREATE_COLLABORATION_REQUEST = 'CREATE_COLLABORATION_REQUEST';
export const CREATE_COLLABORATION_SUCCESS = 'CREATE_COLLABORATION_SUCCESS';
export const CREATE_COLLABORATION_FAILURE = 'CREATE_COLLABORATION_FAILURE';

// Collaboration update
export const UPDATE_COLLABORATION_REQUEST = 'UPDATE_COLLABORATION_REQUEST';
export const UPDATE_COLLABORATION_SUCCESS = 'UPDATE_COLLABORATION_SUCCESS';
export const UPDATE_COLLABORATION_FAILURE = 'UPDATE_COLLABORATION_FAILURE';

// Reset collaboration page
export const RESET_COLLABORATION_DETAILS = 'RESET_COLLABORATION_DETAILS';

// get normal collaboration applications
export const GET_COLLABORATION_APPLICANTS_REQUEST = 'GET_COLLABORATION_APPLICANTS_REQUEST';
export const GET_COLLABORATION_APPLICANTS_SUCCESS = 'GET_COLLABORATION_APPLICANTS_SUCCESS';
export const GET_COLLABORATION_APPLICANTS_FAILURE = 'GET_COLLABORATION_APPLICANTS_FAILURE';

// get normal collaboration rejected applications
export const GET_COLLABORATION_REJECTED_APPLICANTS_REQUEST = 'GET_COLLABORATION_REJECTED_APPLICANTS_REQUEST';
export const GET_COLLABORATION_REJECTED_APPLICANTS_SUCCESS = 'GET_COLLABORATION_REJECTED_APPLICANTS_SUCCESS';
export const GET_COLLABORATION_REJECTED_APPLICANTS_FAILURE = 'GET_COLLABORATION_REJECTED_APPLICANTS_FAILURE';

// reset collaboration
export const RESET_COLLABORATION_APPLICANTS_DATA = 'RESET_COLLABORATION_APPLICANTS_DATA';

// rejecting an applicant
export const REJECT_COLLAB_APPLICANT_REQUEST = 'REJECT_COLLAB_APPLICANT_REQUEST';
export const REJECT_COLLAB_APPLICANT_SUCCESS = 'REJECT_COLLAB_APPLICANT_SUCCESS';
export const REJECT_COLLAB_APPLICANT_FAILURE = 'REJECT_COLLAB_APPLICANT_FAILURE';

// restoring an applicant
export const RESTORE_COLLAB_APPLICANT_REQUEST = 'RESTORE_COLLAB_APPLICANT_REQUEST';
export const RESTORE_COLLAB_APPLICANT_SUCCESS = 'RESTORE_COLLAB_APPLICANT_SUCCESS';
export const RESTORE_COLLAB_APPLICANT_FAILURE = 'RESTORE_COLLAB_APPLICANT_FAILURE';

//similar collaboration
export const GET_SIMILAR_COLLABORATION_REQUEST = 'GET_SIMILAR_COLLABORATION_REQUEST'
export const GET_SIMILAR_COLLABORATION_SUCCESS = 'GET_SIMILAR_COLLABORATION_SUCCESS'
export const GET_SIMILAR_COLLABORATION_FAILURE = 'GET_SIMILAR_COLLABORATION_FAILURE'

//like a collaboration
export const LIKE_A_COLLABORATION_REQUEST = 'LIKE_A_COLLABORATION_REQUEST'
export const LIKE_A_COLLABORATION_SUCCESS = 'LIKE_A_COLLABORATION_SUCCESS'
export const LIKE_A_COLLABORATION_FAILURE = 'LIKE_A_COLLABORATION_FAILURE'

//apply on collaboration
export const APPLY_COLLABORATION_REQUEST = 'APPLY_COLLABORATION_REQUEST';
export const APPLY_COLLABORATION_SUCCESS = 'APPLY_COLLABORATION_SUCCESS';
export const APPLY_COLLABORATION_FAILURE = 'APPLY_COLLABORATION_FAILURE';

// get collaboration categories
export const GET_COLLABORATION_CATEGORIES_REQUEST = 'GET_COLLABORATION_CATEGORIES_REQUEST';
export const GET_COLLABORATION_CATEGORIES_SUCCESS = 'GET_COLLABORATION_CATEGORIES_SUCCESS';
export const GET_COLLABORATION_CATEGORIES_FAILURE = 'GET_COLLABORATION_CATEGORIES_FAILURE';

// get collaboration types
export const GET_COLLABORATION_TYPES_REQUEST = 'GET_COLLABORATION_TYPES_REQUEST';
export const GET_COLLABORATION_TYPES_SUCCESS = 'GET_COLLABORATION_TYPES_SUCCESS';
export const GET_COLLABORATION_TYPES_FAILURE = 'GET_COLLABORATION_TYPES_FAILURE';


// get collaboration types icons
export const GET_COLLABORATION_TYPES_ICONS_REQUEST = 'GET_COLLABORATION_TYPES_ICONS_REQUEST';
export const GET_COLLABORATION_TYPES_ICONS_SUCCESS = 'GET_COLLABORATION_TYPES_ICONS_SUCCESS';
export const GET_COLLABORATION_TYPES_ICONS_FAILURE = 'GET_COLLABORATION_TYPES_FAILURE';

// get questionnaires
export const GET_COLLABORATION_QUESTIONNAIRE_REQUEST = 'GET_COLLABORATION_QUESTIONNAIRE_REQUEST';
export const GET_COLLABORATION_QUESTIONNAIRE_SUCCESS = 'GET_COLLABORATION_QUESTIONNAIRE_SUCCESS';
export const GET_COLLABORATION_QUESTIONNAIRE_FAILURE = 'GET_COLLABORATION_QUESTIONNAIRE_FAILURE';

// reset questionnaire
export const RESET_QUESTIONNAIRES = 'RESET_QUESTIONNAIRES';

// get cities
export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

// add questionnaire
export const UPDATE_COLLAB_QUESTIONNAIRE_REQUEST = 'UPDATE_COLLAB_QUESTIONNAIRE_REQUEST';
export const UPDATE_COLLAB_QUESTIONNAIRE_SUCCESS = 'UPDATE_COLLAB_QUESTIONNAIRE_SUCCESS';
export const UPDATE_COLLAB_QUESTIONNAIRE_FAILURE = 'UPDATE_COLLAB_QUESTIONNAIRE_FAILURE';

// update scope or review
export const UPDATE_SCOPE_OF_REVIEW_REQUEST = 'UPDATE_SCOPE_OF_REVIEW_REQUEST';
export const UPDATE_SCOPE_OF_REVIEW_SUCCESS = 'UPDATE_SCOPE_OF_REVIEW_SUCCESS';
export const UPDATE_SCOPE_OF_REVIEW_FAILURE = 'UPDATE_SCOPE_OF_REVIEW_FAILURE';

// close collab
export const CLOSE_COLLAB_REQUEST = 'CLOSE_COLLAB_REQUEST';
export const CLOSE_COLLAB_SUCCESS = 'CLOSE_COLLAB_SUCCESS';
export const CLOSE_COLLAB_FAILURE = 'CLOSE_COLLAB_FAILURE';

// get tasting methodology
export const GET_TASTING_METHODOLOGY_REQUEST = 'GET_TASTING_METHODOLOGY_REQUEST';
export const GET_TASTING_METHODOLOGY_SUCCESS = 'GET_TASTING_METHODOLOGY_SUCCESS';
export const GET_TASTING_METHODOLOGY_FAILURE = 'GET_TASTING_METHODOLOGY_FAILURE';

//add new address
export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

//product collaboration  apply
export const APPLY_PRODUCT_COLLABORATION_REQUEST = 'APPLY_PRODUCT_COLLABORATION_REQUEST';
export const APPLY_PRODUCT_COLLABORATION_SUCCESS = 'APPLY_PRODUCT_COLLABORATION_SUCCESS';
export const APPLY_PRODUCT_COLLABORATION_FAILURE = 'APPLY_PRODUCT_COLLABORATION_FAILURE';
export const ACCEPT_COLLABORATION_INVITE_SUCCESS = 'ACCEPT_COLLABORATION_INVITE_SUCCESS';

// reject collaboration
export const REJECT_COLLABORATION_INVITE_REQUEST = 'REJECT_COLLABORATION_INVITE_REQUEST';
export const REJECT_COLLABORATION_INVITE_SUCCESS = 'REJECT_COLLABORATION_INVITE_SUCCESS';
export const REJECT_COLLABORATION_INVITE_FAILURE = 'REJECT_COLLABORATION_INVITE_FAILURE';

// outlets cities
export const GET_COLLABORATION_OUTLET_REQUEST = 'GET_COLLABORATION_OUTLET_REQUEST';
export const GET_COLLABORATION_OUTLET_SUCCESS = 'GET_COLLABORATION_OUTLET_SUCCESS';
export const GET_COLLABORATION_OUTLET_FAILURE = 'GET_COLLABORATION_OUTLET_FAILURE';
export const ADD_COLLABORATION_OUTLET = 'ADD_COLLABORATION_OUTLET';
export const REMOVE_COLLABORATION_OUTLET = 'REMOVE_COLLABORATION_OUTLET';
export const UPDATE_COLLABORATION_OUTLET = 'UPDATE_COLLABORATION_OUTLET';
export const RESET_COLLABORATION_OUTLET = 'RESET_COLLABORATION_OUTLET';

// Contest collab reject document
export const REJECT_FILE_UPLOADED_CONTENT_COLLAB = 'REJECT_FILE_UPLOADED_CONTENT_COLLAB';

// APPLICATION CHANGES
export const UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_REQUEST = 'UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_REQUEST';
export const UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_SUCCESS = 'UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_SUCCESS';
export const UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_FAILURE = 'UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_FAILURE';

// AGE PROOF DOCUMENT STATES
export const ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS = 'ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS';
export const REJECT_AGE_PROOF_DOCUMENT_SUCCESS = 'REJECT_AGE_PROOF_DOCUMENT_SUCCESS';

//PREVIEW USER
export const PREVIEW_USER_REQUEST = 'PREVIEW_USER_REQUEST';
export const PREVIEW_USER_SUCCESS = 'PREVIEW_USER_SUCCESS';
export const PREVIEW_USER_FAILURE = 'PREVIEW_USER_FAILURE';

// RESET PREVIEW USER
export const PREVIEW_USER_RESET = 'PREVIEW_USER_RESET';

// PREVIEW DETAILS
export const PREVIEW_DETAILS_REQUEST = 'PREVIEW_DETAILS_REQUEST';
export const PREVIEW_DETAILS_SUCCESS = 'PREVIEW_DETAILS_SUCCESS';
export const PREVIEW_DETAILS_FAILURE = 'PREVIEW_DETAILS_FAILURE';

// PREVIEW DETAILS RESET
export const RESET_PREVIEW_DETAILS = 'RESET_PREVIEW_DETAILS';
export const SURVEY_PREVIEW_SUCCESS = 'SURVEY_PREVIEW_SUCCESS';


