/**
 * when refactoring
 * read the following
 * https://redux.js.org/recipes/structuring-reducers/refactoring-reducer-example
 * and remove this comment when refactoring done
 */

import {
  FEED_REQUEST,
  FEED_SUCCESS,
  FEED_FAILURE,
  LIKE_A_POST_SUCCESS,
  LIKE_A_POST_REQUEST,
  LIKE_A_POST_FAILURE,
  BLOCK_USER_SUCCESS,
} from '../.././actions/constants';
import {
  CREATE_A_COMMENT_SUCCESS,
  DELETE_A_POST_SUCCESS,
  UPDATE_A_POST_SUCCESS,
  UPDATE_A_SHARED_POST,
  UPDATE_A_PHOTO_POST_SUCCESS,
  UPDATE_A_POLL_SUCCESS,
} from '../../actions/common/constants';
import { PLAY_A_POLL_SUCCESS, RENEW_A_POLL_SUCCESS } from '../../actions/poll/constants';
import {
  EMPTY_SOCKET_FEED,
  ADD_TO_SOCKET_FEED,
  GET_A_POST_COMMENT_SUCCESS,
  DELETE_A_COMMENT_SUCCESS,
  ADD_TO_FEED_VIA_SOCKET,
  RESET_FEED,
} from '../../actions/feed/constants';
import { FOLLOW_USER_SUCCESS } from '../../actions/profile/constants';
import { FOLLOW_COMPANY_SUCCESS } from '../../actions/company/constants';
import { feedPostTypes } from '../../utils/commonVariables';
import { FLAG_REPORT_POST_SUCCESS } from '../../actions/flags/constants';
import { _handleReportedContent } from '../../utils/reducerHelpers';
import get from 'lodash/get';

const INITIAL_STATE = {
  feed: [],
  fetching: false,
  error: false,
  page: 1,
  hasEndReached: false,
  socketPosts: [],
  hasFetched: false,
};

const _isCurrentPost = (action, feedCard) => {
  return (
    (!action.payload.isShared &&
      feedCard[action.payload.postType] &&
      !feedCard.shared &&
      feedCard[action.payload.postType].id === action.payload.postId) ||
    (action.payload.isShared &&
      feedCard.shared &&
      feedCard.shared.id === action.payload.sharedId) ||
    (action.payload.isAd &&
      feedCard[feedPostTypes.advertisement] &&
      feedCard[feedPostTypes.advertisement].payload &&
      feedCard[feedPostTypes.advertisement].payload[action.payload.postType] &&
      feedCard[feedPostTypes.advertisement].payload[action.payload.postType].id ===
        action.payload.postId)
  );
};

export const updateMetaForLike = (action, meta) => {
  return {
    ...meta,
    hasLiked:
    action.type === LIKE_A_POST_SUCCESS // like status & count based on API response
      ? action.payload.hasLiked
      : action.type === LIKE_A_POST_REQUEST
      ? !action.payload.hasLiked
      : action.payload.hasLiked,
    likeCount:
      action.type === LIKE_A_POST_SUCCESS
        ? action.payload.likeCount
        : action.type === LIKE_A_POST_REQUEST
          ? action.payload.hasLiked
            ? meta.likeCount === 0
              ? 0
              : meta.likeCount - 1
            : meta.likeCount + 1
          : action.type === LIKE_A_POST_FAILURE
            ? action.payload.hasLiked
              ? meta.likeCount + 1
              : meta.likeCount === 0
                ? 0
                : meta.likeCount - 1
            : meta.likeCount,

  };
};

export default function feed(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FEED_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case FEED_SUCCESS:
      return {
        ...state,
        fetching: false,
        feed: [...state.feed, ...action.payload],
        page: state.page + 1,
        hasEndReached: !action.payload || (action.payload && !action.payload.length),
        hasFetched: true,
      };
    // case BLOCK_USER_REQUEST:
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        feed: state.feed.filter(_=>get(_, [action.payload.user_type,"id"],false)!==action.payload.id)
      };
    case FEED_FAILURE:
      return {
        ...state,
        fetching: false,
        ...(!action.error.__CANCEL__ && {
          error: action.error,
        }),
      };
    case LIKE_A_POST_REQUEST:
    case LIKE_A_POST_SUCCESS:
    case LIKE_A_POST_FAILURE:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (_isCurrentPost(action, feedCard)) {
                return {
                  ...feedCard,
                  meta: action.payload.isAd
                    ? feedCard.meta
                    : updateMetaForLike(action, feedCard.meta),
                  likeActionProcessing: action.type === LIKE_A_POST_REQUEST,
                  ...(!!(
                    action.payload.isAd &&
                    feedCard[feedPostTypes.advertisement] &&
                    feedCard[feedPostTypes.advertisement].payload &&
                    feedCard[feedPostTypes.advertisement].payload.meta
                  ) && {
                    [feedPostTypes.advertisement]: {
                      ...feedCard[feedPostTypes.advertisement],
                      payload: {
                        ...feedCard[feedPostTypes.advertisement].payload,
                        meta: updateMetaForLike(
                          action,
                          feedCard[feedPostTypes.advertisement].payload.meta,
                        ),
                      },
                    },
                  }),
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case CREATE_A_COMMENT_SUCCESS: // New comment added
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (_isCurrentPost(action, feedCard)) {
                return {
                  ...feedCard,
                  meta: action.payload.isAd
                    ? feedCard.meta
                    : {
                        ...feedCard.meta,
                        ...action.payload.meta,
                      },
                  ...(!!(
                    action.payload.isAd &&
                    feedCard[feedPostTypes.advertisement] &&
                    feedCard[feedPostTypes.advertisement].payload &&
                    feedCard[feedPostTypes.advertisement].payload.meta
                  ) && {
                    [feedPostTypes.advertisement]: {
                      ...feedCard[feedPostTypes.advertisement],
                      payload: {
                        ...feedCard[feedPostTypes.advertisement].payload,
                        meta: {
                          ...feedCard[feedPostTypes.advertisement].payload.meta,
                          ...action.payload.meta,
                        },
                      },
                    },
                  }),
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case GET_A_POST_COMMENT_SUCCESS: // Update fetched comments count
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (_isCurrentPost(action, feedCard)) {
                return {
                  ...feedCard,
                  meta: {
                    ...feedCard.meta,
                    commentCount: action.payload.count || 0,
                  },
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case DELETE_A_COMMENT_SUCCESS:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (_isCurrentPost(action, feedCard)) {
                return {
                  ...feedCard,
                  meta: action.payload.isAd
                    ? feedCard.meta
                    : {
                        ...feedCard.meta,
                        commentCount:
                          feedCard.meta.commentCount - 1 === 0 ? 0 : feedCard.meta.commentCount - 1,
                      },
                  ...(!!(
                    action.payload.isAd &&
                    feedCard[feedPostTypes.advertisement] &&
                    feedCard[feedPostTypes.advertisement].payload &&
                    feedCard[feedPostTypes.advertisement].payload.meta
                  ) && {
                    [feedPostTypes.advertisement]: {
                      ...feedCard[feedPostTypes.advertisement],
                      payload: {
                        ...feedCard[feedPostTypes.advertisement].payload,
                        meta: {
                          ...feedCard[feedPostTypes.advertisement].payload.meta,
                          commentCount:
                            feedCard[feedPostTypes.advertisement].payload.meta.commentCount - 1 < 0
                              ? 0
                              : feedCard[feedPostTypes.advertisement].payload.meta.commentCount - 1,
                        },
                      },
                    },
                  }),
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case DELETE_A_POST_SUCCESS: // Remove from a post success
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.filter((feedCard) => {
              if (
                (action.payload.isShared &&
                  action.payload.sharedId &&
                  !!feedCard.shared &&
                  feedCard.shared.id === action.payload.sharedId) ||
                (!action.payload.isShared &&
                  !action.payload.sharedId &&
                  !feedCard.shared &&
                  feedCard[action.payload.postType] &&
                  feedCard[action.payload.postType].id === action.payload.postId)
              )
                return;
              return feedCard;
            })
          : state.feed,
      };
    case PLAY_A_POLL_SUCCESS:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (feedCard.type !== 'polling') return feedCard;
              if (
                action.payload.polling &&
                feedCard.polling &&
                feedCard.polling.id &&
                action.payload.polling.id &&
                feedCard.polling.id === action.payload.polling.id
              ) {
                if (feedCard.shared) {
                  return {
                    ...feedCard,
                    meta: {
                      ...feedCard.meta,
                      originalPostMeta: action.payload.meta,
                    },
                    polling: {
                      ...action.payload.polling,
                      poll_meta: action.payload.meta,
                    },
                  };
                }
                return {
                  ...feedCard,
                  ...action.payload,
                  ...(action.payload.polling &&
                    !action.payload.polling.poll_meta && {
                      polling: {
                        ...action.payload.polling,
                        poll_meta: action.payload.meta,
                      },
                    }),
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case RENEW_A_POLL_SUCCESS:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (feedCard.type !== 'polling' || feedCard.shared) return feedCard;
              if (
                action.payload.pollId &&
                feedCard.polling &&
                feedCard.polling.id &&
                feedCard.polling.id === action.payload.pollId
              ) {
                return {
                  ...feedCard,
                  polling: {
                    ...feedCard.polling,
                    poll_meta: {
                      ...feedCard.polling.poll_meta,
                      is_expired: 0,
                    },
                  },
                  meta: {
                    ...feedCard.meta,
                    is_expired: 0,
                  },
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case ADD_TO_FEED_VIA_SOCKET:
      return {
        ...state,
        feed: state.hasFetched ? [action.payload, ...state.feed] : state.feed,
      };
    case ADD_TO_SOCKET_FEED:
      return {
        ...state,
        socketPosts: state.hasFetched ? [...state.socketPosts, action.payload] : state.socketPosts,
      };
    case EMPTY_SOCKET_FEED:
      return {
        ...state,
        socketPosts: [],
      };
    case UPDATE_A_POST_SUCCESS:
    case UPDATE_A_PHOTO_POST_SUCCESS:
    case UPDATE_A_POLL_SUCCESS:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (
                feedCard.type === action.payload.postType &&
                !feedCard.shared &&
                feedCard[feedCard.type] &&
                feedCard[feedCard.type].id === action.payload.data[action.payload.postType].id
              ) {
                return {
                  ...feedCard,
                  ...action.payload.data,
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case UPDATE_A_SHARED_POST:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (feedCard.shared && feedCard.shared.id === action.payload.id) {
                return {
                  ...feedCard,
                  shared: {
                    ...feedCard.shared,
                    ...action.payload,
                  },
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case FOLLOW_USER_SUCCESS:
    case FOLLOW_COMPANY_SUCCESS:
      return {
        ...state,
        feed: state.hasFetched
          ? state.feed.map((feedCard) => {
              if (
                feedCard.type === 'suggestion' &&
                feedCard.suggestion &&
                feedCard.suggestion.length &&
                feedCard.meta &&
                feedCard.meta.sub_type &&
                (feedCard.meta.sub_type === 'profile' || feedCard.meta.sub_type === 'company')
              ) {
                return {
                  ...feedCard,
                  suggestion: feedCard.suggestion.map((_) => {
                    if (
                      (action.type === 'FOLLOW_USER_SUCCESS' &&
                        _.id === action.payload.profileId) ||
                      (action.type === 'FOLLOW_COMPANY_SUCCESS' &&
                        _.company_id &&
                        _.company_id === action.payload.companyId)
                    ) {
                      return {
                        ..._,
                        isFollowing: true,
                      };
                    }
                    return _;
                  }),
                };
              }
              if (
                feedCard.type === 'feedCard' &&
                feedCard.meta.type === 'profile' &&
                action.type === 'FOLLOW_USER_SUCCESS' &&
                feedCard.feedCard &&
                feedCard.feedCard.profile &&
                feedCard.feedCard.profile.id &&
                feedCard.feedCard.profile.id === action.payload.profileId
              ) {
                return {
                  ...feedCard,
                  meta: {
                    ...feedCard.meta,
                    isFollowing: true,
                  },
                };
              }
              if (
                feedCard.type === 'feedCard' &&
                feedCard.meta.type === 'company' &&
                action.type === 'FOLLOW_COMPANY_SUCCESS' &&
                feedCard.feedCard &&
                feedCard.feedCard.company &&
                feedCard.feedCard.company.id &&
                feedCard.feedCard.company.id === action.payload.companyId
              ) {
                return {
                  ...feedCard,
                  meta: {
                    ...feedCard.meta,
                    isFollowing: true,
                  },
                };
              }
              return feedCard;
            })
          : state.feed,
      };
    case FLAG_REPORT_POST_SUCCESS:
      return {
        ...state,
        ...(state.hasFetched && {
          feed: state.feed.filter((_) => _handleReportedContent(_, action.payload)),
        }),
      };
    case RESET_FEED:
      return INITIAL_STATE;
    default:
      return state;
  }
}
