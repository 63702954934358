import { collaborationInternalPath } from "./collaborationHelper";
import { createQueryParameterFromArray } from "./helper";

// companies
export const companiesLink = {
  view: (companyId) => `/companies/${companyId}`,
  all: `/companies`,
},
  profileLinks = {
    view: (handle) => `/@${handle}`,
    myPassbook: `/passbook`,
    myCollaborations: `/my-collaborations`,
    myTastings: `/my-tastings`,
    mySurvey: `/my-survey`,
    myQuiz: `/my-quiz`,
    myCertification: `/my-certification`,
    myInvitation: `/my-invitations`,
    myWorkshop: `/my-workshop`,
    all: `/people`,
    photos: (handle) => `/@${handle}/photos`,
    badges: (handle) => `/@${handle}/badges`,
    allWithSearchParams: (type, term) => `/people?${type}=${term}`,
    paidTasterProgram: "/paid-taster-program",
    viewById: (id) => `/profile/${id}`,
    company: (id) => `/companies/${id}`,
  },
  productLinks = {
    all: `/reviews/products`,
    allWithFilters: (filterData) =>
      `/reviews/products/${createQueryParameterFromArray(filterData)}`,
    view: (productId) => `/reviews/products/${productId}`,
    reviewLink: (productId) => `/reviews/products/${productId}/review`,
  },
  collectionLinks = {
    single: (collectionId) => `/collection/${collectionId}`,
  },
  reviewLinks = {
    all: `/reviews`,
  },
  exploreLink = `/explore`,
  surveyLinks = {
    create: `/surveys/create`,
    view: (id) => `/surveys/${id}`,
    edit: (id) => `/surveys/${id}/edit`,
    all: `/surveys`,
    fill: (id) => `/surveys/${id}/fill`,
    reports: (id) => `/surveys/${id}/reports`,
    reportGraph: (id) => `/surveys/${id}/reports/graph`,
    reportSummary: (id) => `/surveys/${id}/reports/summary`,
    reportRespondents: (id) => `/surveys/${id}/reports/respondents`,
    manage: (id) => `/surveys/${id}/manage`,
    preview: (id) => `/preview/survey/${id}`,
    previewFill: (id) => `/surveys/${id}/preview`,
    templatePreview: () => `/template/preview`
  },
  quizLinks = {
    create: "/quiz/create",
    view: (id) => `/quiz/${id}`,
    edit: (id) => `/quiz/${id}/edit`,
    all: `/quiz`,
    play: (id) => `/quiz/${id}/play`,
    reports: (id) => `/quiz/${id}/reports`,
  },
  certificateLinks = {
    create: "/certification/create",
    view: (id) => `/certification/${id}`,
    edit: (id) => `/certification/${id}/edit`,
    fill: (id) => `/certifications/${id}/fill`,
    single: (id) => `/certifications/${id}`,
    all: `/certifications`,
  },
  polling = {
    view: (id) => `/polling/${id}`,
    all: `/polls`,
  },
  workshopLinks = {
    view: (id) => `/workshop/${id}`,
    all: '/workshop',
  };

// function to get links
export const getInternalLink = (
  postType,
  postId,
  sharedId,
  getDirectLink = false,
  typeOfPost
) => {
  return sharedId && !getDirectLink
    ? `/shared/${sharedId}/${postType}/${postId}`
    : postType === "collaborate"
      ? collaborationInternalPath.view(typeOfPost, postId)
      : postType === "product"
        ? productLinks.view(postId)
        : `/${postType}/${postId}`;
};

// function for followers links
export const followInternalLinks = {
  profileFollow: (profileId) => `/profile/${profileId}/followers`,
  profileFollowing: (profileId) => `/profile/${profileId}/following`,
  companyFollow: (profileId, companyId) =>
    `/profile/${profileId}/companies/${companyId}/followers`,
  companyFollowing: (profileId, companyId) =>
    `/profile/${profileId}/companies/${companyId}/following`,
};

// function for photo links
export const photosInternalLinks = {
  photoLink: (handle) => `@${handle}/photos`,
  photoLinkCompany: (profileId, companyId) =>
    `/profile/${profileId}/companies/${companyId}/photos`,
  singlePhoto: (id) => `/photo/${id}`,
};

export const moreBadgesLink = (handle) => { return `@${handle}/badges` }

export const homeLink = `/home`;
export const feedLink = `/feed`;
export const onboardingLink = `/onboarding`;

export const miscLinks = {
  home: "/",
  login: "/login",
  register: "/register",
  about: "/about",
  career: "/career",
  blog: "https://blog.tagtaste.com",
  terms: "/terms",
  privacy: "/privacy",
  knowledge: "/knowledge",
  forgotPassword: "/password/forgot",
  resetPassword: "/password/reset",
  settings: "/settings",
  blockedAccounts: "/block-accounts",
  notFound: "/not-found",
  communityGuidelines: "/community",
  faq: "/faqs",
  shoutout: (id) => `/shoutout/${id}`,
  accountManagement: (typeId) => `/accountmanagement/${typeId}`,
  services: '/services',
  infoLinks: {
    redeemEarning: '/info/redeem-earnings'
  },
  successStories: (customerId) => `/success-stories/${customerId}`,
};

export const hashtagLinks = {
  feed: (hashtag) => `/feed/hashtag/${hashtag}`,
  trending: `/trending/hashtag`,
  emptyFeed: `/feed/hashtag`,
};

export const careerLinks = (type, url) => `/career/${type}/${url}`;

export const collaborationInternalLinks = {
  create: (collabType) =>
    `collaborations/create${collabType !== "collaborate" && collabType ? "/" + collabType : ""
    }`,
  tastingProcess: (collabId, batchId, addressId) =>
    `/collaborations/${collabId}/product-review/tasting/batch/${batchId}${addressId ? "?address_id=" + addressId : ""
    }`,
  collaboratePage: (collabId) => `/collaborations/${collabId}`,
  allProducts: `/reviews/products`,
  productPage: (prodId) => `/reviews/products/${prodId}`,
  all: `collaborations`,
  productReviews: "/product-reviews",
  productReviewPage: (id) => `/collaborations/${id}/product-review/`,
  previewQuestionnaire: (id) => `/preview/questionnaire/${id}/view`
};

export const ttfbLinks = {
  leadsManagement: {
    manufacturers: (groupId) => `/business/${groupId}/leads/manufacturers`,
    overview: (groupId) => `/business/${groupId}/leads`,
    products: (groupId) => `/business/${groupId}/leads/products`,
    distributors: (groupId) => `/business/${groupId}/leads/distributors`,
    partners: (groupId) => `/business/${groupId}/leads/partners`,
    product: (productId, groupId) =>
      `/business/${groupId}/leads/products/${productId}`,
    allProductLinks: (type, userIds, groupId) =>
      `/business/${groupId}/leads/products?${type}=${userIds}${type !== "manufacturer_id" ? "&showActive=true" : ""
      }`,
  },
  manufacturers: `/business/manufacturers`,
  overview: `/business`,
  products: `/business/products`,
  distributors: `/business/distributors`,
  partners: `/business/partners`,
  product: (productId) => `/business/products/${productId}`,
  allProductLinks: (type, userIds) =>
    `/business/products?${type}=${userIds}${type !== "manufacturer_id" ? "&showActive=true" : ""
    }`,
  consistency: {
    index: (groupId) => `/business/${groupId}/consistency`,
    product: (groupId, productId) =>
      `/business/${groupId}/consistency/products/${productId}`,
    batch: {
      review: (groupId, productId, batchId) =>
        `/business/${groupId}/consistency/products/${productId}/batches/${batchId}/review`,
    },
    reports: {
      product: (groupId, productId) =>
        `/business/${groupId}/consistency/products/${productId}/reports`,
      batch: (groupId, productId, batchId) =>
        `/business/${groupId}/consistency/products/${productId}/reports/${batchId}`,
    },
    members: (groupId) => `/business/${groupId}/consistency/members`,
    products: (groupId) => `/business/${groupId}/consistency/products`,
    closedProducts: (groupId) =>
      `/business/${groupId}/consistency/products/closed`,
  },
  trackConsistency: (groupId) => `/business/${groupId}/consistency`,
  consistencyMembers: (groupId) => `/business/${groupId}/consistency/members`,
  consistencyAllProducts: (groupId) =>
    `/business/${groupId}/consistency/products`,
  consistencyClosedProducts: (groupId) =>
    `/business/${groupId}/consistency/products/closed`,
  consistencyProduct: (groupId, productId) =>
    `/business/${groupId}/consistency/products/${productId}`,
};
