import ServicesCollaborationIcon from "../../../assets/images/services/icons/ServicesCollaborationIcon.svg"
import ServicesDataAnalyticsIcon from "../../../assets/images/services/icons/ServicesDataAnalyticsIcon.svg"
import ServicesNPDIcon from "../../../assets/images/services/icons/ServicesNPDIcon.svg"
import ServicesQualityAssuranceIcon from "../../../assets/images/services/icons/ServicesQualityAssuranceIcon.svg"
import ServicesResearchIcon from "../../../assets/images/services/icons/ServicesResearchIcon.svg"
import ServicesSensoryIcon from "../../../assets/images/services/icons/ServicesSensoryIcon.svg"
import ServicesStarRatingIcon from "../../../assets/images/services/icons/ServicesStarRatingIcon.svg"
import ServicesTrainingIcon from "../../../assets/images/services/icons/ServicesTrainingIcon.svg"
// Parameters Icons
import ParamConsumerIcon from "../../../assets/images/services/icons/ParamConsumer study.svg"
import ParamLearningIcon from "../../../assets/images/services/icons/ParamLearning.svg"
import ParamResearchIcon from "../../../assets/images/services/icons/ParamResearch.svg"
import ParamstarIcon from "../../../assets/images/services/icons/ParamStar.svg"
import ParamTrainingIcon from "../../../assets/images/services/icons/ParamTraining.svg"

//Partners Logos
import PartnerFssaiLogo from "../../../assets/images/services/brand-logo/trusted-fssai.png"
import PartnerGoodFoodLogo from "../../../assets/images/services/brand-logo/trusted-good-food.png"
import PartnerIfcaLogo from "../../../assets/images/services/brand-logo/trusted-ifca.png"
import PartnerIgbLogo from "../../../assets/images/services/brand-logo/trusted-IGB.png"
import PartnerIimlLogo from "../../../assets/images/services/brand-logo/trusted-IIML.png"
import PartnerIitjLogo from "../../../assets/images/services/brand-logo/trusted-IITJ.png"
import PartnerNiftemLogo from "../../../assets/images/services/brand-logo/trusted-niftem.png"
import PartnerOberoiLogo from "../../../assets/images/services/brand-logo/trusted-oberoi.png"
import PartnerWacsLogo from "../../../assets/images/services/brand-logo/trusted-WACS.png"
//Domain Icons
import DomainAlcoholIcon from "../../../assets/images/services/icons/domain-alcoholic.png";
import DomainBreadsIcon from "../../../assets/images/services/icons/domain-breads.png";
import DomainBurgerIcon from "../../../assets/images/services/icons/domain-burger.png";
import DomainCerealsIcon from "../../../assets/images/services/icons/domain-cereals.png";
import DomainChocolateIcon from "../../../assets/images/services/icons/domain-chocolate.png";
import DomainCoffeeIcon from "../../../assets/images/services/icons/domain-coffee.png";
import DomainFatsIcon from "../../../assets/images/services/icons/domain-fats.png";
import DomainFruitsIcon from "../../../assets/images/services/icons/domain-fruits.png";
import DomainGrainsIcon from "../../../assets/images/services/icons/domain-grains.png";
import DomainIndianIcon from "../../../assets/images/services/icons/domain-indian.png";
import DomainLentilsIcon from "../../../assets/images/services/icons/domain-lentils.png";
import DomainMarqueeIcon from "../../../assets/images/services/icons/domain-marquee.png";
import DomainMeatIcon from "../../../assets/images/services/icons/domain-meat.png";
import DomainMilkIcon from "../../../assets/images/services/icons/domain-milk.png";
import DomainNonAlcoholicIcon from "../../../assets/images/services/icons/domain-non-alcoholic.png";
import DomainNutsIcon from "../../../assets/images/services/icons/domain-nuts.png";
import DomainPizzaIcon from "../../../assets/images/services/icons/domain-pizza.png";
import DomainPoultryIcon from "../../../assets/images/services/icons/domain-poultry.png";
import DomainRiceIcon from "../../../assets/images/services/icons/domain-rice.png";
import DomainSaltyIcon from "../../../assets/images/services/icons/domain-salty.png";
import DomainSaucesIcon from "../../../assets/images/services/icons/domain-sauces.png";
import DomainSpicesIcon from "../../../assets/images/services/icons/domain-spices.png";
import DomainTeaIcon from "../../../assets/images/services/icons/domain-tea.png";
// Customer Logos
import BercosLogo from "../../../assets/images/services/brand-logo/customer-bercos.png"
import BikanerwalaLogo from "../../../assets/images/services/brand-logo/customer-bikanerwala.png"
import BimboLogo from "../../../assets/images/services/brand-logo/customer-bimbo.png"
import BkCafeLogo from "../../../assets/images/services/brand-logo/customer-bkcafe.png"
import BurgerKingLogo from "../../../assets/images/services/brand-logo/customer-burger-king.png"
import CoffeeBeanLogo from "../../../assets/images/services/brand-logo/customer-coffee-bean.png"
import DominosLogo from "../../../assets/images/services/brand-logo/customer-dominos.png"
import GelatoLogo from "../../../assets/images/services/brand-logo/customer-gelato.png"
import HaldiramsLogo from "../../../assets/images/services/brand-logo/customer-haldirams.png"
import HarvestLogo from "../../../assets/images/services/brand-logo/customer-harvest.png"
import McCainLogo from "../../../assets/images/services/brand-logo/customer-mcCain.png"
import McdonaldsLogo from "../../../assets/images/services/brand-logo/customer-mcdonalds.png"
import ModernLogo from "../../../assets/images/services/brand-logo/customer-modern.png"
import PfchangsLogo from "../../../assets/images/services/brand-logo/customer-pfchangs.png"
import PindLogo from "../../../assets/images/services/brand-logo/customer-pind.png"
import CustomerPizzaLogo from "../../../assets/images/services/brand-logo/customer-pizza.png"
import PrabhujiLogo from "../../../assets/images/services/brand-logo/customer-prabhuji.png"
import SubwayLogo from "../../../assets/images/services/brand-logo/customer-subway.png"
import KittyLogo from "../../../assets/images/services/brand-logo/customer-kitty.png"
import CremicaLogo from "../../../assets/images/services/brand-logo/customer-cremica.png"
import LovelyBakeLogo from "../../../assets/images/services/brand-logo/customer-lovely-bake.png"
import GeneralMillsLogo from "../../../assets/images/services/brand-logo/customer-general-mills.png"
import DanbroLogo from "../../../assets/images/services/brand-logo/customer-danbro.png"
import PristineLogo from "../../../assets/images/services/brand-logo/customer-pristine.png"

import CocaColaLogo from "../../../assets/images/services/brand-logo/customer-coca-cola.png"
import PepsiLogo from "../../../assets/images/services/brand-logo/customer-pepsi.png"
import ChimayLogo from "../../../assets/images/services/brand-logo/customer-chimay.png.png"
import BeliriumLogo from "../../../assets/images/services/brand-logo/customer-belirium.png"
import SleepyOWlLogo from "../../../assets/images/services/brand-logo/customer-sleepy-owl.png"
import VerkaLogo from "../../../assets/images/services/brand-logo/customer-verka.png"
import HeritageLogo from "../../../assets/images/services/brand-logo/customer-heritage.png"
import AuricLogo from "../../../assets/images/services/brand-logo/customer-auric.png"

import AllanaLogo from "../../../assets/images/services/brand-logo/customer-allana.png"
import PerdueLogo from "../../../assets/images/services/brand-logo/customer-perdue.png"
import InterpocLogo from "../../../assets/images/services/brand-logo/customer-interporc.png"

import CargillLogo from "../../../assets/images/services/brand-logo/customer-cargill.png"

import BecLogo from "../../../assets/images/services/brand-logo/customer-bec.png"
import ModelezLogo from "../../../assets/images/services/brand-logo/customer-mondelex.png"
import DoleLogo from "../../../assets/images/services/brand-logo/customer-dole.png"
import DaawatLogo from "../../../assets/images/services/brand-logo/customer-daawat.png"
import RganikLogo from "../../../assets/images/services/brand-logo/customer-rganik.png"
import G20Logo from "../../../assets/images/services/brand-logo/customer-g20.png"
import YogaBarLogo from "../../../assets/images/services/brand-logo/customer-yoga-bar.png"
import NuttyLogo from "../../../assets/images/services/brand-logo/customer-nutty.png"
import ApalLogo from "../../../assets/images/services/brand-logo/customer-apal.png"
import USCranberriesLogo from "../../../assets/images/services/brand-logo/customer-uscranberries.png"
import WinklesLogo from "../../../assets/images/services/brand-logo/customer-winkles.png"

import KikkoManLogo from "../../../assets/images/services/brand-logo/customer-kikkoman.png"
import NewlyWedsLogo from "../../../assets/images/services/brand-logo/customer-newlyweds.png"
import MidasLogo from "../../../assets/images/services/brand-logo/customer-midas.png"
import ChukdeLogo from "../../../assets/images/services/brand-logo/customer-chukde.png"

import ZydusLogo from "../../../assets/images/services/brand-logo/customer-zydus.png.png"
import WholeEarthLogo from "../../../assets/images/services/brand-logo/customer-whole-earth.png"

import UBSLogo from "../../../assets/images/services/brand-logo/customer-ubs.png"

import OlaLogo from "../../../assets/images/services/brand-logo/customer-ola.png"
import OyoLogo from "../../../assets/images/services/brand-logo/customer-oyo.png"

import AashitaNarula from "../../../assets/images/services/team-photos/AashitaNarula.png"
import ArunTangri from "../../../assets/images/services/team-photos/ArunTangri.png"
import ChefAnilGrover from "../../../assets/images/services/team-photos/ChefAnilGrover.png"
import ChefManjitGill from "../../../assets/images/services/team-photos/ChefManjitGill.png"
import ChefVeenaArora from "../../../assets/images/services/team-photos/ChefVeenaArora.png"
import DrBinodMaitin from "../../../assets/images/services/team-photos/DrBinodMaitin.png"
import DrDeepaPrakash from "../../../assets/images/services/team-photos/DrDeepaPrakash.png"
import JaspalSabharwal from "../../../assets/images/services/team-photos/JaspalSabharwal.png"
import NikhilBharti from "../../../assets/images/services/team-photos/NikhilBharti.png"
import ProfAshishDubey from "../../../assets/images/services/team-photos/ProfAshishDubey.png"
import RajPritySabharwal from "../../../assets/images/services/team-photos/RajPritySabharwal.png"
import RasikaSingh from "../../../assets/images/services/team-photos/RasikaSingh.png"
import TanviSharma from "../../../assets/images/services/team-photos/TanviSharma3.png"
import HarshArora from "../../../assets/images/services/team-photos/HarshArora.png"
import UpandraRai from "../../../assets/images/services/team-photos/UpandraRai.png"
import DollyAgarwal from "../../../assets/images/services/team-photos/DollyAgarwal.png"



import KikkomanIcon from "../../../assets/images/services/successStory/kikkoman-icon.png";
import Kikkoman from "../../../assets/images/services/successStory/kikkoman.png";
import CocaCola from "../../../assets/images/services/successStory/coca-cola.png";
import Dole from "../../../assets/images/services/successStory/dole.png";
import DominosIcon from "../../../assets/images/services/successStory/dominosIcon.png";
import Dominos from "../../../assets/images/services/successStory/dominosLogo.png";
import Karnatka from "../../../assets/images/services/successStory/Karnatka.png";
import Mondelez from "../../../assets/images/services/successStory/modelez.png";


import PlantMadeLogo from "../../../assets/images/services/brand-logo/customer-plantmade.png";
import HortGoLogo from "../../../assets/images/services/brand-logo/customer-hortgo.png";
import USADryLogo from "../../../assets/images/services/brand-logo/customer-usa-dry.png";
import FritoLayLogo from "../../../assets/images/services/brand-logo/customer-fritolay.png";
import VadhavLogo from "../../../assets/images/services/brand-logo/customer-vadhav.png";
// import PerfectPartnersLogo from "../../../assets/images/services/brand-logo/customer-perfect-partners.png";
import CompassLogo from "../../../assets/images/services/brand-logo/customer-compass.png";
import LilGoodnessLogo from "../../../assets/images/services/brand-logo/customer-lil-goodness.png";


import OnboardingConfirmation from "../../../assets/images/services/about/onboarding-confirmation.svg";
import OnboardingPractice from "../../../assets/images/services/about/onboarding-practice.svg";
import OnboardingProgression from "../../../assets/images/services/about/onboarding-progression.svg";
import OnboardingTraining from "../../../assets/images/services/about/onboarding-training.svg";

import Home from "../../../assets/images/services/about/home.svg";
import FillHome from "../../../assets/images/services/about/home-fill.svg";
import Setting from "../../../assets/images/services/about/setting.svg";
import FillSetting from "../../../assets/images/services/about/setting-fill.svg";
import Chat from "../../../assets/images/services/about/chat.svg";
import FillChat from "../../../assets/images/services/about/chat-fill.svg";
import Collaboration from "../../../assets/images/services/about/collaboration.svg";
import FillCollaboration from "../../../assets/images/services/about/collaboration-fill.svg";
import Dashboard from "../../../assets/images/services/about/dashboard.svg";
import FillDashboard from "../../../assets/images/services/about/dashboard-fill.svg";
import Feed from "../../../assets/images/services/about/feed.svg";
import FillFeed from "../../../assets/images/services/about/feed-fill.svg";
import Hash from "../../../assets/images/services/about/hash.svg";
import FillHash from "../../../assets/images/services/about/hash-fill.svg";
import Notifications from "../../../assets/images/services/about/notification.svg";
import FillNotifications from "../../../assets/images/services/about/notification-fill.svg";
import Payment from "../../../assets/images/services/about/payment.svg";
import FillPayment from "../../../assets/images/services/about/payment-fill.svg";
import Poll from "../../../assets/images/services/about/poll.svg";
import FillPoll from "../../../assets/images/services/about/poll-fill.svg";
import Profile from "../../../assets/images/services/about/profile.svg";
import FillProfile from "../../../assets/images/services/about/profile-fill.svg";
import Quiz from "../../../assets/images/services/about/quiz.svg";
import FillQuiz from "../../../assets/images/services/about/quiz-fill.svg";
import Reviews from "../../../assets/images/services/about/reviews.svg";
import FillReviews from "../../../assets/images/services/about/reviews-fill.svg";
import Search from "../../../assets/images/services/about/search.svg";
import FillSearch from "../../../assets/images/services/about/search-fill.svg";
import Survey from "../../../assets/images/services/about/survey.svg";
import FillSurvey from "../../../assets/images/services/about/survey-fill.svg";





export const SERVICES_TEXTS = {
    title: "Where Every Bite is a Delight and Every Service is a Flavorful Adventure!",
    offers: {
        title: "Services We Offer",
        subtitle: "We offers a range of core services designed to provide decision-centric insights and support for food-related businesses."
    },
    domain: {
        title: "Our Culinary Playground",
        subtitle: "We offers a range of core services designed to provide decision-centric insights and support for food-related businesses."
    },
    categories: {
        title: "Our Clients",
        subtitle: "Join our community of food innovators who are passionate about quality and flavor. Together, we’re redefining what delicious means!"
    },
    stories: {
        title: "Success Stories",
        subtitle: "Success stories demonstrate the effectiveness of the Star Awards Framework in driving innovation, enhancing product quality, and fostering collaboration within the food and beverage industry."
    },
    contact: {
        title: "Get in Touch with Us",
        subtitle: "Have questions about our services, a project in mind, or just curious to learn more? Fill out the form, and our team will reach out to assist you soon."
    },
    onboarding: {
        title: "Onboarding Process - Tasters & Ethnographers",
        subtitle: "From Palate to Process: Onboarding Tasters and Ethnographers Made Simple!"
    },
    insightengine: {
        title: "TagTaste Insight Engine",
        subtitle: "The TagTaste Insight Engine is your gateway to understanding consumer preferences and market trends. With our robust analytics, you can make informed decisions that drive innovation and enhance product quality!"
    },
    platform: {
        title: "Our Digital Platform- Gateway to the World of Food Insights"
    }
}

export const PARAMETERS_LIST = [{
    icon: ParamTrainingIcon,
    title: "Training"
}, {
    icon: ParamResearchIcon,
    title: "Sensory Research"
}, {
    icon: ParamConsumerIcon,
    title: "Consumer Research & NPD"
}, {
    icon: ParamstarIcon,
    title: "Start Awards"
},{
    icon: ParamLearningIcon,
    title: "Learning with TagTaste"
}];


export const ONBOARDING_LIST = [
    {
        icon: OnboardingTraining,
        iconTitle: "Training",
        title: "We take our community of aspiring tasters and ethnographers on a voyage of sensory, scientific, and self discovery which enables them in understanding the common lexicon of food and most importantly a realisation that taste is personal and complex."
    },
    {
        icon: OnboardingPractice,
        iconTitle: "Practice",
        title: "The trained members are required to complete at least three tastings and/or three ethnography probes on the public thread of TagTaste platform."
    },
    {
        icon: OnboardingConfirmation,
        iconTitle: "Confirmation",
        title: "Based on their performance in the training and practice modules, the participants are cleared for basic tastings and/or food studies which entitles them to earn professional fee for every completed assignment."
    },
    {
        icon: OnboardingProgression,
        iconTitle: "Progression",
        title: "Based on the member’s zeal for knowledge, sincerity, discipline and interest they not only start getting complex assignments but also they are offered free advanced training in the food/research category of their interest. For example: Spice, Chocolates, Concept Development, FGD Management etc."
    }
]

export const SERVICES_PARTNERS_LIST = [
     PartnerIfcaLogo,
     PartnerIgbLogo,
     PartnerWacsLogo,
     PartnerOberoiLogo,
     PartnerIimlLogo,
     PartnerNiftemLogo,
     PartnerIitjLogo,
    // PartnerFssaiLogo,
    // PartnerGoodFoodLogo,
]

export const SERVICES_OFFERED_LIST = [
    {
        icon: ServicesTrainingIcon,
        title: "Training: The Heart of Our Success"
    },
    {
    icon: ServicesSensoryIcon,
    title: "Sensory Research, Field Execution, and Analytics"
}, {
    icon: ServicesResearchIcon,
    title: "Consumer Research & NPD"
},  {
    icon: ServicesStarRatingIcon,
    title: "TagTaste Star Rating System"
}, {
    icon: ServicesSensoryIcon,
    title: "Learning with TagTaste"
}, {
    type: "link",
    url: "/services",
    title: "Explore"
}
];

export const SERVICES_DOMAIN_LIST = [{
    icon: DomainBurgerIcon,
    title: "Burger"
}, {
    icon: DomainSpicesIcon,
    title: "Spices, Chillies & Condiments"
}, {
    icon: DomainFatsIcon,
    title: "Fats & Edible Oil"
}, {
    icon: DomainIndianIcon,
    title: "Indian Sweets(Mithai)"
}, {
    icon: DomainPizzaIcon,
    title: "Pizza & Pasta"
}, {
    icon: DomainChocolateIcon,
    title: "Chocolate",
    borderRadius: "16px"
}, {
    icon: DomainGrainsIcon,
    title: "Grains & Millets"
}, {
    icon: DomainLentilsIcon,
    title: "Lentils, Pulses & Pea Proteins"
}, {
    icon: DomainRiceIcon,
    title: "Rice"
}, {
    icon: DomainAlcoholIcon,
    title: "Alcoholic Beverages"
}, {
    icon: DomainCoffeeIcon,
    title: "Coffee"
}, {
    icon: DomainNonAlcoholicIcon,
    title: "Non Alcoholic Beverages"
}, {
    icon: DomainTeaIcon,
    title: "Tea"
}, {
    icon: DomainFruitsIcon,
    title: "Fruits & Veggies"
}, {
    icon: DomainBreadsIcon,
    title: "Breads"
}, {
    icon: DomainPoultryIcon,
    title: "Poultry"
}, {
    icon: DomainSaucesIcon,
    title: "Sauces, Gravies & Condiments"
}, {
    icon: DomainMeatIcon,
    title: "Meat",
     borderRadius: "16px"
}, {
    icon: DomainSaltyIcon,
    title: "Salty Snacks",
     borderRadius: "16px"
}, {
    icon: DomainMarqueeIcon,
    title: "Marquee Traditional Items",
     borderRadius: "16px"
}, {
    icon: DomainNutsIcon,
    title: "Nuts & Seeds"
}, {
    icon: DomainCerealsIcon,
    title: "Cereals"
}, {
    icon: DomainMilkIcon,
    title: "Milk & Milk Products"
}];


export const SERVICES_CATEGORIES_LIST = [
    {
        type: "all",
        title: "All Clients"
    }, {
        type: "restaurant",
        title: "Restaurant Segment"
    }, {
        type: "bakery",
        title: "Bakery & Confectionery"
    }, {
        type: "beverages",
        title: "Beverages including Dairy"
    }, {
        type: "meat",
        title: "Meat & Poultry"
    }, {
        type: "research",
        title: "Futuristic Research"
    }, {
        type: "foreign",
        title: "CPG and Foreign Produce Promotion Boards"
    }, {
        type: "condiments",
        title: "Flavor Houses, Spices & Condiments"
    }, {
        type: "sugar",
        title: "Sugar Alternatives"
    }, {
        type: "caterers",
        title: "Caterers & Aggregators"
    }, {
        type: "market",
        title: "Market Insights"
    },
];


export const SERVICES_CUSTOMERS_LIST = [
    {
        icon: CustomerPizzaLogo,
        type: "restaurant"
    },
    {
        icon: BercosLogo,
        type: "restaurant"
    },
    {
        icon: BurgerKingLogo,
        type: "restaurant"
    },
    {
        icon: DominosLogo,
        type: "restaurant"
    },
    {
        icon: McdonaldsLogo,
        type: "restaurant"
    },
    {
        icon: CoffeeBeanLogo,
        type: "restaurant"
    },
    {
        icon: GelatoLogo,
        type: "restaurant"
    },
    {
        icon: HaldiramsLogo,
        type: "restaurant"
    },
    {
        icon: PrabhujiLogo,
        type: "restaurant"
    },
    {
        icon: PindLogo,
        type: "restaurant"
    },
    {
        icon: McCainLogo,
        type: "restaurant"
    },
    {
        icon: BkCafeLogo,
        type: "restaurant"
    },
    {
        icon: SubwayLogo,
        type: "restaurant"
    },
    {
        icon: BikanerwalaLogo,
        type: "restaurant"
    },
    // {
    //     icon: PfchangsLogo,
    //     type: "restaurant"
    // },
    {
        icon: BimboLogo,
        type: "bakery"
    },
    {
        icon: HarvestLogo,
        type: "bakery"
    },
    {
        icon: ModernLogo,
        type: "bakery"
    },
    {
        icon: KittyLogo,
        type: "bakery"
    },
    {
        icon: CremicaLogo,
        type: "bakery"
    },
    {
        icon: LovelyBakeLogo,
        type: "bakery"
    },
    {
        icon: GeneralMillsLogo,
        type: "bakery"
    },
    // {
    //     icon: DanbroLogo,
    //     type: "bakery"
    // },
    {
        icon: PristineLogo,
        type: "bakery"
    },
    {
        icon: LilGoodnessLogo,
        type: "bakery"
    },
    {
        icon: CocaColaLogo,
        type: "beverages"
    },
    {
        icon: PepsiLogo,
        type: "beverages"
    },
    {
        icon: ChimayLogo,
        type: "beverages"
    },
    {
        icon: BeliriumLogo,
        type: "beverages"
    },
    {
        icon: SleepyOWlLogo,
        type: "beverages"
    },
    // {
    //     icon: VerkaLogo,
    //     type: "beverages"
    // },
    {
        icon: HeritageLogo,
        type: "beverages"
    },
    {
        icon: AuricLogo,
        type: "beverages"
    },
    // {
    //     icon: "src/assets/images/services/successStory/dominos.png",
    //     type: "beverages"
    // },
    // {
    //     icon: "src/assets/images/services/successStory/dominos.png",
    //     type: "beverages"
    // },
    {
        icon: AllanaLogo,
        type: "meat"
    },
    {
        icon: PerdueLogo,
        type: "meat"
    },
    {
        icon: InterpocLogo,
        type: "meat"
    },
    // {
    //     icon: "src/assets/images/services/successStory/dominos.png",
    //     type: "meat"
    // },
    {
        icon: CargillLogo,
        type: "research"
    },
    {
        icon: BecLogo,
        type: "foreign"
    },
    {
        icon: ModelezLogo,
        type: "foreign"
    },
    {
        icon: DaawatLogo,
        type: "foreign"
    },
    {
        icon: RganikLogo,
        type: "foreign"
    },
    {
        icon: G20Logo,
        type: "foreign"
    },
    {
        icon: YogaBarLogo,
        type: "foreign"
    },
    {
        icon: NuttyLogo,
        type: "foreign"
    },
    {
        icon: ApalLogo,
        type: "foreign"
    },
    {
        icon: USCranberriesLogo,
        type: "foreign"
    },
    {
        icon: WinklesLogo,
        type: "foreign"
    },
    {
        icon: VadhavLogo,
        type: "foreign"
    },
    {
        icon: USADryLogo,
        type: "foreign"
    },
    {
        icon: HortGoLogo,
        type: "foreign"
    },
    {
        icon: PlantMadeLogo,
        type: "foreign"
    },
    // {
    //     icon: FritoLayLogo,
    //     type: "foreign"
    // },
    {
        icon: KikkoManLogo,
        type: "condiments"
    },
    {
        icon: NewlyWedsLogo,
        type: "condiments"
    },
    {
        icon: MidasLogo,
        type: "condiments"
    },
    {
        icon: ChukdeLogo,
        type: "condiments"
    },
    // {
    //     icon: "src/assets/images/services/successStory/dominos.png",
    //     type: "condiments"
    // },
    {
        icon: ZydusLogo,
        type: "sugar"
    },
    {
        icon: WholeEarthLogo,
        type: "sugar"
    },
    {
        icon: OlaLogo,
        type: "caterers"
    },
    {
        icon: CompassLogo,
        type: "caterers"
    },
    {
        icon: OyoLogo,
        type: "caterers"
    },
    {
        icon: UBSLogo,
        type: "market"
    }
];


//about 
export const TEAM_CATEGORIES_LIST = [
    {
        type: "leadership",
        title: "Leadership team"
    }, {
        type: "researchBoard",
        title: "Research Protocol Board"
    }
];

export const TEAM_LEADERS_LIST = [
    {
        name: "Jaspal Sabharwal",
        post: "Co-founder & CEO",
        image: JaspalSabharwal,
        team: "leadership"
    }, {
        name: "Arun Tangri",
        post: "Co-founder & COO",
        image: ArunTangri,
        team: "leadership"
    }, {
        name: "Raj Prity Sabharwal",
        post: "Sensory Research & Product Insights",
        image: RajPritySabharwal,
        team: "leadership"
    }, {
        name: "Dolly Agarwal",
        post: "Sensory Research & Training",
        image: DollyAgarwal,
        team: "leadership"
    },{
        name: "Tanvi Sharma",
        post: "Business Operations",
        image: TanviSharma,
        team: "leadership"
    }, {
        name: "Nikhil Kumar Bharti",
        post: "AVP, Engineering",
        image: NikhilBharti,
        team: "leadership"
    }, {
        name: "Aashita Narula",
        post: "AVP, Engineering",
        image: AashitaNarula,
        team: "leadership"
    }, {
        name: "Rasika Singh",
        post: "Industry Collaboration",
        image: RasikaSingh,
        team: "leadership"
    },
    {
        name: "Harsh Arora",
        post: "Product Manager",
        image: HarshArora,
        team: "leadership"
    },
    {
        name: "Upandra Rai",
        post: "Product Designer",
        image: UpandraRai,
        team: "leadership"
    },
    {
        name: "Chef Manjit Gill",
        post: "Culinary Consultant E - ITC",
        image: ChefManjitGill,
        team: "researchBoard"
    },
    {
        name: "Chef Anil Grover",
        post: "Principal CEO at Micro Devices Inc",
        image: ChefAnilGrover,
        team: "researchBoard"
    },{
        name: "Prof. Ashish Dubey",
        post: "Associate Professor",
        subPost: "IIM - Lucknow",
        image: ProfAshishDubey,
        team: "researchBoard"
    }, {
        name: "Dr. Binod Maitin",
        post: "Beverage Expert E - Diageo",
        image: DrBinodMaitin,
        team: "researchBoard"
    }, {
        name: "Dr. Deepa Prakash",
        post: "Food Scientist of repute",
        image: DrDeepaPrakash,
        team: "researchBoard"
    }, {
        name: "Chef Veena Arora",
        post: "Culinary Consultant",
        subPost: "E- The Spice Route, Imperial",
        image: ChefVeenaArora,
        team: "researchBoard"
    }
];


export const SUCCESS_STORIES_LIST = [
    {
        "id": 1,
        "icon": KikkomanIcon,
        "image": Kikkoman,
        "url_link": "kikoman",
        "title": "India Centric New Product Development",
        "subtitle": "Kikkoman launched its first ever dark soy sauce specially crafted for India, after four years of R&D",
        "description": "<p>Kikkoman, in association with TagTaste, developed and tested the Kikkoman Ingredients X Seasoning Pairing system: KIP to evaluate the compatibility of ingredients and seasonings based on their flavor characteristics.</p>",
    },
    // {
    //     "id": 2,
    //     "icon": Karnatka,
    //     "image": Karnatka,
    //     "url_link": "goi",
    //     "title": "We collaborated with our Government to assist drought affected farmers of North Karnataka",
    //     "subtitle": "",
    //     "description": "<p>The farmers of North Karnataka, under the guidance of CSIR, grew Teff in their draught-affected region. However, they needed the industry’s assistance to develop high-quality products with Teff as a main ingredient to help in its market introduction.<br />TagTaste harnessed the power of its network and analytics to develop potent use-cases for teff based products. Teff has become a main-stream functional grain for sports persons and our armed forces. Our government plans to introduce teff to the farmers of Rajasthan this year.</p>",
    //     "images": [],
    //     "article_links": []
    // },
    {
        "id": 3,
        "icon": CocaCola,
        "image": CocaCola,
        "url_link": "coca-cola",
        "title": "TagTaste and Coca-Cola Southern India:  New Product Testing for NCCS-B Market in Telangana and AP",
        "subtitle": "",
        "description": "<ul><li>Community (Panelist) Building.</li><li>Vernacular Training Tools</li><li>Field Testing</li><li>Comparative Analytics</li><li>Statistical Models to predict market readiness .<br /><br /></li></ul>",
    },
    {
        "id": 4,
        "icon": Mondelez,
        "image": Mondelez,
        "url_link": "mondelez",
        "title": "Development of Product Rubrics with Mondelez",
        "subtitle": "",
        "description": "<p>TagTaste collaborated with Mondelēz India to create a range of products for the QSR segment using some of the most popular products from Mondelēz portfolio. This project involved a in-depth study of all these products and development of Product Rubrics for each one of them.</p>",
    },
    {
        "id": 5,
        "icon": Dole,
        "image": Dole,
        "url_link": "framework-fruits-pulses-lentils",
        "title": "Development of Sensory Framework for Fruits,Pulses & Lentils",
        "subtitle": "",
        "description": "<p>TagTaste developed Sensory Frameworks for Dole Products- Fruit Cups and Juices, Raw South African Apples and Pears, the Pink Lady® Apple, US Cranberries and Chickpeas.<br />Fruit-centric Happy McDonald’s Kids’ Meal was launched on the basis of TagTaste’s insights. Mintel, too, acknowledged the same in its research report. Both brands reported consistently high sales numbers.</p>",
    },
    {
        "id": 7,
        "icon": DominosIcon,
        "image": Dominos,
        "url_link": "dominos",
        "title": "TagTaste and Domino’s Eastern India: Elevating East Indian Pizza Excellence",
        "subtitle": "Creating Market-Ready Pizza Variants with Regional Palate Insights and Genetic Expertise",
        "description": "<p>TagTaste assisted Domino’s India in determining the market readiness of East specific Pizza variants that they had developed - <strong>Champaran, Chingdi Malai, </strong><strong>Kosha, Kasundi Chicken, and Paneer Malai. </strong>We developed a framework basis our region specific palate data, genetics and ethnography. All the five pizzas exceeded Domino’s measures of merit in the market</p>",
    },
]


export const PLATFORM_LIST = [
    {
        icon: Home,
        title: "Home",
        fillIcon: FillHome
    },
    {
        icon: Search,
        title: "Search",
        fillIcon: FillSearch
    }, {
        icon: Feed,
        title: "Feed",
        fillIcon : FillFeed
    }, {
        icon: Profile,
        title: "Profile",
        fillIcon : FillProfile
    }, {
        icon: Hash,
        title: "Hash",
        fillIcon: FillHash
    }, {
        icon: Reviews,
        title: "Sensory Reviews",
        fillIcon: FillReviews
    }, {
        icon: Collaboration,
        title: "Collaboration",
        fillIcon: FillCollaboration
    }, {
        icon: Survey,
        title: "Survey",
        fillIcon: FillSurvey
    }, {
        icon: Poll,
        title: "Poll",
        fillIcon: FillPoll
    }, {
        icon: Quiz,
        title: "Quizzes",
        fillIcon: FillQuiz
    }, {
        icon: Dashboard,
        title: "Admin Dashboard",
        fillIcon: FillDashboard
    }, {
        icon: Payment,
        title: "Automated Payments",
        fillIcon: FillPayment
    }, {
        icon: Notifications,
        title: "Notifications",
        fillIcon: FillNotifications
    }, {
        icon: Chat,
        title: "Chat",
        fillIcon: FillChat
    }, {
        icon: Setting,
        title: "Privacy Settings",
        fillIcon: FillSetting
    }
]