import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import get from "lodash/get";
import UserBar from "../common/UserBar";
import MetaBar from "../common/MetaBar";
import CompanyBar from "../common/CompanyBar";
import PostComments from "../common/PostComments";
import { getInternalLink } from "../../../../utils/internalLinks";
import { decodeString, removeStripTags } from "../../../../utils/helper";
import {
  getCollabType,
  typesOfCollaborations,
} from "../../../../utils/collaborationHelper";
import ReadMoreLinkified from "../common/ReadMoreLinkified";
import PaidRibbon from "../../../../components/profile/MyPassbook/PaidRibbon";

class CollaborateCard extends Component {
  render() {
    const {
      cardData,
      isShared,
      showOptionsIcon,
      hideMetaBar,
      isAd,
      showFlagOptions,
      commentsData
    } = this.props;
    const { collaborate, meta } = cardData;
    const createdAt =
      collaborate && collaborate.created_at ? collaborate.created_at : false,
      postId = collaborate && collaborate.id ? collaborate.id : false,
      linkToPost = getInternalLink(
        "collaborate",
        collaborate.id,
        null,
        true,
        collaborate.collaborate_type
      ),
      title = decodeString(collaborate.title),
      description = removeStripTags(decodeString(collaborate.description), [
        "br",
      ]);
    const collabHelper = getCollabType(collaborate.collaborate_type);
    const isPaid = get(meta, "isPaid");

    return (
      <div className="feed-card">
        {cardData.profile ? (
          <UserBar
            postType={typesOfCollaborations.normal}
            postId={postId}
            showOptionsIcon={showOptionsIcon}
            cardData={cardData}
            createdAt={createdAt}
            isAd={isAd}
            hideMetaBar={hideMetaBar}
            showFlagOptions={showFlagOptions}
            profile={cardData.profile}
          />
        ) : cardData.company ? (
          <CompanyBar
            postType={typesOfCollaborations.normal}
            postId={postId}
            showOptionsIcon={showOptionsIcon}
            cardData={cardData}
            createdAt={createdAt}
            hideMetaBar={hideMetaBar}
            showFlagOptions={showFlagOptions}
            isAd={isAd}
            profileId={
              cardData.company && cardData.company.profile_id
                ? cardData.company.profile_id
                : null
            }
            company={cardData.company}
          />
        ) : null}
        <Link
          to={linkToPost}
          className={`${collabHelper === "PRODUCT REVIEW" ? "productreview-details" : ""
            } collaborate-details`}
        >
          {collaborate ? (
            <div className="collaborate-details__container">
              <div>
                {isPaid && (
                  <div className="feedcardribbon__survey__ribbon">
                    <PaidRibbon />
                  </div>
                )}
                {
                  collabHelper === "PRODUCT REVIEW" ?
                    <span
                      className={`${collabHelper === "PRODUCT REVIEW"
                        ? "productreview-details"
                        : ""
                        } collaborate-details__container__title type_icon`}
                    >
                      {collabHelper}
                      {!!collaborate.type_icon && (
                        <p className="collaboration__view__details__types">
                          <img src={collaborate.type_icon.image} className="icon_image" />
                        </p>
                      )}

                    </span>
                    : <h2
                      className={`${collabHelper === "PRODUCT REVIEW"
                        ? "productreview-details"
                        : ""
                        } collaborate-details__container__title`}
                    >
                      {collabHelper}
                    </h2>
                }
              </div>
              <ReadMoreLinkified
                text={title}
                linksAsDiv={true}
                hashtagEventLabel="Collaboration"
                className="collaborate-details__container__looking-for"
              />
              {/* <p className='collaborate-details__container__looking-for'>{ title }</p> */}
              <p
                className="collaborate-details__container__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <button
                className={`${collabHelper === "PRODUCT REVIEW"
                  ? "productreview-details"
                  : ""
                  } collaborate-details__container__view-more`}
              >
                SEE MORE
              </button>
            </div>
          ) : null}
        </Link>
        {!isShared && !hideMetaBar && cardData.meta && (
          <MetaBar
            postType="collaborate"
            postId={collaborate.id}
            isAd={isAd}
            likeActionProcessing={cardData.likeActionProcessing}
            fetchingComments={commentsData && commentsData.fetching}
            postToShare={cardData}
            meta={cardData.meta}
          />
        )}
        {commentsData && !hideMetaBar && !isShared && (
          <PostComments
            showOptionsIcon={showOptionsIcon}
            commentsData={commentsData}
            postType={typesOfCollaborations.normal}
            isAd={isAd}
            postId={collaborate.id}
          />
        )}
      </div>
    );
  }
}
CollaborateCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  commentsData: PropTypes.object,
  showOptionsIcon: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  isAd: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

export default CollaborateCard;
