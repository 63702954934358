import React from "react";
import get from "lodash/get";
import { format, parse } from 'date-fns';
import RightArrwoIcon from "../../../../assets/images/common/icons/chevron-right.svg";

const SerialNumber = ({ schedule_invitees_serial }) => {
    return (
        <p className="schedule__list-table--item__text centered">{schedule_invitees_serial}</p>
    )
};

const DateTime = ({ row }) => {
    const date = get(row, 'row.original.date', false);
    const reporting_time = get(row, 'row.original.reporting_time', false);
    if (!date || !reporting_time) return false;

    const convertedDateTime = parse(`${date} ${reporting_time}`, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formattedDateTime = format(convertedDateTime, 'dd MMM yyyy, hh:mm:ss a');
    return (
        <div >
            <p className="schedule__list-table--item__text">
                <span>{formattedDateTime}</span>
            </p>
        </div>
    )
};

const City = (props) => {
    const tastingCity = get(props, 'data.value.name', '')
    return (
        <span title={tastingCity} className="schedule__list-table--item__text city">{tastingCity}</span>
    )
};

const Location = (props) => {
    const locationText = get(props, 'data.value.title', '');
    const locationLink = get(props, 'data.value.link', '');
    return (
        <a href={locationLink} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" className="schedule__list-table--item__link">
            {locationText}
        </a>
    )
};
const InvitedCount = (props) => {
    const inviteCount = get(props, 'data.value')
    return (
        <p className="schedule__list-table--item__text centered">{inviteCount}</p>
    )
};
const ConfirmedCount = (props) => {
    const confirmedCount = get(props, 'data.value')
    return (
        <p className="schedule__list-table--item__text centered">{confirmedCount}</p>
    )
};
const Action = (props) => {
    const isActive = get(props, 'data.row.original.is_active', 1);
    return (
        <div className='schedule__list-table--item__action--wrapper'>
            <span className="schedule__status danger" style={{ visibility: !isActive ? 'visible' : 'hidden' }}>{!isActive ? "Expired" : ""}</span>
            <button className="button schedule__list-table--item__action">
                <div className="schedule__list-table--item__image-wrapper">
                    <img src={RightArrwoIcon} alt="action" />
                </div>
            </button>
        </div>

    )
};

export { SerialNumber, DateTime, City, Location, InvitedCount, ConfirmedCount, Action };