/* eslint-disable no-case-declarations */
import get from 'lodash/get';
import {
  GET_POLLS_REQUEST,
  GET_POLLS_SUCCESS,
  GET_POLLS_FAILURE,
  RESET_POLLS_DETAILS,
} from '../../actions/seeAllPages/constants';
import { CREATE_A_POLL_SUCCESS } from '../../actions/common/constants';
import {
  LIKE_A_POST_REQUEST,
  LIKE_A_POST_SUCCESS,
  LIKE_A_POST_FAILURE,
} from '../../actions/constants';
import {
  CREATE_A_COMMENT_SUCCESS,
  UPDATE_A_POLL_SUCCESS,
  DELETE_A_POST_SUCCESS,
} from '../../actions/common/constants';
import { GET_A_POST_COMMENT_SUCCESS, DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { RENEW_A_POLL_SUCCESS, PLAY_A_POLL_SUCCESS } from '../../actions/poll/constants';

const INITIAL_STATE = {
  page: 1,
  fetching: false,
  hasEnd: false,
  data: [],
};

const isCurrentItem = (state, actionPollId) => {
  if (!state.data) return false;

  let currentPollIndex =
    state.data && state.data.findIndex((obj) => get(obj, 'polling.id') === actionPollId);
  return currentPollIndex === -1 ? false : true;
};

export default function polls(state = INITIAL_STATE, action) {
  const newState = JSON.parse(JSON.stringify(state));
  let currentPollId = get(action, 'payload.postId');
  let currentPollIndex =
    newState.data && newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPollId);
  let updatedPoll;

  if (action.type) {
    switch (action.type) {
      case GET_POLLS_REQUEST:
        return {
          ...state,
          fetching: true,
          error: false,
          errorMsg: "",
        };
      case GET_POLLS_SUCCESS:
        return {
          ...state,
          fetching: false,
          data:
            state.page === 1
              ? get(action, 'payload.data', [])
              : [...state.data, ...get(action, 'payload.data', [])],
          page: state.page + 1,
          hasEnd: !action.payload.data || (action.payload.data && !action.payload.data.length),
          isFromPreviousData: !!action.payload.isFromPreviousData,
        };
      case GET_POLLS_FAILURE:
        return {
          ...state,
          fetching: false,
          error: true,
          errorMsg: action.error,
        };

      case LIKE_A_POST_REQUEST:
      case LIKE_A_POST_FAILURE:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...newState.data[currentPollIndex].meta,
              hasLiked:
                action.type === LIKE_A_POST_REQUEST
                  ? !action.payload.hasLiked
                  : action.payload.hasLiked,
              likeCount:
                action.type === LIKE_A_POST_REQUEST
                  ? action.payload.hasLiked
                    ? newState.data[currentPollIndex].meta.likeCount === 0
                      ? 0
                      : newState.data[currentPollIndex].meta.likeCount - 1
                    : newState.data[currentPollIndex].meta.likeCount + 1
                  : action.type === LIKE_A_POST_FAILURE
                    ? action.payload.hasLiked
                      ? newState.data[currentPollIndex].meta.likeCount + 1
                      : newState.data[currentPollIndex].meta.likeCount === 0
                        ? 0
                        : newState.data[currentPollIndex].meta.likeCount - 1
                    : newState.data[currentPollIndex].meta.likeCount,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }
      case LIKE_A_POST_SUCCESS:// like status & count based on API response
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...newState.data[currentPollIndex].meta,
              hasLiked: action.payload.hasLiked,
              likeCount: action.payload.likeCount,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case CREATE_A_COMMENT_SUCCESS:
      case GET_A_POST_COMMENT_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...newState.data[currentPollIndex].meta,
              commentCount:
                action.type === CREATE_A_COMMENT_SUCCESS
                  ? newState.data[currentPollIndex].meta.commentCount + 1
                  : action.payload.count,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case DELETE_A_COMMENT_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...newState.data[currentPollIndex].meta,
              commentCount:
                newState.data[currentPollIndex].meta.commentCount - 1 <= 0
                  ? 0
                  : newState.data[currentPollIndex].meta.commentCount - 1,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case UPDATE_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.data.polling.id'))) {
          currentPollId = get(action, 'payload.data.polling.id');
          currentPollIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPollId);

          updatedPoll = {
            ...newState.data[currentPollIndex],
            polling: {
              ...action.payload.data.polling,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case RENEW_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.pollId'))) {
          currentPollId = get(action, 'payload.pollId');
          currentPollIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPollId);

          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...newState.data[currentPollIndex].meta,
              is_expired: 0,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case PLAY_A_POLL_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.polling.id'))) {
          currentPollId = get(action, 'payload.polling.id');
          currentPollIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPollId);

          updatedPoll = {
            ...newState.data[currentPollIndex],
            meta: {
              ...action.payload.meta,
            },
            polling: {
              ...action.payload.polling,
            },
          };
          newState.data[currentPollIndex] = updatedPoll;
          return newState;
        } else {
          return state;
        }

      case DELETE_A_POST_SUCCESS:
        if (isCurrentItem(state, get(action, 'payload.postId'))) {
          currentPollId = get(action, 'payload.postId');
          currentPollIndex =
            newState.data &&
            newState.data.findIndex((obj) => get(obj, 'polling.id') === currentPollId);

          return {
            ...state,
            data: state.data.filter((obj) => {
              return get(obj, 'polling.id', '') !== get(action, 'payload.postId', '');
            }),
          };
        } else {
          return state;
        }

      case CREATE_A_POLL_SUCCESS:
        return {
          ...state,
          data: [...[action.payload], ...state.data],
        };

      case RESET_POLLS_DETAILS:
        return INITIAL_STATE;
      default:
        return state;
    }
  }
}
