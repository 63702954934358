import React from "react";
import PropTypes from "prop-types";
import PRCollabIcon from "../../../assets/images/collaborations/form/pr-create.svg";
import AuditCollabIcon from "../../../assets/images/collaborations/form/audit.svg";
import ScrollableModal from "../common/ScrollableModal";

const texts = {
  title: `Select Review Type`,
};
const types = [
  {
    title: "Product Review",
    actionType: "review",
    description:
      "Get feedback on your products from some real customers and experts.",
    icon: PRCollabIcon,
  },
  {
    title: "Product Consistency Tracking",
    actionType: "consistency",
    description: "Check the consistency across multiple batches of a product.",
    icon: AuditCollabIcon,
  },
];

function ReviewCreate(props) {
  const { handleClose, handleButtonClick } = props;

  return (
    <>
      <ScrollableModal
        showHeader
        title={texts.title}
        handleClose={handleClose}
        showFooter={false}
        containerClassName="premium-modal"
        isModalOpen
        paddingLessContent={false}
      >
        <div className="default-modal__content">
          {types && (
            <ul className="premium-modal__list">
              {types.map((type) => {
                return (
                  <li
                    className="premium-modal__list__item__container"
                    key={`type-${type.title}`}
                  >
                    <button
                      className="premium-modal__list__item"
                      onClick={() => {
                        handleButtonClick(type.actionType)
                      }}
                    >
                      <div className="premium-modal__list__icon__container">
                        <img
                          className="premium-modal__list__icon"
                          src={type.icon}
                          alt={type.icon}
                        />
                      </div>
                      <div className="premium-modal__list__info">
                        <p className="premium-modal__list__info__title">
                          {type.title}
                        </p>
                        <p className="premium-modal__list__info__helper">
                          {type.description}
                        </p>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ScrollableModal>
    </>
  );
}

ReviewCreate.propTypes = {
  handleClose: PropTypes.func,
};

export default ReviewCreate;
